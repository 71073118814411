import checkout from "../schemas/form";
const {
  formField: { Sequence, readPath, readFileName, writePath, writeFileName, keyToCheck, statusOut, uri, uriMethodType, param, archiveLoc, delay, outProp, statusMap, listMap, mapObject, listMapKeys, stationCode, stationName, arcreq, cleancsv, fileType, startLine, variablePackage, w_variablePackage, wcfilter },
} = checkout;

const initialValues = {
  [Sequence.name]: "",
  [readPath.name]: "",
  [readFileName.name]: "",
  [writePath.name]: "",
  [writeFileName.name]: "",
  [keyToCheck.name]: "",
  [statusOut.name]: "",
  [uri.name]: "",
  [uriMethodType.name]: "",
  [param.name]: "",
  // [status.name]: "FALSE",
  [archiveLoc.name]: "",
  [delay.name]: "",
  [outProp.name]: "",
  [statusMap.name]: "",
  // [notFound.name]: "",
  [listMap.name]: "",
  [mapObject.name]: "",
  [listMapKeys.name]: "",
  [stationCode.name]: "",
  [stationName.name]: "",
  [arcreq.name]: "FALSE",
  // [coparc.name]: "",
  [cleancsv.name]: "FALSE",
  [fileType.name]: "",
  [startLine.name]: "",
  [variablePackage.name]: "",
  [w_variablePackage.name]: "",
  // [dcflag.name]: "FALSE",
  [wcfilter.name]: "FALSE",

};

export default initialValues;
