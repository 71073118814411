// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Switch,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import { ErrorMessage, useFormikContext } from "formik";
import { getTypeMaster } from "app/bomMaster/service/TypeMaster";
import { getProcess } from "app/bomMaster/service/ProcessMaster";
// import { getMachine } from "app/MachineMaster/service/Machine";
import { getStationAssociation } from "app/stationMaster/service/StationAssociation";

function AttributeInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, setFieldError } = useFormikContext();
  const [color] = useState("#344767");
  const { station } = useSelector((state) => state.station);
  const { processmaster } = useSelector((state) => state.processmaster);
  const { typemaster } = useSelector((state) => state.typemaster);
  // const { machine } = useSelector((state) => state.machine);
  const { stationassociation } = useSelector(
    (state) => state.stationassociation
  );
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  // const [machinelist, setMachineList] = useState([]);

  const { formField, values, errors, touched } = formData;

  const {
    type,
    name,
    displayname,
    description,
    uomsymbol,
    modelno,
    stationcode,
    uomdescription,
    upperlimit,
    lowerlimit,
    userdisplay,
    // machinecode,
    processid,
  } = formField;
  var {
    type: typeV,
    name: nameV,
    displayname: displaynameV,
    description: descriptionV,
    uomsymbol: uomsymbolV,
    modelno: modelnoV,
    stationcode: stationcodeV,
    // machinecode: machinecodeV,
    processid: processidV,
    uomdescription: uomdescriptionV,
    upperlimit: upperlimitV,
    lowerlimit: lowerlimitV,
    userdisplay: userdisplayV,
  } = values;

  const toggler = (event) => {
    values.userdisplay = event;
  };

  useEffect(() => {
    dispatch(getStation());
    dispatch(getTypeMaster());
    dispatch(getProcess());
    // dispatch(getMachine());
    dispatch(getStationAssociation());
  }, [dispatch]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Attribute Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="type"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Type
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="type"
                  id="type"
                  value={value}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    setValue(selectedValue);
                    const selectedOption = typemaster.find(
                      (option) => option.code === selectedValue
                    );
                    values.type = selectedOption?.categorytype;
                    setFieldValue("itemid", selectedOption?.code);
                    setFieldValue("stationcode", "");
                    // setFieldValue("machinecode", "");
                    setFieldValue("processid", "");
                    // setMachineList(machine);
                  }}
                  error={touched.type && Boolean(errors.type)}
                >
                  <option value=""></option>
                  {typemaster.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.categorytype}
                    </option>
                  ))}
                </Select>
                <ErrorMessage
                  name="type"
                  component="div"
                  style={{ color: "red", fontSize: "12px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={name.type}
                label=<span>
                  {name.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={name.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={displayname.type}
                label=<span>
                  {displayname.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={displayname.name}
                value={displaynameV}
                placeholder={displayname.placeholder}
                error={errors.displayname && touched.displayname}
                success={displaynameV.length > 0 && !errors.displayname}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={description.length > 0 && !errors.description}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={uomsymbol.type}
                label=<span>
                  {uomsymbol.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={uomsymbol.name}
                value={uomsymbolV}
                placeholder={uomsymbol.placeholder}
                error={errors.uomsymbol && touched.uomsymbol}
                success={uomsymbolV.length > 0 && !errors.uomsymbol}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={modelno.type}
                label=<span>
                  {modelno.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={modelno.name}
                value={modelnoV}
                placeholder={modelno.placeholder}
                error={errors.modelno && touched.modelno}
                success={modelnoV.length > 0 && !errors.modelno}
              />
            </Grid>
            {values.type === "PROCESS" ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor={processid.name}
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    {processid.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name={processid.name}
                    id={processid.name}
                    value={values.processid}
                    onChange={(e) => {
                      const processidV = e.target.value;
                      setFieldValue("processid", processidV);
                    }}
                  >
                    <option value=""></option>
                    {/* {processmaster.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}&nbsp;({obj.stationsequence})
                      </option>
                    ))} */}
                    {processmaster.map((obj) => {
                      const combinedText = `${obj.name} (${obj.stationsequence})`;
                      let displayedText =
                        combinedText.length > 40
                          ? `${combinedText.slice(0, 37)}...`
                          : combinedText;

                      if (displayedText.endsWith("...")) {
                        displayedText =
                          combinedText.length > 43
                            ? `${combinedText.slice(0, 38)}...`
                            : combinedText;
                      }

                      return (
                        <option
                          key={obj.id}
                          value={obj.code}
                          title={combinedText}
                        >
                          {displayedText}
                        </option>
                      );
                    })}
                  </Select>
                  {errors.processid && touched.processid && (
                    <FormHelperText
                      error
                      style={{
                        marginLeft: "0px",
                        fontWeight: 400,
                        marginTop: 5,
                      }}
                    >
                      {errors.processid}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            {values.type === "STATION" || values.type === "PROCESS" ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor={stationcode.name}
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    {stationcode.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name={stationcode.name}
                    id={stationcode.name}
                    value={values.stationcode}
                    onChange={(e) => {
                      const stationcodeV = e.target.value;
                      setFieldValue("stationcode", stationcodeV);
                      // setMachineList(
                      //   stationassociation
                      //     .filter((i) => i?.stationcode === stationcodeV)
                      //     .map((i) => i?.machine)
                      // );
                    }}
                  >
                    <option value=""></option>
                    {station.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}&nbsp;({obj.code})
                      </option>
                    ))}
                  </Select>
                  {errors.stationcode && touched.stationcode && (
                    <FormHelperText
                      error
                      style={{
                        marginLeft: "0px",
                        fontWeight: 400,
                        marginTop: 5,
                      }}
                    >
                      {errors.stationcode}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            {/* <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor={machinecode.name}
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  {machinecode.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name={machinecode.name}
                  id={machinecode.name}
                  value={values.machinecode}
                  onChange={(e) => {
                    const machinecodeV = e.target.value;
                    setFieldValue("machinecode", machinecodeV);
                  }}
                >
                  <option value=""></option>
                  {machinelist.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}&nbsp;({obj.code})
                    </option>
                  ))}
                </Select>
                {errors.machinecode && touched.machinecode && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: 400, marginTop: 5 }}
                  >
                    {errors.machinecode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={4}>
              <FormField
                type={uomdescription.type}
                label=<span>
                  {uomdescription.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                  </sup>
                </span>
                name={uomdescription.name}
                value={uomdescriptionV}
                placeholder={uomdescription.placeholder}
                success={uomdescriptionV.length > 0 && !errors.uomdescription}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={lowerlimit.type}
                label=<span>
                  {lowerlimit.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                  </sup>
                </span>
                name={lowerlimit.name}
                value={lowerlimitV}
                placeholder={lowerlimit.placeholder}
                success={lowerlimitV.length > 0 && !errors.lowerlimit}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={upperlimit.type}
                label=<span>
                  {upperlimit.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                  </sup>
                </span>
                name={upperlimit.name}
                value={upperlimitV}
                placeholder={upperlimit.placeholder}
                success={upperlimitV.length > 0 && !errors.upperlimit}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={<Switch onClick={(e) => toggler(e.target.checked)} />}
                label={userdisplay.label}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ProductInfo
AttributeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AttributeInfo;
