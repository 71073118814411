// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import "./product.css";

import { BarLoader } from "react-spinners";

import { Card } from "@mui/material";

import MDButton from "components/MDButton";

import swal from "sweetalert";
import Grid from "@mui/material/Grid";

import axios from "../axiosinstance";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import { Autocomplete, TextField } from "@mui/material";

import { getStation } from "app/stationMaster/service/Station";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProductListview from "./ProductListview";
import MDBadge from "components/MDBadge";
import { getBOMCreation } from "app/bomMaster/service/BOMCreationMaster";

function PartList() {
  const dispatch = useDispatch();
  const { station } = useSelector((state) => state.station);
  const { bomcreationmaster } = useSelector((state) => state.bomcreationmaster);
  const [openpopup, setopenpopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [stationcode, setStationcode] = useState("");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(8);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtercode, setFiltercode] = useState("");
  const [timer, setTimer] = useState(null);

  const [color] = useState("#344767");

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",

  };

  const defaultpropsstation = {
    options: !station
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : station,
    getOptionLabel: (option) => option.name + "(" + option.code + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultpropsmodelcode = {
    options: !bomcreationmaster
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : bomcreationmaster,
    getOptionLabel: (option) => option.modelno,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultpropsFilter = {
    options: [
      { name: "Station", code: "ST001" },
      { name: "Model", code: "MO001" },
    ],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  async function fetchData(stationcode, page, size, query) {
    setStationcode(stationcode);
    setCurrentPage(page)
    setLoading(true);
    setData([]);
    console.log(filtercode, "filter code 83")
    if (filtercode === undefined || stationcode === undefined) {
      getData(currentPage, pageSize, query);
    } else {
      let url = ""
      if(filtercode === "ST001"){
        url = `/itemmaster-service/api/itemMaster/v1/partStatus/getV3?stationcode=${stationcode}&ispageable=true&page=${page}&size=${size}&st=${query}`
      }else if(filtercode === "MO001"){
        url = `/itemmaster-service/api/itemMaster/v1/partStatus/getV3?modelno=${stationcode}&ispageable=true&page=${page}&size=${size}&st=${query}`
      }
      await axios
        .get(url)
        .then((response) => {
          setData(response?.data?.data?.dtoList);
          setTotalPages(response?.data?.data?.page?.totalPages);
          setLoading(false);
        })
        .catch((e) => {
          console.log("error", e);
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          setData([]);
          setLoading(false);
        });
    }
  }
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

    return `${formattedDate} ${formattedTime}`;
  };

  const fetchDataWithDelay = (currentPage, pageSize, query) => {
    // Clear the previous timer to restart the countdown
    clearTimeout(timer);

    // Set a new timer to fetch data after one second
    const newTimer = setTimeout(() => {
      setCurrentPage(0);
      if (stationcode) {
        fetchData(stationcode, currentPage, pageSize, query);
      } else {
        getData(currentPage, pageSize, query);
      }
    }, 1000);

    setTimer(newTimer);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    fetchDataWithDelay(currentPage, pageSize, value);
  };

  useEffect(() => {
    if (stationcode) {
      fetchData(stationcode, currentPage, pageSize, searchQuery);
    }
     else {
      getData(currentPage, pageSize, searchQuery);
    }
  }, [currentPage, pageSize]);

  async function getData(pageSize, size, query) {
    setLoading(true);
    setData([]);
    await axios
      .get(
        `/itemmaster-service/api/itemMaster/v1/partStatus/getV3?ispageable=true&page=${pageSize}&size=${size}&st=${query}`
      )
      .then((response) => {
        setData(response?.data?.data?.dtoList);
        setTotalPages(response?.data?.data?.page?.totalPages);
        setLoading(false);
      })
      .catch((e) => {
        swal({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        setData([]);
        setLoading(false);
      });
  }

  useEffect(() => {
    dispatch(getStation());
    getData(0, pageSize, searchQuery);
  }, []);

  const viewData = (item) => {
    setopenpopup(true);
    setSelectedData(item);
  };

  // Handle the next page button click
  function handleNextPage() {
    setCurrentPage((currentPage) => currentPage + 1);
  }

  // Handle the previous page button click
  function handlePreviousPage() {
    setCurrentPage((currentPage) => currentPage - 1);
  }
  function handlePreviousPage() {
    setCurrentPage((currentPage) => currentPage - 1);
  }

  // Render the table rows with add button
  function renderRows() {
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/app/Steps/steps-ui/${item.stationcode}/${item.name}`}
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "rgb(123, 128, 154)",
                  transition: "color 0.3s", // Optional: add a smooth transition effect
                }}
                onMouseOver={(e) => (e.currentTarget.style.color = "#1A73E8")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = "rgb(123, 128, 154)")
                }
              >
                {item?.name}
              </Link>
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.stationcode}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.modelno? item.modelno :''}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.stationname}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.itemcode}
            </td>

            <td>
              {item?.recstatus === "OK" ? (
                <MDBadge
                  badgeContent="pass"
                  size="md"
                  color="success"
                  gradient
                />
              ) : item?.recstatus == undefined || null || "OPEN" ? (
                <MDBadge badgeContent="Fail" size="md" color="error" gradient />
              ) : (
                <></>
              )}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {formatDateTime(item?.createddate)}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.type}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.bypass === null ? "false" : item?.bypass}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.setuppart === null ? "false" : item?.setuppart }
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              <VisibilityIcon
                fontSize="medium"
                color="rgb(123, 128, 154)"
                cursor="pointer"
                onClick={() => viewData(item)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  const handleSelectFilter =(code)=>{
    setFiltercode(code);
    if(code === undefined){
      getData(0, pageSize, searchQuery);
    }
    else if(code === "MO001"){
      dispatch(getBOMCreation());
    }
  }
  console.log(bomcreationmaster, "master code 313")
  console.log(filtercode, "sit filter code  314")

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card style={{ overflowX: "scroll" }}>
        <h5
          className="MuiTypography-root MuiTypography-h5 css-121ioyh-MuiTypography-root"
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
        Part List
        </h5>

        <MDBox pt={3} mb={3}>
          <MDBox>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <Grid container style={{ display: "flex", flexDirection: "row" }} >

                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ width: 180 }}
                    {...defaultpropsFilter}
                    onChange={(event, newValue)=> handleSelectFilter(newValue?.code)}
                    // onChange={(event, newValue) =>{ 
                    //   setFiltercode(newValue?.code);
                    //   getData(0, pageSize, searchQuery);
                    // }}
                    renderInput={(params) => (
                      <TextField {...params} label="Filter by" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  {filtercode === "ST001" ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-st001"
                      sx={{ width: 180 }}
                      {...defaultpropsstation}
                      onChange={(event, newValue) => {
                        setStationcode(newValue?.code);
                        setCurrentPage(0);
                        setSearchQuery("");
                        fetchData(newValue?.code, 0, pageSize, "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Station" />
                      )}
                    />
                  ) : filtercode === "MO001" ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-mo001"
                      sx={{ width: 180 }}
                      {...defaultpropsmodelcode}
                      onChange={(event, newValue) => {
                        // setMocode(newValue?.modelno);
                        setCurrentPage(0);
                        setSearchQuery("");
                        fetchData(newValue?.modelno, 0, pageSize, "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Model name" />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>



                <Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "end" }}>
                  <MDInput
                    type="text"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleChange}
                    label="Serach"
                  />

                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        <div>
          <table className="data-table2">
            <thead>
              <tr>
                <th>NAME</th>
                <th>STATIONCODE</th>
                <th> Model No</th>
               
                <th>STATIONNAME</th>
                <th>TX ID</th>
                <th>STATUS</th>
                <th>CREATEDDATE</th>
                <th>TYPE</th>
                <th>BYPASS</th>
                <th>SETUP PART</th>
                <th>ACTION</th>
              </tr>
            </thead>
            {renderRows()}
          </table>
          <div className="pagination-buttons2">
            <MDButton
              variant="contained"
              size="small"
              mr={20}
              color="info"
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              Previous
            </MDButton>
            <p className="total-pages2">
              Page {currentPage + 1} of {totalPages}
            </p>
            <MDButton
              variant="contained"
              size="small"
              color="success"
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1 || data.length === 0}
            >
              Next
            </MDButton>
          </div>
        </div>
      </Card>
      <Footer />
      {openpopup && (
        <ProductListview
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Part View"}
          selectedData={selectedData}
        />
      )}
    </DashboardLayout>
  );
}

export default PartList;
