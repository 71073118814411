import { createSlice } from "@reduxjs/toolkit";
import { createUser, deleteUser, getUser, updateUser } from "../service/User";




const initialState = {
  user: [],
  loading: false,
  error: null,
};

export const userDetail = createSlice({
  name: "userDetail",
  initialState,
  extraReducers: {
    [createUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createUser.fulfilled]: (state, action) => {
      console.log("state",state.user)
      state.loading = false;
      state.user.push(action.payload.data.dto);
    },
    [createUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.data.dtoList;

    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.user = state.user.filter((user) => user.id !== id);
      }
    },
    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.user.findIndex(user => user.id === action.payload.data.dto.id)
      state.user[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default userDetail.reducer;
