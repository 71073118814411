
import React, { useState } from 'react'
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import PrintQRCode from './PrintQRCode'
import { Grid } from '@mui/material';
import MDButton from './MDButton';

const PrintQRPopup = (props) => {
   
    const { title, openpopup,link, setOpenpopup } = props;
    const handleClose = () => {
   setOpenpopup(false);
      };
      
    return (
      
        <Dialog open={openpopup} onClose={handleClose} >
         <DialogTitle
        style={{
          backgroundColor: "#1A73E8",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
            <Grid p={8}>
                <PrintQRCode data={link} size={120} isprint={true}/>
            </Grid>
            <DialogActions>
          <MDButton size="small" color="info" variant="outlined" onClick={handleClose}>Cancel</MDButton>
         
        </DialogActions>
        </Dialog>
    )
}

export default PrintQRPopup;