import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const getAttributeVariation = createAsyncThunk(
  "getAttributeVariation",
  async (data, { rejectWithValue }) => {

    try {
      const response = await axios.get(
        //  `visualization-controller/api/visualization/v3/getPartProcessed?date=${data}`,
        `visualization-service/api/visualization/v3/getAttributeVariation?attributeName=${data.attributeName}&endDateTime=${data.enddate}&startDateTime=${data.startdate}&stationCode=${data.stationcode}`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//3 Graph api=================