import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MoonLoader } from "react-spinners";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { updateShift } from "../service/Shift";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import { Grid, Paper } from "@mui/material";
import FormField from "./FormField";
import MDTypography from "components/MDTypography";

const Shiftedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedshift, openpopup, setopenpopup } = props;
  const [downTimes, setDownTimes] = useState([]);
  const { error, loading } = useSelector((state) => state.shift);


  
  const [downTimeData, setDownTimeData] = useState({
    name: "",
    from: "",
    to: "",
    downtime: ""
  });
  const columns = [
    { name: "Name", selector: row => row.name, center: true },
    { name: "From", selector: row => row.from, center: true },
    { name: "To", selector: row => row.to, center: true },
    { name: "Downtime (in minutes)", selector: row => row.downtime, center: true },
    {
      name: "Action",
      cell: (row) => (
        <Button
      
          style={{color:'red'}}
          onClick={() => removedata(row)}
        >
          X
        </Button>
      ),
      center: true,
    },
  ];
  const removedata = (row) => {
    // Filter out the row to be removed
    const updatedData = downTimes.filter((item) => item !== row);
    setDownTimes(updatedData);
  };
  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    shiftname: Yup.string().required("Shift Name is required"),
    starttime: Yup.string().required("Start time is required"),
    endtime: Yup.string().required("End time is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      shiftname: selectedshift?.title || "",
      starttime: selectedshift?.starttime || "",
      endtime: selectedshift?.endtime || "",
      // planneddowntime: selectedshift?.planneddowntime || "",
      
    },
    validationSchema,
    onSubmit: async (values) => {
      const totalTime = dayjs(values.endtime, "HH:mm").isBefore(
        dayjs(values.starttime, "HH:mm")
      )
        ? dayjs(values.endtime, "HH:mm")
            .add(1, "day")
            .diff(dayjs(values.starttime, "HH:mm"), "minute")
        : dayjs(values.endtime, "HH:mm").diff(
            dayjs(values.starttime, "HH:mm"),
            "minute"
          );

      const plannedDowntime = values.planneddowntime || 0;
      const availableTime = totalTime - plannedDowntime;

      const updatedShift = {
        ...selectedshift,
        title: values.shiftname,
        starttime: values.starttime,
        endtime: values.endtime,
        totaltime: totalTime.toString(),
        // planneddowntime: plannedDowntime.toString(),
        down_time_list: downTimes,
        availabletime: availableTime.toString(),
      };
      console.log("updatedShift",updatedShift)
      const response = await dispatch(updateShift(updatedShift));

      if (!response.error && !loading) {
        setopenpopup(false);
        swal("Good job!", "Shift has been successfully updated.", "success");
      } else {
        handleClose();

        swal({
          icon: "error",
          title: "Error",
          text: error?.message
            ? error?.message
            : "An error occurred while updating the item",
        });
      }
    },
  });
  const handleAddDownTime = () => {
    const { name, from, to, downtime } = downTimeData;

    // Validate that all fields are filled
    if (!name || !from || !to || !downtime) {
      swal("Error", "Please fill in all fields before adding.", "error");
      return;
    }

    // const fromTime = new Date(`1970-01-01T${from}:00`);
    // const toTime = new Date(`1970-01-01T${to}:00`);
    // if (toTime <= fromTime) {
    //   swal("Error", "'To' time should be greater than 'From' time.", "error");
    //   return;
    // }

    // Add downtime entry
    setDownTimes([...downTimes, { ...downTimeData }]);
    setDownTimeData({ name: "", from: "", to: "", downtime: "" });
  };
  useEffect(() => {
    if (downTimeData.from && downTimeData.to) {
      const fromTime = new Date(`1970-01-01T${downTimeData.from}:00`);
      let toTime = new Date(`1970-01-01T${downTimeData.to}:00`);
      
      // if (toTime > fromTime) {
      //   // Calculate downtime in minutes
      //   const downtimeMinutes = Math.round((toTime - fromTime) / (1000 * 60));
      //   setDownTimeData((prevData) => ({ ...prevData, downtime: downtimeMinutes.toString() }));
      // } else {
      //   setDownTimeData((prevData) => ({ ...prevData, downtime: "" }));
      // }
      if (toTime > fromTime) {
        // Calculate downtime in minutes
        const downtimeMinutes = Math.round((toTime - fromTime) / (1000 * 60));
        setDownTimeData((prevData) => ({ ...prevData, downtime: downtimeMinutes.toString() }));
      } else {
        toTime = new Date(`1970-01-02T${downTimeData.to}:00`);
        const downtimeMinutes = Math.round((toTime - fromTime) / (1000 * 60));
        setDownTimeData((prevData) => ({ ...prevData, downtime: downtimeMinutes.toString() }));
      }
    }
  }, [downTimeData.from, downTimeData.to]);
  useEffect(() => {
    // Update the form values when selectedshift changes
    formik.setValues({
      shiftname: selectedshift?.title || "",
      starttime: selectedshift?.starttime || "",
      endtime: selectedshift?.endtime || "",
      // planneddowntime: selectedshift?.planneddowntime || "",
    });
    setDownTimes(selectedshift?.down_time_list)
  }, [selectedshift]);

  const handleClose = () => {
    formik.setValues({
      shiftname: selectedshift?.title || "",
      starttime: selectedshift?.starttime || "",
      endtime: selectedshift?.endtime || "",
      // planneddowntime: selectedshift?.planneddowntime || "",
    });
 
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>
                Shift Name
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="shiftname"
              name="shiftname"
              value={formik.values.shiftname}
              onChange={formik.handleChange}
              error={
                formik.touched.shiftname && Boolean(formik.errors.shiftname)
              }
              helperText={
                formik.touched.shiftname && formik.errors.shiftname ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.shiftname}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="time"
              label=<span>
                Start Time
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="starttime"
              name="starttime"
              value={formik.values.starttime}
              onChange={formik.handleChange}
              error={
                formik.touched.starttime && Boolean(formik.errors.starttime)
              }
              helperText={
                formik.touched.starttime && formik.errors.starttime ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.starttime}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="time"
              label=<span>
                End Time
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="endtime"
              name="endtime"
              value={formik.values.endtime}
              onChange={formik.handleChange}
              error={formik.touched.endtime && Boolean(formik.errors.endtime)}
              helperText={
                formik.touched.endtime && formik.errors.endtime ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.endtime}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2} mt={1}>
            {/* <MDInput
              type="number"
              label="Planned Downtime (in minutes)"
              variant="standard"
              fullWidth
              id="planneddowntime"
              name="planneddowntime"
              value={formik.values.planneddowntime}
              onChange={formik.handleChange}
              error={
                formik.touched.planneddowntime &&
                Boolean(formik.errors.planneddowntime)
              }
              helperText={
                formik.touched.planneddowntime &&
                formik.errors.planneddowntime ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.planneddowntime}
                  </p>
                ) : null
              }
            /> */}
             <MDBox lineHeight={2}>
          <MDTypography variant="h6">Down Time</MDTypography>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
        
                  <MDInput
              type="text"
              label="Name"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={downTimeData.name}
              onChange={(e) => setDownTimeData({ ...downTimeData, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
         
               <MDInput
              type="time"
              label="From"
              variant="standard"
              fullWidth
              id="from"
              name="from"
              value={downTimeData.from}
              onChange={(e) => setDownTimeData({ ...downTimeData, from: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
         
                 <MDInput
              type="time"
              label="To"
              variant="standard"
              fullWidth
              id="to"
              name="to"
              value={downTimeData.to}
              onChange={(e) => setDownTimeData({ ...downTimeData, to: e.target.value })}
              // onChange={(e) => {
              //   const toValue = e.target.value;
              //   const fromValue = downTimeData.from;
                
              //   if (fromValue && new Date(`1970-01-01T${toValue}:00`) <= new Date(`1970-01-01T${fromValue}:00`)) {
              //     swal("Error", "'To' time should be greater than 'From' time.", "error");
              //   } else {
              //     setDownTimeData({ ...downTimeData, to: toValue });
              //   }
              // }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
          <MDInput
              type="number"
              label="Down Time"
              variant="standard"
              fullWidth
              id="downtime"
              name="downtime"
              value={downTimeData.downtime}
              onChange={(e) => setDownTimeData({ ...downTimeData, downtime: e.target.value })}
              disabled
            />
       
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDButton variant="gradient" color="light" onClick={handleAddDownTime}>ADD</MDButton>
          </Grid>
        </Grid>
             <MDBox mt={3} component={Paper}>
          <DataTable
            columns={columns}
            data={downTimes ? downTimes : [] }
            pagination
            responsive
            highlightOnHover
            noDataComponent={<div style={{ padding: "20px", textAlign: "center" }}>No down time. </div>}
          />
        </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="success" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="info" type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Shiftedit;
