import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const getAttributeVariationbypart = createAsyncThunk(
  "getAttributeVariationbypart",
  async (data, { rejectWithValue }) => {

    try {
      const response = await axios.get(
        //  `visualization-controller/api/visualization/v3/getPartProcessed?date=${data}`,
        `visualization-service/api/visualization/v3/getAttributeVariationByPart?stationcode=${data.station}&attributeName=${data.attributeName}&lastPartCount=${data.day}`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



// 4 garaph ap=============