import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { shiftName, starttime, endtime },
} = checkout;



const validations = [
  Yup.object().shape({
    [shiftName.name]: Yup.string().required(shiftName.errorMsg),
    [starttime.name]: Yup.string().required(starttime.errorMsg),

    [endtime.name]: Yup.string().required(endtime.errorMsg),
  }),
];

export default validations;
