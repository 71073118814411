import { createSlice } from "@reduxjs/toolkit";
import { createMachinechecklist, deleteMachinechecklist, getMachinechecklist, updateMachinechecklist } from "../service/MachineChecklist";






const initialState = {
  machinechecklist: [],
  loading: false,
  error: null,
};

export const machinechecklistDetail = createSlice({
  name: "machinechecklistDetail",
  initialState,
  extraReducers: {
    [createMachinechecklist.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createMachinechecklist.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.machinechecklist.push(action.payload.data.dto);
    },
    [createMachinechecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getMachinechecklist.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMachinechecklist.fulfilled]: (state, action) => {
      state.loading = false;
      state.machinechecklist = action.payload.data.dtoList;

    },
    [getMachinechecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteMachinechecklist.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteMachinechecklist.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.machinechecklist = state.machinechecklist.filter((machinechecklist) => machinechecklist.id !== id);
      }
    },
    [deleteMachinechecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateMachinechecklist.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateMachinechecklist.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.machinechecklist.findIndex(machinechecklist => machinechecklist.id === action.payload.data.dto.id)
      state.machinechecklist[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateMachinechecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default machinechecklistDetail.reducer;
