import { createSlice } from "@reduxjs/toolkit";
import { createDepartment, deleteDepartment, getDepartment, updateDepartment } from "../service/Department";






const initialState = {
  department: [],
  loading: false,
  error: null,
};

export const departmentDetail = createSlice({
  name: "departmentDetail",
  initialState,
  extraReducers: {
    [createDepartment.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createDepartment.fulfilled]: (state, action) => {
      console.log("state",state.department)
      state.loading = false;
      state.department.push(action.payload.data.dto);
    },
    [createDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getDepartment.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDepartment.fulfilled]: (state, action) => {
      state.loading = false;
      state.department = action.payload.data.dtoList;

    },
    [getDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteDepartment.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteDepartment.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.department = state.department.filter((department) => department.id !== id);
      }
    },
    [deleteDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateDepartment.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateDepartment.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.department.findIndex(department => department.id === action.payload.data.dto.id)
      state.department[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default departmentDetail.reducer;
