// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function MachineInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { machinecode, machinename, machineDescrption, tags } = formField;
  var { machinecode: machinecodeV, machinename: machinenameV, machineDescrption: machineDescrptionV, tags: tagsV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Machine Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={machinecode.type}
                label=<span>{machinecode.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
                name={machinecode.name}
                value={machinecodeV}
                placeholder={machinecode.placeholder}
                error={errors.machinecode && touched.machinecode}
                success={machinecodeV.length > 0 && !errors.machinecode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={machinename.type}
                label=<span>{machinename.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
                name={machinename.name}
                value={machinenameV}
                placeholder={machinename.placeholder}
                error={errors.machinename && touched.machinename}
                success={machinenameV.length > 0 && !errors.machinename}
              />
            </Grid>
          </Grid>


          <Grid container spacing={3}>


            <Grid item xs={12} sm={6}>
              <FormField
                type={machineDescrption.type}
                label=<span>{machineDescrption.label}</span>
                name={machineDescrption.name}
                value={machineDescrptionV}
                placeholder={machineDescrption.placeholder}
              // error={errors.machineDescrption && touched.machineDescrption}
              // success={machineDescrptionV.length > 0 && !errors.machineDescrption}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={tags.type}
                label=<span>{tags.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
                name={tags.name}
                value={Array.isArray(tagsV) ? tagsV.join(', ') : tagsV}
                placeholder={tags.placeholder}
                error={errors.tags && touched.tags}
                success={tagsV.length > 0 && !errors.tags}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for MachineInfo
// MachineInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };
MachineInfo.propTypes = {
  formData: PropTypes.shape({
    formField: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
};

export default MachineInfo;
