import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

//edit icon
import { FaRegEdit } from "react-icons/fa";
// import { deleteRole, getRole } from "../service/Role";
// import Roleedit from "./Roleedit";
import CreateAttributePopup from "./CreateAttributePopup";
import MDButton from "components/MDButton";
import { deleteAttribute, getAttribute } from "../service/Attribute";
import AttributeEdit from "./Attributeedit";

function AttributeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { attribute, loading } = useSelector((state) => state.attribute);
  // const [loading, setLoading] = useState(true);
  const { station } = useSelector((state) => state.station);
  const { processmaster } = useSelector((state) => state.processmaster);
  const { machine } = useSelector((state) => state.machine);

  const [openpopup, setopenpopup] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [openpopupAttribute, setopenpopupAttribute] = useState(false);

  function editAttribute(item) {
    console.log("item", item);

    setopenpopup(true);
    setSelectedAttribute(item);
  }

  function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteAttribute(item))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getAttribute());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Card>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Attribute Master
                      </MDTypography>
                    </span>

                    <span
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                    >
                      <MDButton
                        color="success"
                        onClick={() => setopenpopupAttribute(true)}
                      >
                        ADD
                      </MDButton>
                    </span>
                  </div>
                </MDBox>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "type", accessor: "type" },
                      {
                        Header: "name",
                        accessor: "name",
                        Cell: ({ row }) => (
                          <span>
                            {row.original.displayflag == "true" ||
                            row.original.displayflag == true
                              ? row.original.displayname
                              : row.original.name}
                          </span>
                        ),
                      },
                      { Header: "description", accessor: "description" },
                      { Header: "symbol", accessor: "uom" },
                      { Header: "unit of measure", accessor: "uomdescription" },
                      { Header: "lower limit", accessor: "lowerlimit" },
                      { Header: "upper limit", accessor: "upperlimit" },
                      { Header: "model no.", accessor: "modelno" },
                      {
                        Header: "station",
                        accessor: "stationcode",
                        Cell: ({ row }) => (
                          <span>
                            {station
                              .filter(
                                (item) => item.code === row.original.stationcode
                              )
                              .map((item, index) => (
                                <span key={index}>
                                  {item.name} {`(${item.code})`}
                                </span>
                              ))}
                          </span>
                        ),
                      },
                      // {
                      //   Header: "machine",
                      //   accessor: "machinecode",
                      //   Cell: ({ row }) => (
                      //     <span>
                      //       {machine
                      //         .filter(
                      //           (item) => item.code === row.original.machinecode
                      //         )
                      //         .map((item, index) => (
                      //           <span key={index}>
                      //             {item.name} {`(${item.code})`}
                      //           </span>
                      //         ))}
                      //     </span>
                      //   ),
                      // },
                      {
                        Header: "process",
                        accessor: "processid",
                        Cell: ({ row }) => (
                          <span>
                            {processmaster
                              .filter(
                                (item) => item.code === row.original.processid
                              )
                              .map((item, index) => (
                                <span key={index}>
                                  {item.name} {`(${item.code})`}
                                </span>
                              ))}
                          </span>
                        ),
                      },
                      {
                        Header: "Action",
                        accessor: "action",

                        Cell: ({ row }) => (
                          <div>
                            <Link to={{}}>
                              <FaRegEdit
                                style={iconStyleedit}
                                onClick={() => {
                                  editAttribute(row.original);
                                }}
                              />
                            </Link>

                            <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(row.original);
                                }}
                              />
                            </Link>
                          </div>
                        ),
                      },
                    ],
                    rows: attribute || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      <AttributeEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Attribute Edit"}
        selectedAttribute={selectedAttribute}
      />
      <CreateAttributePopup
        openpopupAttribute={openpopupAttribute}
        setopenpopupAttribute={setopenpopupAttribute}
      />
    </DashboardLayout>
  );
}

export default AttributeList;
