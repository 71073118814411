import { Autocomplete, Card, Checkbox, Grid, Stack, TextField } from '@mui/material';
import Rolecode from 'Rolecode';
import { getCheckList } from 'app/stationMaster/service/Checklist';
import { getStation } from 'app/stationMaster/service/Station';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BarLoader } from 'react-spinners';
import { APIs } from 'services/APIs';
import { getApiResponse } from 'services/getApisResponse';
import axios from '../../../../axiosinstance';
import swal from "sweetalert";
import CreateMachine from 'app/stationMaster/componets/CreateMachine';
import CreateChecklist from 'app/stationMaster/componets/CreateChecklist';
import CreatePokayoke from 'app/stationMaster/componets/CreatePokayoke';
import { deleteCategory, getCategory } from 'app/stationMaster/service/Category';
import {deleteMachine, getMachine } from 'app/MachineMaster/service/Machine';
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const AssociationMaster = () => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const code = Rolecode();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const { station } = useSelector((state) => state.station);
  const [selectedButton, setSelectedButton] = useState(null);
  const [color] = useState("#344767");
  const [categoryList, setCategoryList] = useState([])
  const [loading, setLoading] = useState(false);
  const [machineList, setMachineList] = useState([])
  const [rowData, setRowData] = useState([])
  const [stationData, setStationData] = useState(null)
  const [stationAssociation, setStationAssociation] = useState();
  const [openpopupMachine, setopenpopupMachine] = useState(false);
  const [openpopupChecklist, setopenpopupChecklist] = useState(false);
  const [ openpokayoke, setopenpokayoke] = useState(false)
  const { machine } = useSelector((state) => state.machine);
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const [initialData, setInitialData] = useState({
    id: null,
    status:"ACTIVE",
    // createdby: "",
    // modifiedby: "",
    station: {},
    pokayoke: [],
    checklist: [],
    machine: [],
  })
  const {  category } = useSelector((state) => state.category);
 

  const filterByType = (arr, typ) =>{
    const filteredData = arr.filter(item =>item.type && item.type === typ)
    return filteredData
  }

  const handleButtonClick = (button) => {
    if(!stationData || !stationData.code){
      return
    }
    if(button === 'machine'){
      setRowData(machine)
    }else if(button === 'checklist'){
      let data = filterByType(category, "MACHINECHECKLIST")
      setRowData(data)
    }
    else if(button === 'pokayoke'){
      let data = filterByType(category, "POKAYOKE")
      setRowData(data);
    }
    setSelectedButton(button);
  };

  useEffect(async() => {
    setLoading(true)
    dispatch(getStation());
    // dispatch(getCheckList());
    dispatch(getCategory())
    dispatch(getMachine())
    
    // const category = await getApiResponse(APIs.getCategory, {})
    // setCategoryList(category.data.dtoList)

    // const machine = await getApiResponse(APIs.getMachineList, {})
    // setMachineList(machine.data.dtoList)
    setLoading(false)
  }, [dispatch]);


  useEffect(async() => {
    handleSelectStation(stationData?.code,stationData)
  }, [category,machine]);

 
  

  const defaultpropsstation = {
    options: !station
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : station,
    getOptionLabel: (option) => option.name + "(" + option.code + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleSelectStation = async(code, val) =>{
  
    if(code === undefined){
      setSelectedButton(null);
      setStationData(null);
      setRowData([]);  
    }else{
      setSelectedButton('checklist');
      let data = filterByType(category, "MACHINECHECKLIST")
      setRowData(data)
      setStationData(val)
      let response = await getApiResponse(`${APIs.getStationAssociation}?station.code=${code}`, {})
      setStationAssociation(response?.data?.dtoList)
      if(response.data.dtoList.length <=0){
        setInitialData( {
          ...initialData, 
          station: val,
          createdby: auth.profileid,
          checklist:[],
          pokayoke:[],
          machine:[],
        })
      }else {
        let respData = response.data?.dtoList?.[response?.data?.dtoList?.length -1 ]
        setInitialData( {
          ...initialData, 
          id:respData.id,
          station: val,
          checklist:respData.checklist,
          pokayoke:respData.pokayoke,
          machine:respData.machine,
          modifiedby: auth.profileid,
        })
      }
    }
  }
//delete pokayoke and machinechecklist
const onDelete = async (item) => {
  const willDelete = await swal({
    title: "Are you sure?",
    text: `You will not be able to recover this ${selectedButton}!`,
    icon: "warning",
    buttons: ["Cancel", "Delete"],
    dangerMode: true,
  });

  if (willDelete) {
    swal("Deleting...", {
      icon: "info",
      buttons: false,
      closeOnClickOutside: false,
    });

    // Dispatch the appropriate delete action based on selectedButton
    let response;
    handleAssociationDelete(item.id)
    if (selectedButton === 'checklist' || selectedButton === 'pokayoke') {
      response = await dispatch(deleteCategory(item));
    } else {
      response = await dispatch(deleteMachine(item));
    }

    if (!response.error && !loading) {
      swal("Deleted!", "Your item has been deleted.", "success");
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: `An error occurred while deleting the ${selectedButton}`,
      });
    }
  } else {
    swal("Cancelled", `Your ${selectedButton}is safe.`, "info");
  }
};



// add popup
const popuphandler =(selectedButton)=>{
  
  switch (selectedButton) {
    case 'machine':
      setopenpopupMachine(true);
      break;
    case 'checklist':
      setopenpopupChecklist(true);
      break;
    case 'pokayoke':
      setopenpokayoke(true);
      break;
    default:
      console.warn(`please select correct button`);
      return;
  }
}

const handleAssociationDelete = (id)=> {
  let updatedArray;
    switch (selectedButton) {
      case 'machine':
        updatedArray = [...initialData.machine];
        break;
      case 'checklist':
        updatedArray = [...initialData.checklist];
        break;
      case 'pokayoke':
        updatedArray = [...initialData.pokayoke];
        break;
      default:
        console.warn(`Unsupported type: ${type}`);
        return;
    }

    const itemIndex = updatedArray.findIndex(item => item.id === id);
    if(itemIndex !== -1){
      updatedArray.splice(itemIndex, 1);
    }else{
      return
    }
    setInitialData({
      ...initialData,
      [selectedButton]: updatedArray,
    });
    let updatedData = { ...initialData, [selectedButton]: updatedArray, }
    handleSave(updatedData);
}



  const handleCheckboxChange = (id, val, isChecked) => {
  
    let updatedArray;
    switch (selectedButton) {
      case 'machine':
        updatedArray = [...initialData.machine];
        break;
      case 'checklist':
        updatedArray = [...initialData.checklist];
        break;
      case 'pokayoke':
        updatedArray = [...initialData.pokayoke];
        break;
      default:
        console.warn(`Unsupported type: ${type}`);
        return;
    }

    const itemIndex = updatedArray.findIndex(item => item.id === id);

    if (isChecked) {
      if (itemIndex === -1) {
        updatedArray.push(val);
      }
    } else {
      if (itemIndex !== -1) {
        updatedArray.splice(itemIndex, 1);
      }
    }

    setInitialData({
      ...initialData,
      [selectedButton]: updatedArray,
    });
  };

  const checkId =( id)=>{
    let res;
    if(selectedButton === "machine"){
      res = initialData.machine.filter(item => item.id === id)
    }else if(selectedButton === "checklist"){
      res = initialData.checklist.filter(item => item.id === id)
    }else if(selectedButton === "pokayoke") {
      res = initialData.pokayoke.filter(item => item.id === id)
    }
    if(res.length > 0){
      return true
    }else{
      return false
    }
  }

  function renderRows() {
    return (
      <tbody>
        {rowData?.map((item) => (
          <tr key={item.id}>
            <td>
              <Checkbox
                checked={ checkId( item.id) }
                onChange={(e) => handleCheckboxChange(item.id, item, e.target.checked)}
                color="primary"
              />
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.name}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item?.description ? item?.description : item?.desc}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
             {formatDateTime(item?.createddate)}
            </td>

            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {formatDateTime(item?.modifieddate)}
             
            </td>
            <td>
            <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(item);
                                }}
                              />
                            </Link>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
//formated date
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions).toUpperCase();
    return `${formattedDate} ${formattedTime}`;
  };

  const handleSave = async(data) => {
    if(stationAssociation.length>0){
      try {
        let resp = await axios.put(APIs.updateStationAssociation, data);
          if(resp.status === 201){
            swal("Success!", "Update association of machine with station is completed.")
          }else{
            swal("Oops!", "Something went wrong!")
          }
        } catch (error) {
          swal("Oops!", "Something went wrong!")
        }
    }else{
      try {
        let resp = await axios.post(APIs.createStationAssociation, initialData);
          if(resp.status === 201){
            swal("Success!", "Create association of machine with station is completed.")
          }else{
            swal("Oops!", "Something went wrong!")
          }
        } catch (error) {
          swal("Oops!", "Something went wrong!")
        }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} >
        <Card>
          <MDBox pl={3} pr={3} lineHeight={1}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ alignSelf: 'flex-start', marginTop: '20px' }}>
                <MDTypography variant="h5" fontWeight="medium">
                  Association
                </MDTypography>
              </div>
            </div>
          </MDBox>
          <MDBox pl={3} pr={3} lineHeight={1}>
            {auth?.rolecode === code?.Admincode && (
              <Grid container alignItems="center" justifyContent="space-between" mt={2}>
                <Grid item xs={12} sm={windowWidth < 900 ? 6.5 : 3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ width: '100%' }}
                    {...defaultpropsstation}
                    onChange={(event, newValue) => {
                      handleSelectStation(newValue?.code, newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Station" />}
                  />
                </Grid>
                <Grid item xs={12} sm={windowWidth < 900 ? 5.5 : 7} textAlign="right">
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-10px', gap: '10px', color: '#344767' }}
                  >
                    <MDButton
                      variant={selectedButton === 'checklist' ? 'contained' : "outlined"}
                      color={selectedButton === 'checklist' ? 'primary' : 'dark'}
                      // backgroundColor = {selectedButton === 'checklist' ? 'primary' : 'white'}
                      onClick={() => handleButtonClick('checklist')}
                      size="small"
                    >
                      Machine Check List
                    </MDButton>
                    <MDButton
                      color={selectedButton === 'pokayoke' ?  'primary' : 'dark'}
                      variant={selectedButton === 'pokayoke' ? 'contained' : "outlined"}
                      onClick={() => handleButtonClick('pokayoke')}
                      size="small"
                    >
                      Poka Yoke
                    </MDButton>
                    <MDButton
                      color={selectedButton === 'machine' ?  'primary' : 'dark'}
                      variant={selectedButton === 'machine' ? 'contained' : "outlined"}
                      onClick={() => handleButtonClick('machine')}
                      size="small"
                    >
                      Machine List
                    </MDButton>
                    {selectedButton !=null &&(
                      <MDButton
                       color={selectedButton ?  'primary' : 'dark'}
                       variant={selectedButton ? 'contained' : "outlined"}
                    size="small"
                   onClick={()=>popuphandler(selectedButton)}
                    >
                    add {selectedButton}
                    </MDButton>
            )}
                   

                  </Stack>
                </Grid>
              </Grid>
            )}
          </MDBox>

          <MDBox p={3}>
            <Card style={{ height: "49vh", overflowY: "scroll" }}>
              <BarLoader
                color={color}
                loading={loading}
                aria-label="Loading Spinner"
              />
              <div>
                <table className="data-table2">
                  <thead>
                    <tr>
                      <th></th>
                      <th>NAME</th>
                      <th>Description</th>
                     
                      <th>Created Date</th>
                      <th>Modified Date</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  {renderRows()}
                </table>
              </div>

            </Card>
          </MDBox>

          {rowData.length >0 &&
            <MDBox>
            <Grid container alignItems="center" justifyContent="space-between" p={2} px={8}>

              <Grid item xs={12} textAlign="right">
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-10px', gap: '10px', color: '#344767' }}
                >
                  {/* <MDButton
                    color={"primary"}
                  // onClick={() => handleButtonClick('Machine Check List')}
                  >
                    CANCEL
                  </MDButton> */}
                  <MDButton
                    color={'success'}
                  onClick={() => handleSave(initialData)}
                  >
                    SAVE
                  </MDButton>
                </Stack>
              </Grid>
            </Grid>
          </MDBox>}

        </Card>

      </MDBox>
      <CreateMachine
        openpopupMachine={openpopupMachine}
        setopenpopupMachine={setopenpopupMachine}
      />
       <CreateChecklist
        openpopupChecklist={openpopupChecklist}
        setopenpopupChecklist={setopenpopupChecklist}
     
      />
      <CreatePokayoke
        openpokayoke={openpokayoke}
        setopenpokayoke={setopenpokayoke}
      
      />
      
    </DashboardLayout>
  )
}

export default AssociationMaster;