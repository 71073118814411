import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/dayco.jpg";
import ProfileEditPopup from "./ProfileEditPopup"; 
import { useSelector } from "react-redux";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [tabValue, setTabValue] = useState(0);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [userName, setUserName] = useState(
    localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).name
      : ""
  );

  const updateLocalStorageName = (newName) => {
    const authData = JSON.parse(localStorage.getItem("mmu-auth")) || {};
    authData.name = newName;
    localStorage.setItem("mmu-auth", JSON.stringify(authData));
  };

  const updateName = (newName) => {
    setUserName(newName);
    updateLocalStorageName(newName);
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleEditIconClick = () => {
    setIsEditPopupOpen(true);
  };

  const handleCloseEditPopup = () => {
    setIsEditPopupOpen(false);
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="24rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={burceMars}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {auth?.lastname
                  ? auth?.firstname+" "+auth?.lastname
                  : auth?.firstname}
                {/* <span
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={handleEditIconClick}
                >
                  <Icon
                    fontSize="medium"
                    variant="body"
                    color="info"
                    sx={{
                      verticalAlign: "middle",
                      marginBottom: "5px",
                    }}
                  >
                    edit
                  </Icon>
                </span> */}
              </MDTypography>
              <MDTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {localStorage.getItem("mmu-auth")
                  ? JSON.parse(localStorage.getItem("mmu-auth")).rolename
                  : ""}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
      {isEditPopupOpen && (
        <ProfileEditPopup onClose={handleCloseEditPopup} updateName={updateName} />
      )}
    </MDBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
