import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Tooltip from "@mui/material/Tooltip";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Card,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { updateStation } from "../service/Station";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CreateChecklist from "./CreateChecklist";
import CreateMachine from "./CreateMachine";
import { deleteCheckList } from "../service/Checklist";
import { deleteStationAssociation } from "../service/StationAssociation";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { deleteFile, fileUpload, getFile } from "../service/FileUpload";
import { UploadFile } from "@mui/icons-material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { deletePokayoke } from "../service/Pokayoke";
import CreatePokayoke from "./CreatePokayoke";
import CircularProgress from "@mui/material/CircularProgress";
import { deleteMachine } from "app/MachineMaster/service/Machine";
import { deleteMachinechecklist } from "../service/MachineChecklist";

const Stationedit = (props) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { machine, loading1 } = useSelector((state) => state.machine);
  const { category, loading2 } = useSelector((state) => state.category);
  const { fileupload, loading3 } = useSelector((state) => state.fileupload);
  const { machinechecklist } = useSelector((state) => state.machinechecklist);
  const [openpokayoke, setopenpokayoke] = useState(false);
  const { pokayoke } = useSelector((state) => state.pokayoke);
  const [checklistcheckboxClicked, setChecklistCheckboxClicked] =
    useState(false);
    const [pokayokechecklist, setPokayokechecklist] =
    useState(false);
    
  const [reworkChecked, setReworkChecked] = useState(false);
  const [machinelistcheckboxClicked, setMachinelistCheckboxClicked] =
    useState(false);
  const [openpopupChecklist, setopenpopupChecklist] = useState(false);
  const [openpopupMachine, setopenpopupMachine] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [changefile, setChangeFile] = useState(false);
  const {
    title,
    loader,
    selectedStation,
    openpopup,
    setopenpopup,
    selectedChecklist,
    selectedMachinelist,
    selectedFileGet,
    selectedpokayoka
  } = props;
  const [loading, setIsLoading] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleFileChange = (e) => {
    setChangeFile(true);
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleChooseFileClick = () => {
    // Trigger the file input's click event
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validationSchema = Yup.object({
    stationcode: Yup.string().required("Station Code is required"),
    stationname: Yup.string().required("Station Name is required"),
    // stationdescription: Yup.string().required("Station Descrption is required"),
    // tags: Yup.string().required("Tags is required"),
  });

  console.log("selected item", selectedFile);

  const formik = useFormik({
    initialValues: {
      stationcode: "",
      stationname: "",
      stationdescription: "",
      totalreworkcount: "",
      rework: "",
      bypass: "",
      setuppart: "",
      tags: "",
      upload: null,
    },
    validationSchema: validationSchema,
    
    onSubmit: async (values, { resetForm }) => {

      if (selectedFile != null) {
        for (
          let i = 0;
          i <
          fileupload?.filter((item) => item?.code === values?.stationcode?.trim())
            ?.length;
          i++
        ) {
          dispatch(
            deleteFile(
              fileupload?.filter(
                (item) => item?.code === values?.stationcode?.trim()
              )[i]?.id
            )
          );
        }
      }

      const requestData = {
        ...selectedStation,
        code: values?.stationcode?.trim(),
        name: values?.stationname?.trim(),
        description: values?.stationdescription?.trim(),
        totalreworkcount: values.totalreworkcount.toString(),
        bypass: values.bypass ? values.bypass.toString() : "false",
        rework: values.rework ? values.rework.toString() : "false",
        setuppart: values.setuppart ? values.setuppart.toString() : "false",
      };
      const response = await dispatch(updateStation(requestData));
      if (!response.error && !loading) {
        const code = response?.payload?.data?.dto?.code;
        if (selectedFile != null) {
          UploadFile(selectedFile, code);
        }
        swal(
          "Good job!",
          "Station has been successfully updated!",
          "success"
        );
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
      }
      resetForm();
      handleClose();
    },
  });

  const UploadFile = (document, station_code) => {
    const formData = new FormData();
    const body = { code: station_code, resourcecode: "DAYCO" };
    formData.append("file", document);
    formData.append("body", JSON.stringify(body));
    dispatch(fileUpload(formData));
  };

  useEffect(() => {
    setChangeFile(false);
    formik.setValues({
      stationcode: selectedStation?.code || "",
      stationname: selectedStation?.name || "",
      stationdescription: selectedStation?.description || "",
      totalreworkcount: selectedStation?.totalreworkcount || "",
      tags: selectedStation?.tags || "",
      upload: selectedFileGet?.filename || null,
      rework: selectedStation?.rework === "true" ? true : false,
      bypass: selectedStation?.bypass === "true" ? true : false,
      setuppart: selectedStation?.setuppart === "true" ? true : false,
    });
  }, [
    selectedStation,
    selectedFileGet,

  ]);

  const handleClose = () => {
    setopenpopup(false);
    setChangeFile(false);
    formik.setValues({
      stationcode: selectedStation?.code || "",
      stationname: selectedStation?.name || "",
      stationdescription: selectedStation?.description || "",
      totalreworkcount: selectedStation?.totalreworkcount || "",
      rework: selectedStation?.rework || false,
      bypass: selectedStation?.bypass || false,
      setuppart: selectedStation?.setuppart || false,
      tags: selectedStation?.tags || "",
      upload: selectedFileGet?.filename || null,
    });
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
        {loader ? (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "20vh",
    }}
  >
    <CircularProgress />
  </div>
) : (
          <MDBox>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="stationcode"
                    name="stationcode"
                    label=<span>
                      Station Code
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    value={formik.values.stationcode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.stationcode &&
                      Boolean(formik.errors.stationcode)
                    }
                    helperText={
                      formik.touched.stationcode && formik.errors.stationcode
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="staionname"
                    name="stationname"
                    label=<span>
                      Station Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    value={formik.values.stationname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.stationname &&
                      Boolean(formik.errors.stationname)
                    }
                    helperText={
                      formik.touched.stationname && formik.errors.stationname
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="staiondescription"
                    name="stationdescription"
                    label="Station Description"
                    variant="standard"
                    value={formik.values.stationdescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.stationdescription &&
                      Boolean(formik.errors.stationdescription)
                    }
                    helperText={
                      formik.touched.stationdescription &&
                      formik.errors.stationdescription
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="tags"
                    name="tags"
                    label="Tags"
                    variant="standard"
                    value={formik.values.tags}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tags && Boolean(formik.errors.tags)}
                    helperText={formik.touched.tags && formik.errors.tags}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="totalreworkcount"
                    type="number"
                    name="totalreworkcount"
                    label="Total Rework Count"
                    variant="standard"
                    value={formik.values.totalreworkcount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.totalreworkcount &&
                      Boolean(formik.errors.totalreworkcount)
                    }
                    helperText={
                      formik.touched.totalreworkcount &&
                      formik.errors.totalreworkcount
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={formik.values.rework} onChange={(e) => formik.setFieldValue('rework', e.target.checked)} name="rework" />}
                    label="Rework"
                  />

                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={formik.values.bypass} onClick={(e) => formik.setFieldValue('bypass', e.target.checked)} name="bypass" />}
                    label="Bypass"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={formik.values.setuppart} onClick={(e) => formik.setFieldValue('setuppart', e.target.checked)} name="setuppart" />}
                    label="Setup Part"
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4.85}
                sx={{ display: "grid", placeItems: "end" }}
              >
                <TextField
                  fullWidth
                  variant="standard"
                  label="Upload Document"
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: Boolean(
                      selectedFile?.name || formik?.values?.upload
                    ),
                  }}
                  value={
                    changefile ? selectedFile?.name : formik?.values?.upload
                  }
                  sx={{ borderBottom: "0px solid #dce0e2" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                mr={3.1}
                ml={-2}
                sx={{ display: "grid", placeItems: "end" }}
              >
                <input
                  type="file"
                  name="document"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <Tooltip title="Upload Document" arrow>
                  <MDButton
                    variant="outlined"
                    color="info"
                    onClick={handleChooseFileClick}
                  >
                    <CloudUploadIcon />
                  </MDButton>
                </Tooltip>
              </Grid>

            </Grid>

            <MDBox mt={5} width="100%" display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="dark">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>
)}
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default Stationedit;


































// import React, { useEffect, useState, useRef } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import MDInput from "components/MDInput";
// import MDBox from "components/MDBox";
// import Tooltip from "@mui/material/Tooltip";
// import AddBoxIcon from "@mui/icons-material/AddBox";

// import { useDispatch } from "react-redux";
// import {
//   Autocomplete,
//   Card,
//   Checkbox,
//   FormControl,
//   Grid,
//   Input,
//   InputLabel,
//   TextField,
// } from "@mui/material";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import MDTypography from "components/MDTypography";
// import { useSelector } from "react-redux";
// import MDButton from "components/MDButton";
// import { updateStation } from "../service/Station";
// import DeleteIcon from "@mui/icons-material/Delete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import CreateChecklist from "./CreateChecklist";
// import CreateMachine from "./CreateMachine";
// import { deleteCheckList } from "../service/Checklist";
// import { deleteStationAssociation } from "../service/StationAssociation";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { deleteFile, fileUpload, getFile } from "../service/FileUpload";
// import { UploadFile } from "@mui/icons-material";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import { deletePokayoke } from "../service/Pokayoke";
// import CreatePokayoke from "./CreatePokayoke";
// import CircularProgress from "@mui/material/CircularProgress";
// import { deleteMachine } from "app/MachineMaster/service/Machine";
// import { deleteMachinechecklist } from "../service/MachineChecklist";

// const Stationedit = (props) => {
//   const dispatch = useDispatch();
//   const fileInputRef = useRef(null);
//   const { machine, loading1 } = useSelector((state) => state.machine);
//   const { category, loading2 } = useSelector((state) => state.category);
//   const { fileupload, loading3 } = useSelector((state) => state.fileupload);
//   const { machinechecklist } = useSelector((state) => state.machinechecklist);
//   const [openpokayoke, setopenpokayoke] = useState(false);
//   const { pokayoke } = useSelector((state) => state.pokayoke);
//   const [checklistcheckboxClicked, setChecklistCheckboxClicked] =
//     useState(false);
//     const [pokayokechecklist, setPokayokechecklist] =
//     useState(false);
    
//   const [reworkChecked, setReworkChecked] = useState(false);
//   const [machinelistcheckboxClicked, setMachinelistCheckboxClicked] =
//     useState(false);
//   const [openpopupChecklist, setopenpopupChecklist] = useState(false);
//   const [openpopupMachine, setopenpopupMachine] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [changefile, setChangeFile] = useState(false);
//   const {
//     title,
//     loader,
//     selectedStation,
//     openpopup,
//     setopenpopup,
//     selectedChecklist,
//     selectedMachinelist,
//     selectedFileGet,
//     selectedpokayoka
//   } = props;
//   const [loading, setIsLoading] = useState(false);

//   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//   const checkedIcon = <CheckBoxIcon fontSize="small" />;

//   const handleFileChange = (e) => {
//     setChangeFile(true);
//     const file = e.target.files[0];
//     setSelectedFile(file);
//   };

//   const handleChooseFileClick = () => {
//     // Trigger the file input's click event
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   const validationSchema = Yup.object({
//     checklist: Yup.array()
//       .min(1, "Select at least one checkbox")
//       .required("Required"),
//       pokayoke: Yup.array()
//       .min(1, "Select at least one checkbox")
//       .required("Required"),
//     machinelist: Yup.array()
//       .min(1, "Select at least one machinelist")
//       .required("Required"),
//     stationcode: Yup.string().required("Station Code is required"),
//     stationname: Yup.string().required("Station Name is required"),
//     // stationdescription: Yup.string().required("Station Descrption is required"),
//     // tags: Yup.string().required("Tags is required"),
//   });

//   console.log("selected item", selectedFile);

//   const formik = useFormik({
//     initialValues: {
//       stationcode: "",
//       stationname: "",
//       stationdescription: "",
//       totalreworkcount: "",
//       rework: "",
//       bypass: "",
//       setuppart: "",
//       tags: "",
//       upload: null,
//       checklist: [],
//       machinelist: [],
//       pokayoke:[]
      
//     },
//     validationSchema: validationSchema,
    
//     onSubmit: async (values, { resetForm }) => {
//       console.log("Form Values:", values);
//       for (
//         let i = 0;
//         i <
//         selectedChecklist?.filter(
//           (item) => item?.stationcode === values?.stationcode?.trim()
//         )?.length;
//         i++
//       ) {
//         dispatch(
//           deleteCheckList(
//             selectedChecklist?.filter(
//               (item) => item?.stationcode === values?.stationcode?.trim()
//             )[i]
//           )
//         );
//       }

//       for (
//         let i = 0;
//         i <
//         selectedpokayoka?.filter(
//           (item) => item?.stationcode === values?.stationcode?.trim()
//         )?.length;
//         i++
//       ) {
//         dispatch(
//           deletePokayoke(
//             selectedpokayoka?.filter(
//               (item) => item?.stationcode === values?.stationcode?.trim()
//             )[i]
//           )
//         );
//       }

//       for (
//         let i = 0;
//         i <
//         selectedMachinelist?.filter(
//           (item) => item?.stationcode === values?.stationcode?.trim()
//         )?.length;
//         i++
//       ) {
//         dispatch(
//           deleteStationAssociation(
//             selectedMachinelist?.filter(
//               (item) => item?.stationcode === values?.stationcode?.trim()
//             )[i]
//           )
//         );
//       }

//       if (selectedFile != null) {
//         for (
//           let i = 0;
//           i <
//           fileupload?.filter((item) => item?.code === values?.stationcode?.trim())
//             ?.length;
//           i++
//         ) {
//           dispatch(
//             deleteFile(
//               fileupload?.filter(
//                 (item) => item?.code === values?.stationcode?.trim()
//               )[i]?.id
//             )
//           );
//         }
//       }

//       const requestData = {
//         ...selectedStation,
//         code: values?.stationcode?.trim(),
//         name: values?.stationname?.trim(),
//         description: values?.stationdescription?.trim(),
//         totalreworkcount: values.totalreworkcount.toString(),
//         bypass: values.bypass ? values.bypass.toString() : "false",
//         rework: values.rework ? values.rework.toString() : "false",
//         setuppart: values.setuppart ? values.setuppart.toString() : "false",
//         category: values?.checklist,
//         machine: values?.machinelist,
//         pokayoke:values?.pokayoke
//       };
//       const response = await dispatch(updateStation(requestData));
//       if (!response.error && !loading) {
//         const code = response?.payload?.data?.dto?.code;
//         if (selectedFile != null) {
//           UploadFile(selectedFile, code);
//         }
//         swal(
//           "Good job!",
//           "Station has been successfully updated!",
//           "success"
//         );
//       } else {
//         swal({
//           icon: "error",
//           title: "Error",
//           text: "Something went wrong",
//         });
//       }
//       resetForm();
//       handleClose();
//     },
//   });

//   function onDeleteCategory(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deleteMachinechecklist(item))
//           .then(() => {
//             formik.setFieldValue(
//               "checklist",
//               formik.values.checklist.filter((value) => value !== item)
//             );
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }


//   function onDeleteMachine(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deleteMachine(item))
//           .then(() => {
//             formik.setFieldValue(
//               "machinelist",
//               formik.values.machinelist.filter((value) => value !== item)
//             );
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }


//   function onDeletePokayoke(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deletePokayoke(item))
//           .then(() => {
//             formik.setFieldValue(
//               "checklist",
//               formik.values.checklist.filter((value) => value !== item)
//             );
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   const UploadFile = (document, station_code) => {
//     const formData = new FormData();
//     const body = { code: station_code, resourcecode: "DAYCO" };
//     formData.append("file", document);
//     formData.append("body", JSON.stringify(body));
//     dispatch(fileUpload(formData));
//   };

//   useEffect(() => {
//     setChangeFile(false);
//     formik.setValues({
//       stationcode: selectedStation?.code || "",
//       stationname: selectedStation?.name || "",
//       stationdescription: selectedStation?.description || "",
//       totalreworkcount: selectedStation?.totalreworkcount || "",
//       tags: selectedStation?.tags || "",
//       upload: selectedFileGet?.filename || null,
//       rework: selectedStation?.rework === "true" ? true : false,
//       bypass: selectedStation?.bypass === "true" ? true : false,
//       setuppart: selectedStation?.setuppart === "true" ? true : false,
//       checklist: selectedChecklist.map((i) => i?.category) || [],
//       machinelist: selectedMachinelist.map((i) => i?.machine) || [],
//       pokayoke: selectedpokayoka.map((i) => i?.category) || [],

//     });
//   }, [
//     selectedStation,
//     selectedChecklist,
//     selectedMachinelist,
//     selectedFileGet,
//     selectedpokayoka

//   ]);

//   const handleClose = () => {
//     setopenpopup(false);
//     setChangeFile(false);
//     formik.setValues({
//       stationcode: selectedStation?.code || "",
//       stationname: selectedStation?.name || "",
//       stationdescription: selectedStation?.description || "",
//       totalreworkcount: selectedStation?.totalreworkcount || "",
//       rework: selectedStation?.rework || false,
//       bypass: selectedStation?.bypass || false,
//       setuppart: selectedStation?.setuppart || false,
//       tags: selectedStation?.tags || "",
//       upload: selectedFileGet?.filename || null,
//       checklist: selectedChecklist.map((i) => i?.category) || [],
//       machinelist: selectedMachinelist.map((i) => i?.machine) || [],
//       pokayoke: selectedpokayoka.map((i) => i?.category) || [],
//     });
//   };

//   return (
//     <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
//       <DialogTitle
//         style={{
//           backgroundColor: "blue",
//           color: "#fff",
//           textAlign: "center",
//         }}
//       >
//         {title}
//       </DialogTitle>
//       <form onSubmit={formik.handleSubmit}>
//         <DialogContent>
//         {loader ? (
//   <div
//     style={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       height: "20vh",
//     }}
//   >
//     <CircularProgress />
//   </div>
// ) : (
//           <MDBox>
//             <Grid container spacing={3} mt={0.5}>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="stationcode"
//                     name="stationcode"
//                     label=<span>
//                       Station Code
//                       <sup
//                         style={{
//                           color: "red",
//                           fontSize: "small",
//                           fontWeight: "bolder",
//                           position: "relative",
//                           top: "2px",
//                         }}
//                       >
//                         {" "}
//                         *
//                       </sup>
//                     </span>
//                     variant="standard"
//                     value={formik.values.stationcode}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={
//                       formik.touched.stationcode &&
//                       Boolean(formik.errors.stationcode)
//                     }
//                     helperText={
//                       formik.touched.stationcode && formik.errors.stationcode
//                     }
//                   />
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="staionname"
//                     name="stationname"
//                     label=<span>
//                       Station Name
//                       <sup
//                         style={{
//                           color: "red",
//                           fontSize: "small",
//                           fontWeight: "bolder",
//                           position: "relative",
//                           top: "2px",
//                         }}
//                       >
//                         {" "}
//                         *
//                       </sup>
//                     </span>
//                     variant="standard"
//                     value={formik.values.stationname}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={
//                       formik.touched.stationname &&
//                       Boolean(formik.errors.stationname)
//                     }
//                     helperText={
//                       formik.touched.stationname && formik.errors.stationname
//                     }
//                   />
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="staiondescription"
//                     name="stationdescription"
//                     label="Station Description"
//                     variant="standard"
//                     value={formik.values.stationdescription}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={
//                       formik.touched.stationdescription &&
//                       Boolean(formik.errors.stationdescription)
//                     }
//                     helperText={
//                       formik.touched.stationdescription &&
//                       formik.errors.stationdescription
//                     }
//                   />
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="tags"
//                     name="tags"
//                     label="Tags"
//                     variant="standard"
//                     value={formik.values.tags}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.tags && Boolean(formik.errors.tags)}
//                     helperText={formik.touched.tags && formik.errors.tags}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="totalreworkcount"
//                     type="number"
//                     name="totalreworkcount"
//                     label="Total Rework Count"
//                     variant="standard"
//                     value={formik.values.totalreworkcount}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={
//                       formik.touched.totalreworkcount &&
//                       Boolean(formik.errors.totalreworkcount)
//                     }
//                     helperText={
//                       formik.touched.totalreworkcount &&
//                       formik.errors.totalreworkcount
//                     }
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormGroup>
//                   <FormControlLabel
//                     control={<Switch checked={formik.values.rework} onChange={(e) => formik.setFieldValue('rework', e.target.checked)} name="rework" />}
//                     label="Rework"
//                   />

//                 </FormGroup>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormGroup>
//                   <FormControlLabel
//                     control={<Switch checked={formik.values.bypass} onClick={(e) => formik.setFieldValue('bypass', e.target.checked)} name="bypass" />}
//                     label="Bypass"
//                   />
//                 </FormGroup>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormGroup>
//                   <FormControlLabel
//                     control={<Switch checked={formik.values.setuppart} onClick={(e) => formik.setFieldValue('setuppart', e.target.checked)} name="setuppart" />}
//                     label="Setup Part"
//                   />
//                 </FormGroup>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 sm={4.85}
//                 sx={{ display: "grid", placeItems: "end" }}
//               >
//                 <TextField
//                   fullWidth
//                   variant="standard"
//                   label="Upload Document"
//                   InputProps={{ readOnly: true }}
//                   InputLabelProps={{
//                     shrink: Boolean(
//                       selectedFile?.name || formik?.values?.upload
//                     ),
//                   }}
//                   value={
//                     changefile ? selectedFile?.name : formik?.values?.upload
//                   }
//                   sx={{ borderBottom: "0px solid #dce0e2" }}
//                 />
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 sm={1}
//                 mr={3.1}
//                 ml={-2}
//                 sx={{ display: "grid", placeItems: "end" }}
//               >
//                 <input
//                   type="file"
//                   name="document"
//                   onChange={handleFileChange}
//                   ref={fileInputRef}
//                   style={{ display: "none" }}
//                 />
//                 <Tooltip title="Upload Document" arrow>
//                   <MDButton
//                     variant="outlined"
//                     color="info"
//                     onClick={handleChooseFileClick}
//                   >
//                     <CloudUploadIcon />
//                   </MDButton>
//                 </Tooltip>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <span style={{ display: "flex", alignItems: "center" }}>
//                   <Autocomplete
//                     multiple
//                     fullWidth
//                     id="checklist-tags-demo"
//                     options={machinechecklist}
//                     disableCloseOnSelect
//                     getOptionLabel={(option) => option?.name}
//                     onChange={(e, value) => {
//                       formik.setFieldValue("checklist", value);
//                     }}
//                     value={formik.values.checklist}
//                     onBlur={formik.handleBlur}
//                     renderOption={(props, option, { selected }) => (
//                       <div
//                         {...props}
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           alignItems: "center",
//                           padding: "8px",
//                           borderBottom: "1px solid #ccc",
//                         }}
//                       >
//                         <div style={{ display: "flex", alignItems: "center" }}>
//                           <Checkbox
//                             icon={icon}
//                             checkedIcon={checkedIcon}
//                             checked={
//                               checklistcheckboxClicked
//                                 ? formik.values.checklist.some(
//                                   (item) => item?.name === option?.name
//                                 )
//                                 : selectedChecklist
//                                   ?.map((i) => i?.category)
//                                   ?.some(
//                                     (item) =>
//                                       item?.name?.trim() === option?.name
//                                   ) || selected
//                             }
//                             onClick={(e) => {
//                               setChecklistCheckboxClicked(true);
//                               e.stopPropagation();
//                               const selectedItems = formik.values.checklist;
//                               const itemName = option?.name;
//                               const isItemAlreadySelected = selectedItems.some(
//                                 (item) => item?.name === itemName
//                               );

//                               if (isItemAlreadySelected) {
//                                 const updatedItems = selectedItems.filter(
//                                   (item) => item?.name !== itemName
//                                 );
//                                 formik.setFieldValue("checklist", updatedItems);
//                               } else {
//                                 formik.setFieldValue("checklist", [
//                                   ...selectedItems,
//                                   option,
//                                 ]);
//                               }
//                             }}
//                           />
//                           <span>{option?.name}</span>
//                         </div>
//                         <DeleteIcon
//                           fontSize="small"
//                           sx={{ color: "red" }}
//                           onClick={() => {
//                             onDeleteCategory(option);
//                           }}
//                         />
//                       </div>
//                     )}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label=<span>
//                           Check List
//                           <sup
//                             style={{
//                               color: "red",
//                               fontSize: "small",
//                               fontWeight: "bolder",
//                               position: "relative",
//                               top: "2px",
//                             }}
//                           >
//                             {" "}
//                             *
//                           </sup>
//                         </span>
//                         placeholder=""
//                         variant="standard"
//                         error={
//                           formik.touched.checklist &&
//                           Boolean(formik.errors.checklist)
//                         }
//                         helperText={
//                           formik.touched.checklist && formik.errors.checklist
//                         }
//                       />
//                     )}
//                   />
//                   <Tooltip title="Add Checklist" arrow>
//                     <AddBoxIcon
//                       fontSize="large"
//                       sx={{ cursor: "pointer" }}
//                       onClick={() => setopenpopupChecklist(true)}
//                     />
//                   </Tooltip>
//                 </span>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <span style={{ display: "flex", alignItems: "center" }}>
//                   <Autocomplete
//                     multiple
//                     fullWidth
//                     id="machinelist-tags-demo"
//                     options={machine}
//                     disableCloseOnSelect
//                     getOptionLabel={(option) => option?.name}
//                     onChange={(e, value) =>
//                       formik.setFieldValue("machinelist", value)
//                     }
//                     value={formik.values.machinelist}
//                     onBlur={formik.handleBlur}
//                     renderOption={(props, option, { selected }) => (
//                       <div
//                         {...props}
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           alignItems: "center",
//                           padding: "8px",
//                           borderBottom: "1px solid #ccc",
//                         }}
//                       >
//                         <div style={{ display: "flex", alignItems: "center" }}>
//                           <Checkbox
//                             icon={icon}
//                             checkedIcon={checkedIcon}
//                             checked={
//                               machinelistcheckboxClicked
//                                 ? formik?.values?.machinelist?.some(
//                                   (item) => item?.name === option?.name
//                                 )
//                                 : selectedMachinelist
//                                   ?.map((i) => i?.machine)
//                                   ?.some(
//                                     (item) =>
//                                       item?.name?.trim() === option?.name
//                                   ) || selected
//                             }
//                             onClick={(e) => {
//                               setMachinelistCheckboxClicked(true);
//                               e.stopPropagation();
//                               const selectedItems = formik.values.machinelist;
//                               const itemName = option?.name;
//                               const isItemAlreadySelected = selectedItems.some(
//                                 (item) => item?.name === itemName
//                               );

//                               if (isItemAlreadySelected) {
//                                 const updatedItems = selectedItems.filter(
//                                   (item) => item?.name !== itemName
//                                 );
//                                 formik.setFieldValue(
//                                   "machinelist",
//                                   updatedItems
//                                 );
//                               } else {
//                                 formik.setFieldValue("machinelist", [
//                                   ...selectedItems,
//                                   option,
//                                 ]);
//                               }
//                             }}
//                           />
//                           <span>
//                             {option?.name}&nbsp;({option?.code})
//                           </span>
//                         </div>
//                         <DeleteIcon
//                           fontSize="small"
//                           sx={{ color: "red" }}
//                           onClick={() => {
//                             onDeleteMachine(option);
//                           }}
//                         />
//                       </div>
//                     )}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label=<span>
//                           Machine List
//                           <sup
//                             style={{
//                               color: "red",
//                               fontSize: "small",
//                               fontWeight: "bolder",
//                               position: "relative",
//                               top: "2px",
//                             }}
//                           >
//                             {" "}
//                             *
//                           </sup>
//                         </span>
//                         placeholder=""
//                         variant="standard"
//                         error={
//                           formik.touched.machinelist &&
//                           Boolean(formik.errors.machinelist)
//                         }
//                         helperText={
//                           formik.touched.machinelist &&
//                           formik.errors.machinelist
//                         }
//                       />
//                     )}
//                   />
//                   <Tooltip title="Add Machine" arrow>
//                     <AddBoxIcon
//                       fontSize="large"
//                       sx={{ cursor: "pointer" }}
//                       onClick={() => setopenpopupMachine(true)}
//                     />
//                   </Tooltip>
//                 </span>
//               </Grid>




//               {/* pokayoga============= */}
//               <Grid item xs={12} sm={6}>
//                 <span style={{ display: "flex", alignItems: "center" }}>
//                   <Autocomplete
//                     multiple
//                     fullWidth
//                     id="pokayoke-tags-demo"
//                     options={pokayoke}
//                     disableCloseOnSelect
//                     getOptionLabel={(option) => option?.name}
//                     onChange={(e, value) => {
//                       formik.setFieldValue("pokayoke", value);
//                     }}
//                     value={formik.values.pokayoke}
//                     onBlur={formik.handleBlur}
//                     renderOption={(props, option, { selected }) => (
//                       <div
//                         {...props}
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           alignItems: "center",
//                           padding: "8px",
//                           borderBottom: "1px solid #ccc",
//                         }}
//                       >
//                         <div style={{ display: "flex", alignItems: "center" }}>
//                           <Checkbox
//                             icon={icon}
//                             checkedIcon={checkedIcon}
//                             checked={
//                               pokayokechecklist
//                                 ? formik.values.pokayoke.some(
//                                   (item) => item?.name === option?.name
//                                 )
//                                 : selectedpokayoka
//                                   ?.map((i) => i?.category)
//                                   ?.some(
//                                     (item) =>
//                                       item?.name?.trim() === option?.name
//                                   ) || selected
//                             }
//                             onClick={(e) => {
//                               setPokayokechecklist(true);
//                               e.stopPropagation();
//                               const selectedItems = formik.values.pokayoke;
//                               const itemName = option?.name;
//                               const isItemAlreadySelected = selectedItems.some(
//                                 (item) => item?.name === itemName
//                               );

//                               if (isItemAlreadySelected) {
//                                 const updatedItems = selectedItems.filter(
//                                   (item) => item?.name !== itemName
//                                 );
//                                 formik.setFieldValue("pokayoke", updatedItems);
//                               } else {
//                                 formik.setFieldValue("pokayoke", [
//                                   ...selectedItems,
//                                   option,
//                                 ]);
//                               }
//                             }}
//                           />
//                           <span>{option?.name}</span>
//                         </div>
//                         <DeleteIcon
//                           fontSize="small"
//                           sx={{ color: "red" }}
//                           onClick={() => {
//                             onDeletePokayoke(option);
//                           }}
//                         />
//                       </div>
//                     )}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label=<span>
//                          Poka yoke
//                           <sup
//                             style={{
//                               color: "red",
//                               fontSize: "small",
//                               fontWeight: "bolder",
//                               position: "relative",
//                               top: "2px",
//                             }}
//                           >
//                             {" "}
//                             *
//                           </sup>
//                         </span>
//                         placeholder=""
//                         variant="standard"
//                         error={
//                           formik.touched.pokayoke &&
//                           Boolean(formik.errors.pokayoke)
//                         }
//                         helperText={
//                           formik.touched.pokayoke && formik.errors.pokayoke
//                         }
//                       />
//                     )}
//                   />
//                   <Tooltip title="Add pokayoke" arrow>
//                     <AddBoxIcon
//                       fontSize="large"
//                       sx={{ cursor: "pointer" }}
//                       onClick={() => setopenpokayoke(true)}
//                     />
//                   </Tooltip>
//                 </span>
//               </Grid>
//             </Grid>

//             <MDBox mt={5} width="100%" display="flex" justifyContent="flex-end">
//               <MDButton type="submit" variant="gradient" color="dark">
//                 {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
//               </MDButton>
//             </MDBox>
//           </MDBox>
// )}
//         </DialogContent>
//       </form>
//       <CreateChecklist
//         openpopupChecklist={openpopupChecklist}
//         setopenpopupChecklist={setopenpopupChecklist}
//       />
//       <CreateMachine
//         openpopupMachine={openpopupMachine}
//         setopenpopupMachine={setopenpopupMachine}
//       />
//       <CreatePokayoke
//         setopenpokayoke={setopenpokayoke}
//         openpokayoke={openpokayoke}
//       />
//     </Dialog>
//   );
// };

// export default Stationedit;

