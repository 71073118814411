import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

//edit icon
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import MDButton from "components/MDButton";

import { getHeaderchecklist } from "./service/Headerchecklist";
import Checklistview from "./Checklistview";

function Pokayokalist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { headerchecklist, loading } = useSelector(
    (state) => state.headerchecklist
  );
  const { operatorCode } = useSelector((state) => state.operatorCode);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [openpopupRole, setopenpopupRole] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function viewChecklist(item) {
    setopenpopup(true);
    setSelectedRole(item);
  }

  useEffect(() => {
    const data = {
      stationcode: operatorCode,
      operatorid: auth.profileid,
      type: "POKAYOGA",
    };

    dispatch(getHeaderchecklist(data));
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    color: "blue",
    marginRight: "9px",
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

      return ` ${formattedDate} ${formattedTime}`;

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Card>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Pokayoke List
                      </MDTypography>
                    </span>

                    {/* <span
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                >
                  <MDButton
                    color="success"
                    onClick={() => setopenpopupRole(true)}
                  >
                    ADD
                  </MDButton>
                </span> */}
                  </div>
                </MDBox>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "name", accessor: "operatorname" },
                      { Header: "stationcode", accessor: "stationcode" },
                      { Header: "type", accessor: "type" },
                      {
                        Header: "Created Date",
                        accessor: "createddate",
                        Cell: ({ value }) => {
                          return formatDateTime(value)
                          // const formattedDate = new Date(value).toDateString();
                          // return formattedDate;
                        },
                      },

                      {
                        Header: "Action",
                        accessor: "action",

                        Cell: ({ row }) => (
                          <div>
                            <Link to={{}}>
                              <RemoveRedEyeIcon
                                fontSize="medium"
                                style={iconStyleedit}
                                onClick={() => {
                                  viewChecklist(row.original);
                                }}
                              />
                            </Link>

                            {/* <Link to={{}}>
                          <MdDelete
                            style={iconStyle}
                            onClick={() => {
                              onDelete(row.original);
                            }}
                          />
                        </Link> */}
                          </div>
                        ),
                      },
                    ],
                    rows: headerchecklist || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      {openpopup ? (
        <Checklistview
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Pokayoke View"}
          selectedRole={selectedRole}
         
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
}


export default Pokayokalist;