import { createSlice } from "@reduxjs/toolkit";
import { getNoOfParts } from "../service/Noofparts";

const initialState = {
  noofpart: {
    datasets: { label: 'Number of Parts Manufactured', data: [] },
    error: null,
    labels: [],
    objects: null,
  },
  loading: false,
  error: null,
};

export const noofpartDetail = createSlice({
  name: "noofpartDetail",
  initialState,
  reducers: {
    updateNoOfPartData: (state, action) => {
      const newData = action.payload;
      state.noofpart.labels = newData?.labels;
      state.noofpart.datasets.data = newData?.data;
    },
  },
  extraReducers: {
    [getNoOfParts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getNoOfParts.fulfilled]: (state, action) => {
      state.loading = false;
      state.noofpart = action.payload; 
    },
    [getNoOfParts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { updateNoOfPartData } = noofpartDetail.actions;
export default noofpartDetail.reducer;
