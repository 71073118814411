const Base_Url = ""

export const APIs = {
    "getCategory": "/category-service/api/Category/v1/get",
    "getMachineList": "/machine-master/api/Machine/v2/get",
    "getStationAssociation" : "/association-master-service/api/AssociationMaster/v2/get",
    "createStationAssociation" : "/association-master-service/api/AssociationMaster/v2/create",
    "updateStationAssociation" : "/association-master-service/api/AssociationMaster/v2/update",
    "getStationAssociationsingle" : "/association-master-service/api/AssociationMaster/v2/getSingle",
    "reportServiceApi" : "report-service/api/Report/v2",
    "attributeServiceApi" : "attribute-service/api/Attribute/v2",
}
