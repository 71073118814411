import { createSlice } from "@reduxjs/toolkit";
import { createShift, deleteShift, getShift, updateShift } from "../service/Shift";






const initialState = {
  shift: [],
  loading: false,
  error: null,
};

export const shiftDetail = createSlice({
  name: "shiftDetail",
  initialState,
  extraReducers: {
    [createShift.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createShift.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.shift.push(action.payload.data.dto);
    },
    [createShift.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getShift.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getShift.fulfilled]: (state, action) => {
      console.warn(action.payload)
      state.loading = false;
      state.shift = action.payload.data.dtoList;

    },
    [getShift.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteShift.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteShift.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.shift = state.shift.filter((shift) => shift.id !== id);
      }
    },
    [deleteShift.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
   
    },
    [updateShift.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateShift.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.shift.findIndex(shift => shift.id === action.payload.data.dto.id)
      state.shift[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateShift.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default shiftDetail.reducer;
