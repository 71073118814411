/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import swal from "sweetalert";

function ReportsLineChart({
  color,
  title,
  description,
  date,
  chart,
  filterOptions,
  showDateInput,
  attributefilter,
  showDateInputPrev,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onLabelChange,
  isLabelAutocomplete,
  labelAutocompleteName,
  historylabels,
  hasTime,
  datemargin,
}) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHistoryDate, setSelectedHistoryDate] = useState(new Date());
  const [selectedLabel, setSelectedLabel] = useState(localStorage.getItem("part-date")?.replace(/"/g, ""));
  
  console.log(attributefilter);


  const defaultpropsstation = {
    options: !filterOptions ? [{ name: "Loading...", id: 0 }] : filterOptions,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  console.log(
    "attributefilter",
    attributefilter?.filter(
      (item) => item.stationcode === selectedFilter?.code
    ),
    selectedFilter?.code
  );

  const defaultpropsattribute = {
    options: !attributefilter
      ? [{ name: "Loading...", id: 0 }]
      : attributefilter?.filter(
          (item) => item.stationcode === selectedFilter?.code
        ),
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsLabel = {
    options: historylabels || [],
    getOptionLabel: (option) => option,
    isOptionEqualToValue: (option, value) => option === value,
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    console.log("newDate",newDate)
    setSelectedDate(newDate);
    const data=[{name:"anlit",class:"hhh"}]
    
  };

  

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    const selectedStartDate = new Date(newStartDate);

    if (endDate && selectedStartDate > new Date(endDate)) {
      swal({
        icon: "error",
        title: "Invalid Date Range",
        text: "Start date cannot be after than the end date.",
      }).then(() => {});
    } else if (
      hasTime &&
      endDate &&
      new Date(selectedStartDate) > new Date(endDate)
    ) {
      swal({
        icon: "error",
        title: "Invalid Date Range",
        text: "start date and time cannot be after than the end date and time.",
      }).then(() => {});
    } else {
      onStartDateChange(newStartDate);
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    const selectedEndDate = new Date(newEndDate);
    if (startDate && new Date(startDate) > selectedEndDate) {
      swal({
        icon: "error",
        title: "Invalid Date Range",
        text: "End date cannot be before than the start date.",
      }).then(() => {});
    } else if (
      hasTime &&
      startDate &&
      new Date(startDate) > new Date(selectedEndDate)
    ) {
      swal({
        icon: "error",
        title: "Invalid Date Range",
        text: "End date and time cannot be before than the start date and time.",
      }).then(() => {});
    } else {
      onEndDateChange(newEndDate);
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              <Line data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            {filterOptions && (
              <Autocomplete
                {...defaultpropsstation}
                sx={{ width: 160, minHeight: 40, ml: "auto", mr: 1 }}
                onChange={(event, newValue) => {
                  setSelectedFilter(newValue);
                  setSelectedAttribute("");
                }}
                renderInput={(params) => (
                  <MDInput {...params} label="Select Station" />
                )}
              />
            )}
            {attributefilter && (
              <Autocomplete
                {...defaultpropsattribute}
                sx={{ width: 160, ml: "auto", mr: 1 }}
                value={selectedAttribute}
                onChange={(event, newValue) => {
                  setSelectedAttribute(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} label="Select Attribute" />
                )}
              />
            )}
            {showDateInput && (
              <>
                {!hasTime && (
                  <MDInput
                    type="date"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      width: 160,
                      minHeight: 30,
                      ml: "auto",
                      mr: 1,
                      marginBottom: datemargin ? 1 : 0,
                    }}
                    label="Select Date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                )}
                {hasTime && (
                  <>
                    {/* <MDInput
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    label="Select Time"
                    sx={{ width: 160, minHeight: 30 }}
                  /> */}
                    <MDInput
                      type="datetime-local"
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: 160, minHeight: 30, mr: 2 }}
                      onChange={handleStartDateChange}
                    />
                    <MDInput
                      type="datetime-local"
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: 160, minHeight: 30 }}
                      onChange={handleEndDateChange}
                    />
                  </>
                )}
              </>
            )}
            {/* {showDateInputPrev && (
              <>
                <MDInput
                  type="date"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  sx={{ mr: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Start Date" />
                  )}
                />
                <MDInput
                  type="date"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Select End Date" />
                  )}
                />
              </>
            )} */}
          
            {isLabelAutocomplete && historylabels && (
              <Autocomplete
                {...defaultPropsLabel}
                sx={{ width: 160, minHeight: 30, ml: "auto", mr: 1 }}
                value={selectedLabel}
                onChange={(event, newValue) => {
                  setSelectedLabel(newValue);
                  onLabelChange(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} label={labelAutocompleteName} />
                )}
              />
            )}
            {/* <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography> */}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default ReportsLineChart;
