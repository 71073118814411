import { setOperatorCode } from "app/Codereducer/codeParamSlice";
import axios from "../../axiosinstance"



export const logIn = (data,stationcode) => async (dispatch) => {

   
  console.log("Code Parameter:", stationcode);
    dispatch({ type: "LOGIN_PENDING", payload: "" })
    try {

        const resp = await axios.post("profile-service-mongo/api/ProfileEntity/v2/login", data)
        dispatch(setOperatorCode(stationcode));
        dispatch({ type: "LOGIN_FULFILLED", payload: resp.data })
       
       
    } catch (error) {
        dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data })
        dispatch(setOperatorCode(stationcode));
    }
   
}
