import React, { useEffect, useState } from "react";
import CreateRecord from "./CreateRecord";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Pokayoka from "./Pokayoka";

const ChecklistOperator = () => {


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 style={{textAlign:"center"}}>Machine check List</h2>
      <CreateRecord/>
      <MDBox mt={3}>
      <h2 style={{textAlign:"center"}}>Pokayoke List</h2>
        <Pokayoka/>
      </MDBox>
     
    </DashboardLayout>
  );
};

export default ChecklistOperator;
