import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

//edit icon
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Autocomplete, TextField, FormControlLabel } from "@mui/material";

import MDButton from "components/MDButton";

import { getHeaderchecklist } from "./service/Headerchecklist";
import Checklistview from "./Checklistview";
import { getStation } from "app/stationMaster/service/Station";
import { getUser } from "app/UserMaster/service/User";

function AdminChecklist() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

 

  const { headerchecklist, loading } = useSelector(
    (state) => state.headerchecklist
  );
  const { operatorCode } = useSelector((state) => state.operatorCode);
    const { station } = useSelector((state) => state.station);
    const { user } = useSelector((state) => state.user);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [stationcode, setStationcode] = useState("");
  const [profileid, setProfileid] = useState("");

  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [openpopupRole, setopenpopupRole] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);
  console.warn("user",user)

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultpropsstation = {
    options: !station
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : station,
    getOptionLabel: (option) => option.name + "(" + option.code + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultpropsuoperator = {
    options: !user
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : user,
    getOptionLabel: (option) => option?.lastname ? option?.firstname  +" " + option?.lastname : option?.firstname ,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  function viewChecklist(item) {
    setopenpopup(true);
    setSelectedRole(item);
  }

  useEffect(() => {

    // if (stationcode && profileid) {
    //   const data = {
    //     stationcode: stationcode,
    //     operatorid: profileid,
    //   };
    //   dispatch(getHeaderchecklist(data));
    // }
    const data = {
      stationcode: stationcode,
      operatorid: profileid,
      type:"CHECKLISTMASTER"
    };

    dispatch(getHeaderchecklist(data));
  }, [stationcode,profileid]);
  useEffect(() => {
 
    dispatch(getStation());
    dispatch(getUser("OPERATOR"));
    
    
  }, []);
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    color: "blue",
    marginRight: "9px",
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

      return ` ${formattedDate} ${formattedTime}`;

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>

          <Card>
          <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Checklist
                      </MDTypography>
                    </span>

                    {/* <span
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                >
                  <MDButton
                    color="success"
                    onClick={() => setopenpopupRole(true)}
                  >
                    ADD
                  </MDButton>
                </span> */}
                  </div>
                </MDBox>
           <MDBox style={{display:"flex",flexDirection:"row"}}>
               
                  <MDBox pl={3} pr={3} lineHeight={1}>
                    <div style={addButtonStyle}>
                      <span
                        style={{
                          alignSelf: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                        <Autocomplete
                         
                          disablePortal
                          id="combo-box-demo"
                          sx={{ width: 180 }}
                          {...defaultpropsstation}
                          onChange={(event, newValue) => {
                            setStationcode(newValue?.code);
                            
                           
                           
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Station" />
                          )}
                        />
                      </span>
                    </div>
                  </MDBox>
                
            
               
                  <MDBox pl={3} pr={3} lineHeight={1}>
                    <div style={addButtonStyle}>
                      <span
                        style={{
                          alignSelf: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                        <Autocomplete
                         
                          disablePortal
                          id="combo-box-demo"
                          sx={{ width: 180 }}
                          {...defaultpropsuoperator}
                          onChange={(event, newValue) => {
                            setProfileid(newValue?.profileid);
                            
                           
                           
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Operator" />
                          )}
                        />
                      </span>
                    </div>
                  
                </MDBox>
              </MDBox>
             
              
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
               
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "name", accessor: "operatorname" },
                      { Header: "stationcode", accessor: "stationcode" },
                      {
                        Header: "Created Date",
                        accessor: "createddate",
                        Cell: ({ value }) => {
                          return formatDateTime(value)
                          // const formattedDate = new Date(value).toDateString();
                          // return formattedDate;
                        },
                      },

                      {
                        Header: "Action",
                        accessor: "action",

                        Cell: ({ row }) => (
                          <div>
                            <Link to={{}}>
                              <RemoveRedEyeIcon
                                fontSize="medium"
                                style={iconStyleedit}
                                onClick={() => {
                                  viewChecklist(row.original);
                                }}
                              />
                            </Link>

                            {/* <Link to={{}}>
                          <MdDelete
                            style={iconStyle}
                            onClick={() => {
                              onDelete(row.original);
                            }}
                          />
                        </Link> */}
                          </div>
                        ),
                      },
                    ],
                    rows: headerchecklist || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      {openpopup ? (
        <Checklistview
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Checklist View"}
          selectedRole={selectedRole}
        />
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
}




export default AdminChecklist
