
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const createShift = createAsyncThunk("createShift", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.post(
      `event-service/api/event/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getShift = createAsyncThunk("getShift", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.get(
      `event-service/api/event/v1/get?type=SHIFT`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateShift = createAsyncThunk("updateShift", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.put(
      `event-service/api/event/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteShift = createAsyncThunk("deleteShift", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.put(
      `event-service/api/event/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




