

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getMachine, updateMachine } from "../service/Machine";
import { MoonLoader } from "react-spinners";

const Machinedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedMachine, openpopup, setopenpopup } = props;
  const [loading, setLoading] = useState(false);

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Machine Name is required"),
    code: Yup.string().required("Machine Code is required"),
    tags: Yup.string().required("Tags is required"),
   
  });
 
  
  const formik = useFormik({
    initialValues: {
      code: selectedMachine?.code || "",
      name: selectedMachine?.name || "",
      description: selectedMachine?.description || "",
      tags: selectedMachine?.tags || "",

    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      // Create a copy of selectedmachine with updated values
      const updatedBrand = {
        ...selectedMachine,

        code: values.code,
        name: values.name,
        description: values.description,
     
        tags: Array.isArray(values.tags) ? values.tags : [values.tags],
      
      };


      // Dispatch the action to update the brand in the Redux store
      try{
      const response = await dispatch(updateMachine(updatedBrand));

      // Close the dialog
      await dispatch(getMachine());

      setopenpopup(false);
      setLoading(false);
      swal("Good job!", "Machine has been successfully updated.", "success");
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      handleClose();
      swal("", "Something went wrong!", "error");
    }
    },
  
    
  });

  useEffect(() => {
    // Function to convert an array to a string with comma-separated values
    const arrayToString = (array) => {
      return Array.isArray(array) ? array.join(', ') : array;
    };
  
    // Update the form values when selectedRole changes
    formik.setValues({
      code: selectedMachine?.code || "",
      name: selectedMachine?.name || "",
      description: selectedMachine?.description || "",
      tags: arrayToString(selectedMachine?.tags || ""),
    });
  }, [selectedMachine]);
  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth >
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent fullWidth >
          <Grid item xs={12} lg={12}>
            <MDBox mt={1.625}>

              <MDBox mb={2} mt={1}>
                <MDInput
                  type="text"
                  label=<span>
                  Machine Code
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                  variant="standard"
                  fullWidth
                  id="machinecode"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={
                    formik.touched.code && formik.errors.code ? (
                      <p
                        style={{
                          fontWeight: "400",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.code}
                      </p>
                    ) : null
                  }
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
              </MDBox>


              <MDBox mb={2} mt={1}>
                <MDInput
                  type="text"
                  label=<span>
                  Machine Name
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                  variant="standard"
                  fullWidth
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name && formik.errors.name ? (
                      <p
                        style={{
                          fontWeight: "400",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.name}
                      </p>
                    ) : null
                  }
                />
              </MDBox>


              <MDBox mb={2} mt={1}>
                <MDInput
                  type="text"
                  label="Machine Description"
                  variant="standard"
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
              </MDBox>


              <MDBox mb={2} mt={1}>
                <MDInput
                  type="text"
                  label=<span>
                  Tags
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                  variant="standard"
                  fullWidth
                  id="tags"
                  name="tags"
                  // value={formik.values.tags.join(',')} 
                  value={Array.isArray(formik.values.tags) ? formik.values.tags.join(',') : formik.values.tags}
                  onChange={formik.handleChange}
                  // error={formik.touched.tags}
                  // error={formik.touched.tags && (formik.errors.tags)}
                  error={formik.touched.tags && Boolean(formik.errors.tags)}
                  helperText={
                    formik.touched.tags && formik.errors.tags ? (
                      <p
                        style={{
                          fontWeight: "400",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.tags}
                      </p>
                    ) : null
                  }
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
              </MDBox>


            </MDBox>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Machinedit;


