/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "../schemas/form";
const {
  formField: { type, name, displayname, description, uomsymbol, modelno, stationcode, processid, uomdescription, upperlimit, lowerlimit, userdisplay},
} = checkout;


const initialValues = {
  [type.name]: "",
  [name.name]: "",
  [displayname.name]: "",
  [description.name]: "",
  [uomsymbol.name]: "",
  [modelno.name]: "",
  [stationcode.name]: "",
  // [machinecode.name]: "",
  [processid.name]: "",
  [uomdescription.name]: "",
  [upperlimit.name]: "",
  [lowerlimit.name]: "",
  [userdisplay.name]: false,
};

export default initialValues;