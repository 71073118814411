import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import { TextField, Select, InputLabel } from "@mui/material";

import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { getAttributeVariationbypart } from "./service/Attributebypart";
import { getAttribute } from "app/attributeMaster/service/Attribute";
import MDButton from "components/MDButton";

function Attributebypart({
  color,
  title,
  description,

  chart,
  datemargin,
}) {
  const dispatch = useDispatch();
  // const { partprocessed, loading, error } = useSelector(
  //   (state) => state.partprocessed
  // );
  const { station } = useSelector((state) => state.station);
  const { attribute } = useSelector((state) => state.attribute);

  const { attributevariationpart, loading, error } = useSelector(
    (state) => state.attributevariationpart
  );

  const stationcodelocal = localStorage
    .getItem("partstation-code")
    ?.replace(/"/g, "");
  const attributenamelocal = localStorage
    .getItem("partattribute-name")
    ?.replace(/"/g, "");
  const daylocal = localStorage.getItem("part4-Day")?.replace(/"/g, "");
  const attributelocal = localStorage.getItem("attribute-list");

  const [attributeList, setAttributeList] = useState(
    JSON.parse(attributelocal) ? JSON.parse(attributelocal) : []
  );
  const [stationcode, setStationcode] = useState(
    stationcodelocal ? stationcodelocal : ""
  );
  const [attributename, setAttributename] = useState(
    attributenamelocal ? attributenamelocal : ""
  );

  const [day, setDay] = useState(daylocal ? daylocal : "5");

  const { data, options } = configs(
    attributevariationpart.labels || [],
    attributevariationpart.datasets || {}
  );

  function getAttributeData(code) {
    const result = attribute?.filter((item) => item.stationcode === code);
    localStorage.setItem("partattribute-list", JSON.stringify(result));

    setAttributeList(result);
  }

  useEffect(() => {
    dispatch(getAttribute());

    const fetchData = async () => {
      const data = {
        station: stationcode,
        attributeName: attributename,
        day: day,
      };
      const response = await dispatch(getAttributeVariationbypart(data));

      if (response.error && !loading) {
        swal({
          icon: "error",
          title: "Error",
          text: response?.payload?.message
            ? response?.payload?.message
            : error?.message,
        });
      }
    };

    fetchData();
  }, []);

  const filter = async (attributename, day) => {
    const data = {
      station: stationcode,
      attributeName: attributename,
      day: day,
    };

    const response = await dispatch(getAttributeVariationbypart(data));

    if (response.error && !loading) {
      swal({
        icon: "error",
        title: "Error",
        text: response?.payload?.message
          ? response?.payload?.message
          : error?.message,
      });
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              {loading ? (
                <CircularProgress color="inherit" size={40} />
              ) : (
                <Line data={data} options={options} />
              )}
            </MDBox>
          ),
          [chart, color, loading]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <Select
              native
              variant="outlined"
              style={{ width: "50%", marginRight: "3px" }}
              value={stationcode}
              name="stationvalue"
              id="stationvalue"
              onChange={(e) => {
                const stationvalue = e.target.value;
                localStorage.setItem(
                  "partstation-code",
                  JSON.stringify(stationvalue)
                );

                setStationcode(e.target.value);
                setAttributename("");

                // const selectedDepartmentObj =
                //   station.find((obj) => obj.code === stationvalue) || {};

                getAttributeData(stationvalue);
              }}
            >
              <option value="" disabled selected>
                Select Station
              </option>
              {station.map((obj) => (
                <option key={obj.id} value={obj.code}>
                  {obj.name}
                </option>
              ))}
            </Select>
            <Select
              native
              variant="outlined"
              style={{ width: "50%", marginRight: "3px", marginLeft: "auto" }}
              name="attributeame"
              value={attributename}
              id="attributeame"
              onChange={(e) => {
                const attribute = e.target.value;
                localStorage.setItem(
                  "partattribute-name",
                  JSON.stringify(attribute)
                );

                setAttributename(attribute);
                // const selectedDepartmentObj =
                //   station.find((obj) => obj.code === stationvalue) || {};
              }}
            >
              <option value="" disabled selected>
                Select Attribute
              </option>
              <option value=""></option>
              {attributeList.map((obj) => (
                <option key={obj.id} value={obj.name}>
                  {obj.name}
                </option>
              ))}
            </Select>
            <Select
              native
              variant="outlined"
              style={{ width: "50%", marginRight: "3px", marginLeft: "auto" }}
              name="days"
              value={day}
              id="days"
              onChange={(e) => {
                const days = e.target.value;
                localStorage.setItem("part4-Day", JSON.stringify(days));

                setDay(days);
                // const selectedDepartmentObj =
                //   station.find((obj) => obj.code === stationvalue) || {};
              }}
            >
              <option value="" disabled selected>
                Select Part
              </option>
              <option value=""></option>
              <option key="1" value="1">
                Last 1 Part
              </option>
              <option key="2" value="2">
                Last 2 Parts
              </option>
              <option key="3" value="3">
                Last 3 Parts
              </option>
              <option key="4" value="4">
                Last 4 Parts
              </option>
              <option key="5" value="5">
                Last 5 Parts
              </option>
              <option key="6" value="6">
                Last 6 Parts
              </option>
              <option key="7" value="7">
                Last 7 Parts
              </option>
              <option key="8" value="8">
                Last 8 Parts
              </option>
              <option key="9" value="9">
                Last 9 Parts
              </option>
            </Select>
            <MDButton
              style={{ width: "13%", marginRight: "3px" }}
              color="secondary"
              onClick={() => {
                filter(attributename, day);
              }}
            >
              filter
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Attributebypart.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  chart: PropTypes.string,
  datemargin: PropTypes.bool,
};

export default Attributebypart;
