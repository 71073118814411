
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const createPokayoke = createAsyncThunk("createPokayoke", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.post(
      `category-service/api/Category/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getPokayoke = createAsyncThunk("getPokayoke", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.get(
      `category-service/api/Category/v1/get?type=POKAYOKE`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updatePokayoke = createAsyncThunk("updatePokayoke", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deletePokayoke = createAsyncThunk("deletePokayoke", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


