import { createSlice } from "@reduxjs/toolkit";
import { fileUpload, getFile, deleteFile } from "../service/FileUpload";

const initialState = {
  fileupload: [],
  loading: false,
  error: null,
};

export const fileUploadDetail = createSlice({
  name: "fileUploadDetail",
  initialState,
  extraReducers: {
    [fileUpload.pending]: (state) => {
      state.loading = true;
    },
    [fileUpload.fulfilled]: (state, action) => {
      console.log("state",state.fileUpload)
      state.loading = false;
      state.fileupload.push(action.payload?.data?.dto);
    },
    [fileUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.fileupload = action.payload;

    },
    [getFile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteFile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteFile.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
    },
    [deleteFile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default fileUploadDetail.reducer;
