import { createSlice } from "@reduxjs/toolkit";
import { createConfiguration, deleteConfiguration, getConfiguration, updateConfiguration } from "../service/Configuration";

const initialState = {
  configuration: [],
  loading: false,
  error: null,
};

export const configurationDetail = createSlice({
  name: "configurationDetail",
  initialState,
  extraReducers: {
    [createConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createConfiguration.fulfilled]: (state, action) => {
      console.log("state",state.configuration)
      state.loading = false;
      state.configuration.push(action.payload.data.dto);
    },
    [createConfiguration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
      state.configuration = action.payload.data.dtoList;

    },
    [getConfiguration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.configuration = state.configuration.filter((configuration) => configuration.id !== id);
      }
    },
    [deleteConfiguration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.configuration.findIndex(configuration => configuration.id === action.payload.data.dto.id)
      state.configuration[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateConfiguration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default configurationDetail.reducer;
