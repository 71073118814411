import { createSlice } from "@reduxjs/toolkit";
import { createProcess, deleteProcess, getProcess, updateProcess } from "../service/ProcessMaster";






const initialState = {
  processmaster: [],
  loading: false,
  error: null,
};

export const processmasterDetail = createSlice({
  name: "processmasterDetail",
  initialState,
  extraReducers: {
    [createProcess.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createProcess.fulfilled]: (state, action) => {
      console.log("state",state.processmaster)
      state.loading = false;
      state.processmaster.push(action.payload.data.dto);
    },
    [createProcess.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getProcess.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProcess.fulfilled]: (state, action) => {
      state.loading = false;
      state.processmaster = action.payload.data.dtoList;

    },
    [getProcess.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteProcess.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteProcess.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.processmaster = state.processmaster.filter((processmaster) => processmaster.id !== id);
      }
    },
    [deleteProcess.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProcess.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProcess.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.processmaster.findIndex(processmaster => processmaster.id === action.payload.data.dto.id)
      state.processmaster[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateProcess.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default processmasterDetail.reducer;
