import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";
import { FormControl, TextField } from "@mui/material";

import { createMachinechecklist } from "../service/MachineChecklist";

import { getCategory } from "../service/Category";

export default function CreateChecklist(props) {
  const { openpopupChecklist, setopenpopupChecklist } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [ selectedChecklist, setSelectedChecklist ] = useState({});

  const validationSchema = Yup.object({
    checklistname: Yup.string().required("Checklist Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      checklistname: "" ,
      checklistdescription: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      console.log("Form Values:", values);
      const requestData = {
        name: values.checklistname.trim(),
        desc: values.checklistdescription.trim(),
        recstatus: "OPEN",
        resourcecode: "DAYCO",
        status: "Active",
        syncstatus: "SYNCED",
        type: "MACHINECHECKLIST",
      };
      try {
        await dispatch(createMachinechecklist(requestData));
        resetForm();
        handleClose();
        swal("Good job!", "Checklist has been successfully added.", "success");
        await dispatch(getCategory());
      } catch (error) {
        console.error("Error submitting form:", error);
        swal("", "Something went wrong!", "error");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClose = () => {
    setSelectedChecklist({});
    setopenpopupChecklist(false);
  };

  return (
    <Dialog
      open={openpopupChecklist}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <form onSubmit={formik.handleSubmit}>
            <Card>
              <MDBox mx={2} mt={-3}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={-2}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="small"
                    color="white"
                    mt={1}
                  >
                    Machine Checklist Master
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox p={3}>
                <Grid container spacing={3} mt={0.5}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="checklistname"
                        name="checklistname"
                        label=<span>
                          Machine Checklist Name
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        value={formik.values.checklistname || selectedChecklist?.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.checklistname &&
                          Boolean(formik.errors.checklistname)
                        }
                        helperText={
                          formik.touched.checklistname &&
                          formik.errors.checklistname
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="checklistdescription"
                        name="checklistdescription"
                        label="Machine Checklist Description"

                        variant="standard"
                        value={formik.values.checklistdescription || selectedChecklist?.desc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.checklistdescription &&
                          Boolean(formik.errors.checklistdescription)
                        }
                        helperText={
                          formik.touched.checklistdescription &&
                          formik.errors.checklistdescription
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <MDBox
                  mt={5}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton type="submit" variant="gradient" color="dark">
                  {loading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Submit"
                  )}
                </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
}
