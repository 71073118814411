/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-designation-form",
  formField: {
    designationName: {
      name: "designationName",
      label: "Designation Name",
      type: "text",
      errorMsg: "Designation name is required.",
    },
    designationDescription: {
      name: "designationDescription",
      label: "Designation Description",
      type: "text",
      errorMsg: "Designation Description is required.",
    },
   
    departmentvalue: {
      name: "departmentvalue",
      label: "Department",
      type: "text",
      errorMsg: "Department is required.",
    },
    designation:{
      name: " designation",
      label: "Parent Designation",
      type: "text",
      errorMsg: "designation is required.",
    },
  },
};

export default form;
