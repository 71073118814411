import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import { getAttribute } from "app/attributeMaster/service/Attribute";
import DaysAnalytics from "./DaysAnalytics";
import PartsProcessed from "./PartsProcessed";
import Stationwiseattribute from "./Stationwiseattribute";
import Attributebypart from "./Attributebypart";

function Analytics() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStation());
    dispatch(getAttribute());
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={6}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={5}>
                <DaysAnalytics
                  title="Number Of Parts"
                  description="Manufactured"
                  color="info"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={5}>
                <PartsProcessed
                  color="dark"
                  title="Parts Processed"
                  description="Per Station"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={3}>
                <Stationwiseattribute
                  color="success"
                  title="Station Wise Attribute Variation"
                  description="During Selected Time"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={3}>
                <Attributebypart
                  color="secondary"
                  title="Station Wise Attribute Variation"
                  description="Number of Parts"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
