import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import { Dialog, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import axios from "../../../axiosinstance";
import { createitemMaster, getitemMaster } from "../service/ItemMaster";
import { useDispatch } from "react-redux";
import { getTypeMaster } from "app/bomMaster/service/TypeMaster";
import FormField from "./FormField";
import { getStation } from "app/stationMaster/service/Station";
import { getBOMCreation } from "app/bomMaster/service/BOMCreationMaster";

export default function CreateItemPopup(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addresslist, setAddresslist] = useState([]);
  const [selectedTypeMaster, setSelectedTypeMaster] = useState(null);
  const [idCounter, setIdCounter] = useState(0);
  const { typemaster } = useSelector((state) => state.typemaster);
  const { bomcreationmaster } = useSelector((state) => state.bomcreationmaster);
  const [value, setValue] = React.useState(null);
  const [attributes, setAttributes] = useState({ keyList: [] });
  const [attributeObject, setAttributeObject] = useState({});
  const [attributeNames, setAttributeNames] = useState([]);
  const [attFlag, setAttFlag] = useState(false);
  const { station } = useSelector((state) => state.station);
  const dispatch = useDispatch();

  const initialValues = {
    itemtype: "",
    itemname: "",
    itemdescription: "",
    itemcode: "",
    stationcode: "",
    bom: "",
    stationname: "",
  };

  const validationSchema = Yup.object({
    itemtype: Yup.string().required("Item Type is required"),
    itemname: Yup.string().required("Item Name is required"),
    itemdescription: Yup.string().required("Item Description is required"),
    itemcode: Yup.string().required("Item Code is required"),
    stationcode: Yup.string().when("itemtype", {
      is: "PART",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Station Code is required"),
    }),
    bom: Yup.string().when("itemtype", {
      is: "PART",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Bill of Material (BOM) is required"),
    }),
  });

  const handleClose = () => {
    setopenpopupItem(false);
    setData([]);
    setValue(null);
    setAttributes({ keyList: [] });
    setAttributeNames([]);
  };

  const { openpopupItem, setopenpopupItem } = props;

  const handleSubmit = (values, { resetForm }) => {
    const newAttributeObject = {};
    values.attributes?.keyList.forEach((key) => {
      switch (key) {
        case "type":
          newAttributeObject[key] = values.itemtype;
          break;
        case "code":
          newAttributeObject[key] = values.itemcode;
          break;
        case "modelno":
          newAttributeObject[key] = values.bom;
          break;
        case "stationcode":
          newAttributeObject[key] = values.stationcode;
          break;
        default:
          newAttributeObject[key] = values[key];
      }
    });
    setAttributeObject(newAttributeObject);
    setAttributeNames(Object.keys(newAttributeObject));
    const newData = {
      uid: idCounter,
      itemtype: values.itemtype,
      itemname: values.itemname,
      itemdescription: values.itemdescription,
      itemcode: values.itemcode,
      stationcode: values.stationcode,
      stationname: values.stationname,
      bom: values.bom,
      attributes: newAttributeObject,
    };
    setData([...data, newData]);
    setIdCounter(idCounter + 1);
    // resetForm();
  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    // { Header: "item type", accessor: "itemtype" },
    // { Header: "item name", accessor: "itemname" },
    // { Header: "item description", accessor: "itemdescription" },
    // { Header: "item code", accessor: "itemcode" },
    ...attributeNames.map((attribute) => ({
      Header: attribute,
      accessor: `attributes.${attribute}`,
    })),
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
      width: "12%",
    },
  ];
  async function finasubmit() {
    setLoading(true);
    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setLoading(false);
    } else {
      const finalObject = {};
      for (let i = 0; i < data.length; i++) {
        finalObject[data[i].attributes.name] = data[i].attributes;
      }
      const requestData = {
        name: data[0].itemname.trim(),
        description: data[0].itemdescription.trim(),
        type: data[0].itemtype,
        attributes: finalObject,
        code: data[0].itemcode,
        stationcode: data[0].stationcode,
        stationname: data[0].stationname,
        recstatus: "OPEN",
        resourcecode: "DAYCO",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      };
      try {
        const response = await dispatch(createitemMaster(requestData));
        setLoading(false);
        handleClose();
        swal(
          "Good job!",
          "The Item has been successfully registered.",
          "success"
        );
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);
        handleClose();
        swal("", "Something went wrong!", "error");
      }
    }
  }

  useEffect(() => {
   
    dispatch(getStation());
    dispatch(getTypeMaster("ITEM"));
    dispatch(getBOMCreation());
  }, [dispatch]);

  return (
    <Dialog open={openpopupItem} onClose={handleClose} fullWidth maxWidth="md">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={0}
                      p={2}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        mt={2}
                      >
                        Item Master
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <Grid container spacing={3} mt={0}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="itemtype"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Item Type
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            variant="standard"
                            style={{ width: "100%" }}
                            name="itemtype"
                            id="itemtype"
                            value={value}
                            onChange={(event) => {
                              const selectedValue = event.target.value;
                              setValue(selectedValue);
                              const selectedOption = typemaster.find(
                                (option) => option.code === selectedValue
                              );
                              setSelectedTypeMaster(selectedOption);
                              if (selectedOption) {
                                attributes.keyList = selectedOption?.attributes;
                                values.attributes = attributes;
                                setAttributes(attributes);
                                if (selectedOption?.attributes == null) {
                                  setAttFlag(false);
                                } else {
                                  setAttFlag(true);
                                }
                                values.itemtype = selectedOption?.categorytype;
                              }
                            }}
                            error={touched.itemtype && Boolean(errors.itemtype)}
                          >
                            <option value=""></option>
                            {typemaster.map((obj) => (
                              <option key={obj.id} value={obj.code}>
                                {obj.categorytype}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="itemtype"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="itemname"
                          label=<span>
                            Item Name
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="itemname"
                          value={values.itemname}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="itemdescription"
                          label=<span>
                            Item Description
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                            </sup>
                          </span>
                          name="itemdescription"
                          value={values.itemdescription}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="itemcode"
                          label=<span>
                            Item Code
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="itemcode"
                          value={values.itemcode}
                        />
                      </Grid>
                      {values?.itemtype == "PRODUCT" ? (
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="stationcode"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Station Code
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                marginLeft: "4px",
                              }}
                            >
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            variant="standard"
                            style={{ width: "100%" }}
                            name="stationcode"
                            id="stationcode"
                            value={values.stationcode}
                            onChange={(e) => {
                              const stationcodeV = e.target.value;
                              setFieldValue("stationcode", stationcodeV);
                              setFieldValue(
                                "stationname",
                                station.find(
                                  (item) => item.code === stationcodeV
                                )?.name
                              );
                            }}
                            error={
                              touched.stationcode && Boolean(errors.stationcode)
                            }
                          >
                            <option value=""></option>
                            {station.map((obj) => (
                              <option key={obj.id} value={obj.code}>
                                {obj.name}&nbsp;({obj.code})
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="stationcode"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>
                      ) : null}
                      {values?.itemtype == "PRODUCT" ? (
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="bom"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Bill of Material (BOM)
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                marginLeft: "4px",
                              }}
                            >
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            variant="standard"
                            style={{ width: "100%" }}
                            name="bom"
                            id="bom"
                            value={values.bom}
                            onChange={(e) => {
                              const bomVal = e.target.value;
                              setFieldValue("bom", bomVal);
                            }}
                            error={touched.bom && Boolean(errors.bom)}
                          >
                            <option value=""></option>
                            {bomcreationmaster.map((obj) => (
                              <option key={obj.id} value={obj.modelno}>
                                {obj.name}&nbsp;({obj.modelno})
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="bom"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>
                      ) : null}
                      {attFlag &&
                        attributes.keyList.map((att, index) => {
                          if (
                            att !== "type" &&
                            att !== "code" &&
                            att !== "modelno" &&
                            att !== "stationcode"
                          ) {
                            const isUpperlimit =
                              selectedTypeMaster?.attributes[index] ===
                                "upperlimit" ||
                              selectedTypeMaster?.attributes[index] ===
                                "lowerlimit";
                            return (
                              <Grid item xs={12} sm={4} key={att}>
                                <FormField
                                  type={isUpperlimit ? "number" : "text"}
                                  label={
                                    selectedTypeMaster?.nameattributes[index]
                                  }
                                  name={att}
                                  value={attributes[att]}
                                />
                              </Grid>
                            );
                          }
                          return null;
                        })}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          size="small"
                        >
                          Add
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      border: "1px solid #727375",
                      borderRadius: "5px",
                      marginTop: "15px",
                      marginBottom: "10px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <DataTable
                      showTotalEntries={false}
                      entriesPerPage={false}
                      table={{ columns, rows: data }}
                    />
                  </Grid>
                  <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    marginRight="10px"
                    marginBottom="8px"
                  >
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => finasubmit()}
                    >
                      {loading ? (
                        <MoonLoader color="#f2fefa" size={16} />
                      ) : (
                        "submit"
                      )}
                    </MDButton>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
