import { createSlice } from "@reduxjs/toolkit";
import { createStationAssociation, deleteStationAssociation, getStationAssociation, updateStationAssociation } from "../service/StationAssociation";

const initialState = {
  stationassociation: [],
  loading: false,
  error: null,
};

export const stationassociationDetail = createSlice({
  name: "stationassociationDetail",
  initialState,
  extraReducers: {
    [createStationAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createStationAssociation.fulfilled]: (state, action) => {
      console.log("state",state.stationassociation)
      state.loading = false;
      state.stationassociation.push(action.payload.data.dto);
    },
    [createStationAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getStationAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStationAssociation.fulfilled]: (state, action) => {
      state.loading = false;
      state.stationassociation = action.payload?.data?.dtoList;

    },
    [getStationAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteStationAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteStationAssociation.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.stationassociation = state.stationassociation.filter((stationassociation) => stationassociation.id !== id);
      }
    },
    [deleteStationAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateStationAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateStationAssociation.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.stationassociation.findIndex(stationassociation => stationassociation.id === action.payload.data.dto.id)
      state.stationassociation[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateStationAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default stationassociationDetail.reducer;
