import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const createStation = createAsyncThunk(
  "createStation",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.post(
        `station-master/api/StationMaster/v2/create`,
        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getStation = createAsyncThunk(
  "getStation",
  async (data, { rejectWithValue }) => {
    // console.log("formData", data);

    try {
      if (data) {
        const response = await axios.get(
          `station-master/api/StationMaster/v2/get?code=${data}`
        );
        return await response.data;
      } else {
        const response = await axios.get(
          `station-master/api/StationMaster/v2/get`
        );
        return await response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateStation = createAsyncThunk(
  "updateStation",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.put(
        `station-master/api/StationMaster/v2/update`,
        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteStation = createAsyncThunk(
  "deleteStation",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.put(
        `station-master/api/StationMaster/v2/delete`,
        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
