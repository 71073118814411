import { createSlice } from "@reduxjs/toolkit";
import { getProductdetail } from "../service/ProductDetailservice";







const initialState = {
  productdetail: [],
  loading: false,
  error: null,
};

export const productDetail = createSlice({
  name: "productDetail",
  initialState,
  extraReducers: {
    
    [getProductdetail.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProductdetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.productdetail = action.payload.data.dtoList;

    },
    [getProductdetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
   
   
  },
});

export default productDetail.reducer;
