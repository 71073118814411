import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
} from "@mui/material";

import MDButton from "components/MDButton";

import { useDispatch } from "react-redux";
import { getBomstation } from "./service/BomStation";
import { useSelector } from "react-redux";

import ItemAccordian from "./ItemAccordian";
import Errorcard from "errorcard/Errorcard";
import ProductDetailCard from "app/webSocketUI/ProductDetailCard";
import CloseIcon from "@mui/icons-material/Close";

const Steps = () => {
  const dispatch = useDispatch();
  const { bomstation, loading } = useSelector((state) => state.bomstation);
  const [expandedStep, setExpandedStep] = useState(-1);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [hovered, setHovered] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extracting values from the path
  // const pathSegments = location.pathname.split("/");
  // const name = pathSegments[5];
  // const code = pathSegments[4];
  const { name, id } = useParams();
  const code = id
  console.log("name :", name, "code :", id, "name and code ")

  useEffect(() => {
    setError("");
    const fetchData = async () => {
      const obj = {
        code: code,
        name: name,
      };
      const response = await dispatch(getBomstation(obj));

      response?.payload?.response?.data?.aceErrors
        ? setError(
          response?.payload?.response?.data?.aceErrors[0]?.errorMessage
        )
        : setError(response?.payload?.response?.data);
    };
    fetchData();
  }, [code]);

  const handleStepToggle = (index) => {
    const currentStation = bomstation?.stations[index];
    const filterData = bomstation?.stations?.find(
      (i) => i.stationname === currentStation?.stationname
    );

    setData({
      code: filterData?.stationcode,
      name: name,
      type: bomstation?.header?.type,
    });
    setExpandedStep((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const steps = bomstation.stations?.map((item) => ({
    code: item.stationcode,
    label: item.stationname,
    buttonName: item.recstatus,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {error ? (
            <div>
              <Errorcard error={error} />
            </div>
          ) : (
            <>
              <ProductDetailCard data={bomstation?.header} name={name} code={code} />
              <Grid container mt={7} mb={3}>
                <Grid item xs={12} sm={11.7}>
                  <Timeline align="left">
                    {steps?.map((step, index) => (
                      <TimelineItem key={step.label}>
                        <TimelineOppositeContent
                          style={{ flex: 0 }}
                        ></TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          {index < steps?.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Accordion
                            expanded={expandedStep === index}
                            onChange={() => handleStepToggle(index)}
                            style={{
                              marginTop: expandedStep === index ? -20 : -20,
                              marginBottom: expandedStep === index ? 10 : 0,
                            }}
                          >
                            <AccordionSummary>
                              <Typography
                                style={{ textTransform: "uppercase" }}
                              >
                                {" "}
                                {`${step.label.toUpperCase()} (${step.code})`}
                              </Typography>
                              &nbsp;&nbsp;&nbsp;
                              {step.buttonName == "OK" ? (
                                <MDButton
                                  variant="contained"
                                  color="success"
                                  size="small"
                                >
                                  PASS
                                </MDButton>
                              ) : (
                                <MDButton
                                  variant="contained"
                                  color="error"
                                  size="small"
                                >
                                  FAIL
                                </MDButton>
                              )}
                            </AccordionSummary>

                            <AccordionDetails>
                              {expandedStep === index && (
                                <>
                                  <ItemAccordian
                                    data={data}
                                    key={step.label} // Add a unique key
                                  />
                                  <MDButton
                                    variant="outlined"
                                    color="success"
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setExpandedStep(-1);
                                    }}
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    style={{
                                      marginTop: 7,
                                      marginLeft: '10px',
                                      backgroundColor: hovered ? 'blue' : 'transparent',
                                      color: hovered ? 'white' : 'green',
                                      border: `1px solid ${hovered ? 'transparent' : 'green'}`,
                                    }}
                                  >
                                    <CloseIcon />
                                    &nbsp; CLOSE
                                  </MDButton>
                                </>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default Steps;
