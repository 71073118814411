import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const getFinalStation = createAsyncThunk(
  "getFinalStation",
  async (data, { rejectWithValue }) => {

    try {
      const response = await axios.get(
        `visualization-service/api/visualization/v3/getFinalStation`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
