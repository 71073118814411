
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const createMachinechecklist = createAsyncThunk("createMachinechecklist", async (data, {rejectWithValue}) => {
 

  try {
    const response = await axios.post(
      `category-service/api/Category/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getMachinechecklist = createAsyncThunk("getMachinechecklist", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.get(
      `category-service/api/Category/v1/get?type=MACHINECHECKLIST`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateMachinechecklist = createAsyncThunk("updateMachinechecklist", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteMachinechecklist = createAsyncThunk("deleteMachinechecklist", async (data, {rejectWithValue}) => {
 

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});






