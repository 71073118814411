
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create attribute


export const getAttributeList = createAsyncThunk("getAttribute", async (data, {rejectWithValue}) => {
  // console.log("formData", data);

  try {
    const response = await axios.get(
           `attribute-service/api/Attribute/v2/getV2?stationcode=${data.stationcode}&code=${data.itemcode}`,
     
    );   
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});





