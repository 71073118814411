import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAttribute, updateAttribute } from "../service/Attribute";
import {
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import { getTypeMaster } from "app/bomMaster/service/TypeMaster";
import { getProcess } from "app/bomMaster/service/ProcessMaster";
// import { getMachine } from "app/MachineMaster/service/Machine";
import { getStationAssociation } from "app/stationMaster/service/StationAssociation";
import { MoonLoader } from "react-spinners";

const AttributeEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedAttribute, openpopup, setopenpopup } = props;
  const [togglevaluechange, setToggleValueChange] = useState(false);
  const { station } = useSelector((state) => state.station);
  const { error, loading } = useSelector((state) => state.attribute);
  const { processmaster } = useSelector((state) => state.processmaster);
  const { typemaster } = useSelector((state) => state.typemaster);
  // const { machine } = useSelector((state) => state.machine);
  const { stationassociation } = useSelector(
    (state) => state.stationassociation
  );
  const [value, setValue] = useState("");
  // const [machinelist, setMachineList] = useState([]);

  const validationSchema = Yup.object({
    type: Yup.string().required("Type is required"),
    name: Yup.string().required("Attribute Name is required"),
    displayname: Yup.string().required("Display Name is required"),
    uomsymbol: Yup.string().required("UOM Symbol is required"),
    modelno: Yup.string().required("Model No. is required"),
    // machinecode: Yup.string().required("Machine Code is required"),
    stationcode: Yup.string().when(["type"], {
      is: (val) => val === "MA6694",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Station Code is required"),
    }),
    processid: Yup.string().when(["type"], {
      is: (val) => val === "MA6694" || val === "ST5955",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Process ID is required"),
    }),
  });
  const formik = useFormik({
    initialValues: {
      type:
        typemaster.find(
          (option) => option.categorytype === selectedAttribute?.type
        )?.code || "",
      name: selectedAttribute?.name || "",
      displayname: selectedAttribute?.displayname || "",
      description: selectedAttribute?.description || "",
      uomsymbol: selectedAttribute?.uom || "",
      modelno: selectedAttribute?.modelno || "",
      stationcode: selectedAttribute?.stationcode || "",
      // machinecode: selectedAttribute?.machinecode || "",
      uomdescription: selectedAttribute?.uomdescription || "",
      upperlimit: selectedAttribute?.upperlimit || "",
      lowerlimit: selectedAttribute?.lowerlimit || "",
      processid: selectedAttribute?.processid || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // setLoading(true);
      const requestData = {
        ...selectedAttribute,
        type: values.typename,
        name: values.name?.trim(),
        displayname: values.displayname?.trim(),
        description: values.description?.trim(),
        uom: values.uomsymbol?.trim(),
        modelno: values.modelno?.trim(),
        stationcode: values.stationcode,
        // machinecode: values.machinecode,
        processid: values.processid,
        uomdescription: values.uomdescription?.trim(),
        upperlimit: values.upperlimit,
        lowerlimit: values.lowerlimit,
        displayflag: togglevaluechange,
      };

      const response = await dispatch(updateAttribute(requestData));
      if (!response.error && !loading) {
        handleClose();
        swal(
          "Good job!",
          "Attribute has been successfully updated.",
          "success"
        );
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: error?.message ? error?.message : "Something went wrong!",
        });
        handleClose();
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      type:
        typemaster.find(
          (option) => option.categorytype === selectedAttribute?.type
        )?.code || "",
      name: selectedAttribute?.name || "",
      displayname: selectedAttribute?.displayname || "",
      description: selectedAttribute?.description || "",
      uomsymbol: selectedAttribute?.uom || "",
      modelno: selectedAttribute?.modelno || "",
      stationcode: selectedAttribute?.stationcode || "",
      // machinecode: selectedAttribute?.machinecode || "",
      uomdescription: selectedAttribute?.uomdescription || "",
      upperlimit: selectedAttribute?.upperlimit || "",
      lowerlimit: selectedAttribute?.lowerlimit || "",
      processid: selectedAttribute?.processid || "",
      userdisplay:
        selectedAttribute?.displayflag === "true"
          ? setToggleValueChange(true)
          : setToggleValueChange(false),
    });
    // if (
    //   typemaster.find(
    //     (option) => option.categorytype === selectedAttribute?.type
    //   )?.code === "MA6694"
    // ) {
    //   setMachineList(machine);
    // } else {
    //   setMachineList(
    //     stationassociation
    //       .filter((i) => i?.stationcode === selectedAttribute?.stationcode)
    //       .map((i) => i?.machine)
    //   );
    // }
  }, [selectedAttribute]);

  const handleClose = () => {
    setopenpopup(false);
    formik.setValues({
      type:
        typemaster.find(
          (option) => option.categorytype === selectedAttribute?.type
        )?.code || "",
      name: selectedAttribute?.name || "",
      displayname: selectedAttribute?.displayname || "",
      description: selectedAttribute?.description || "",
      uomsymbol: selectedAttribute?.uom || "",
      modelno: selectedAttribute?.modelno || "",
      stationcode: selectedAttribute?.stationcode || "",
      // machinecode: selectedAttribute?.machinecode || "",
      uomdescription: selectedAttribute?.uomdescription || "",
      upperlimit: selectedAttribute?.upperlimit || "",
      lowerlimit: selectedAttribute?.lowerlimit || "",
      processid: selectedAttribute?.processid || "",
      userdisplay:
        selectedAttribute?.displayflag === "true"
          ? setToggleValueChange(true)
          : setToggleValueChange(false),
    });
    // if (
    //   typemaster.find(
    //     (option) => option.categorytype === selectedAttribute?.type
    //   )?.code === "MA6694"
    // ) {
    //   setMachineList(machine);
    // } else {
    //   setMachineList(
    //     stationassociation
    //       .filter((i) => i?.stationcode === selectedAttribute?.stationcode)
    //       .map((i) => i?.machine)
    //   );
    // }
  };

  const toggler = (event) => {
    setToggleValueChange(event);
  };

  useEffect(() => {
    dispatch(getStation());
    dispatch(getTypeMaster());
    dispatch(getProcess());
    // dispatch(getMachine());
    dispatch(getStationAssociation());
  }, [dispatch]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="type"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "6px",
                  }}
                >
                  Type
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="type"
                  id="type"
                  value={formik.values.type}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    setValue(selectedValue);
                    const selectedOption = typemaster.find(
                      (option) => option.code === selectedValue
                    );
                    formik.setFieldValue("type", selectedOption?.code || "");
                    formik.setFieldValue(
                      "typename",
                      selectedOption?.categorytype
                    );
                    formik.setFieldValue("stationcode", "");
                    // formik.setFieldValue("machinecode", "");
                    formik.setFieldValue("processid", "");
                    // setMachineList(machine);
                  }}
                >
                  <option value=""></option>
                  {typemaster.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.categorytype}
                    </option>
                  ))}
                </Select>
                {formik.errors.type && formik.touched.type && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: 400 }}
                  >
                    {formik.errors.type}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="text"
                label=<span>
                  Attribute Name
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched.name && formik.errors.name ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.name}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="text"
                label=<span>
                  Display Name
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="displayname"
                name="displayname"
                value={formik.values.displayname}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.displayname &&
                  Boolean(formik.errors.displayname)
                }
                helperText={
                  formik.touched.displayname && formik.errors.displayname ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.displayname}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="text"
                label="Description"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="text"
                label=<span>
                  UOM Symbol
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="uomsymbol"
                name="uomsymbol"
                value={formik.values.uomsymbol}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.uomsymbol && Boolean(formik.errors.uomsymbol)
                }
                helperText={
                  formik.touched.uomsymbol && formik.errors.uomsymbol ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.uomsymbol}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="text"
                label=<span>
                  Model No.
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="modelno"
                name="modelno"
                value={formik.values.modelno}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={formik.touched.modelno && Boolean(formik.errors.modelno)}
                helperText={
                  formik.touched.modelno && formik.errors.modelno ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.modelno}
                    </p>
                  ) : null
                }
              />
            </Grid>
            {formik.values.type === "PR5934" ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="processid"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "6px",
                    }}
                  >
                    Process
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="processid"
                    id="processid"
                    value={formik.values.processid}
                    onChange={(e) => {
                      let processidV = e.target.value;
                      formik.setFieldValue("processid", processidV);
                    }}
                  >
                    <option value=""></option>
                    {/* {processmaster.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}&nbsp;({obj.stationsequence})
                    </option>
                  ))} */}
                    {processmaster.map((obj) => {
                      const combinedText = `${obj.name} (${obj.stationsequence})`;
                      let displayedText =
                        combinedText.length > 40
                          ? `${combinedText.slice(0, 37)}...`
                          : combinedText;

                      if (displayedText.endsWith("...")) {
                        displayedText =
                          combinedText.length > 43
                            ? `${combinedText.slice(0, 38)}...`
                            : combinedText;
                      }

                      return (
                        <option
                          key={obj.id}
                          value={obj.code}
                          title={combinedText}
                        >
                          {displayedText}
                        </option>
                      );
                    })}
                  </Select>
                  {formik.errors.processid && formik.touched.processid && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: 400 }}
                    >
                      {formik.errors.processid}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            {formik.values.type === "ST5955" ||
            formik.values.type === "PR5934" ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="stationcode"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "6px",
                    }}
                  >
                    Station
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="stationcode"
                    id="stationcode"
                    value={formik.values.stationcode}
                    onChange={(e) => {
                      let stationcodeV = e.target.value;
                      formik.setFieldValue("stationcode", stationcodeV);
                      // formik.setFieldValue("machinecode", "");
                      // setMachineList(
                      //   stationassociation
                      //     .filter((i) => i?.stationcode === stationcodeV)
                      //     .map((i) => i?.machine)
                      // );
                    }}
                  >
                    <option value=""></option>
                    {station.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}&nbsp;({obj.code})
                      </option>
                    ))}
                  </Select>
                  {formik.errors.stationcode && formik.touched.stationcode && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: 400 }}
                    >
                      {formik.errors.stationcode}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            {/* <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="machinecode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "6px",
                  }}
                >
                  Machine
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="machinecode"
                  id="machinecode"
                  value={formik.values.machinecode}
                  onChange={(e) => {
                    let machinecodeV = e.target.value;
                    formik.setFieldValue("machinecode", machinecodeV);
                  }}
                >
                  <option value=""></option>
                  {machinelist.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}&nbsp;({obj.code})
                    </option>
                  ))}
                </Select>
                {formik.errors.machinecode && formik.touched.machinecode && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: 400 }}
                  >
                    {formik.errors.machinecode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={4}>
              <MDInput
                type="text"
                label=<span>
                  UOM Description
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  ></sup>
                </span>
                variant="standard"
                fullWidth
                id="uomdescription"
                name="uomdescription"
                value={formik.values.uomdescription}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.uomdescription &&
                  Boolean(formik.errors.uomdescription)
                }
                helperText={
                  formik.touched.uomdescription &&
                  formik.errors.uomdescription ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.uomdescription}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="number"
                label=<span>
                  Lower Limit
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  ></sup>
                </span>
                variant="standard"
                fullWidth
                id="lowerlimit"
                name="lowerlimit"
                value={formik.values.lowerlimit}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.lowerlimit && Boolean(formik.errors.lowerlimit)
                }
                helperText={
                  formik.touched.lowerlimit && formik.errors.lowerlimit ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.lowerlimit}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MDInput
                type="number"
                label=<span>
                  Upper Limit
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "1px",
                      marginLeft: "2px",
                    }}
                  ></sup>
                </span>
                variant="standard"
                fullWidth
                id="upperlimit"
                name="upperlimit"
                value={formik.values.upperlimit}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.upperlimit && Boolean(formik.errors.upperlimit)
                }
                helperText={
                  formik.touched.upperlimit && formik.errors.upperlimit ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.upperlimit}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={togglevaluechange}
                    onClick={(e) => toggler(e.target.checked)}
                  />
                }
                label="Validation"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AttributeEdit;
