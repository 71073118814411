import React, { useEffect, useState } from "react";

import DataTable from "examples/Tables/DataTable";
import axios from "../../axiosinstance";
import CircularProgress from "@mui/material/CircularProgress";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import ViewAttribute from "./ViewAttribute.js";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const ItemAccordian = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openpopup, setopenpopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [hovered, setHovered] = useState(false);

  function transformAttributes(attributes) {
    if (!attributes || typeof attributes !== "object") {
      return [];
    }

    return Object.keys(attributes)?.map((key) => attributes[key]);
  }

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

    return ` ${formattedDate} ${formattedTime}`;
  };
  const viewData = (item) => {
    console.warn(item);
    setopenpopup(true);
    setSelectedData(item);
  };
console.warn("data",data)
  async function itemCode(name, code, type) {
    setLoading(true);

    await axios
      .get(
        `/itemmaster-service/api/itemMaster/v1/get?name=${name}&stationcode=${code}&type=${type}`
      )
      .then((response) => {
        setData(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.message);
        setData([]);
      });
  }

  useEffect(() => {
    if (props?.data?.code) {
      setData([]);
      setError("");
      itemCode(props?.data?.name, props?.data?.code, props?.data?.type);
    }
  }, [props?.data?.code, props?.data?.type]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {data.length === 0 && !loading && (
        <div
          style={{
            border: "2px solid #333",
            width: "60%",
            padding: "20px",
            borderRadius: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
            textAlign: "center",
            backgroundColor: "#f7f7f7",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "18px",
              color: error ? "red" : "#555",
            }}
          >
            {error ? error : "No data found"}
          </p>
        </div>
      )}

      {!loading &&
        data?.map((item) => (
          <div
            style={{
              border: "1.5px solid black",
              width: "97%",
              padding: 10,
              borderRadius: 10,
              marginLeft: 15,
              marginBottom: 5,
            }}
          >
            <div style={{ fontSize: "15px", color: "#7b809a" }}>
              <div>
                <text style={{ fontWeight: "bold" }}>Name:</text>

                {" " + item.name}
                {item.recstatus === "OK" ? (
                  <MDBadge
                    badgeContent="PASS"
                    size="md"
                    style={{ marginLeft: "12px" }}
                    color="success"
                    gradient
                  />
                ) : item.recstatus == undefined || null || "OPEN" ? (
                  <MDBadge
                    badgeContent="FAIL"
                    size="md"
                    style={{ marginLeft: "12px" }}
                    color="error"
                    gradient
                  />
                ) : (
                  <></>
                )}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Stationcode :</text>
                {" " + item.stationcode}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Stationname :</text>
                {" " + item.stationname}
              </div>
              
              <div>
                <text style={{ fontWeight: "bold" }}>Txn Id :</text>
                {" " + item?.sapcode}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Type :</text>
                {" " + item?.type}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Model No :</text>
                {" " + item?.modelno ? item?.modelno : ''}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Bypass :</text>
                {" " + item?.bypass && item?.bypass}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Setup Part :</text>
                {" " + item?.setuppart && item?.setuppart }
              </div>
              <div>
              <text style={{ fontWeight: "bold" }}>Created Date :</text>
                {formatDateTime(item?.createddate)}
              </div>
             {item?.modifieddate===null ||item?.modifieddate===undefined ? (
              <div>
              <text style={{ fontWeight: "bold" }}>Modified Date :</text>
              {' '}  --/--/--
              </div>
            ) : (
             
              <div>
             
                <text style={{ fontWeight: "bold" }}>Modified Date :</text>
                {formatDateTime(item?.modifieddate)}

              </div>
            )}
            <div>
               


                <MDButton
      variant="outlined"
      color="success"
      size="small"
      onClick={() => viewData(item)}
      // onMouseEnter={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
      style={{
        marginTop: 7,
        marginLeft: '10px',
        // backgroundColor: hovered ? 'blue' : 'transparent',
        // color: hovered ? 'white' : 'green',
        // border: `1px solid ${hovered ? 'transparent' : 'green'}`,
      }}
    >
      <RemoveRedEyeIcon />
                  &nbsp; attribute
    </MDButton>
              </div>
            </div>

            {/* <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={{
                columns: [
                  {
                    Header: "name",
                    accessor: "displayname",
                    width: "25%",
                  },
                  {
                    Header: "uom",
                    accessor: (row) => `${row.uomdescription} (${row.uom})`,
                    width: "30%",
                  },
                  {
                    Header: "range",
                    accessor: (row) =>
                      `(${row.lowerlimit} - ${row.upperlimit})`,
                    width: "30%",
                  },
                  {
                    Header: "value",
                    accessor: "value",
                    Cell: ({ value, row }) => {
                      const isOutOfRange =
                        value < row.original.lowerlimit ||
                        value > row.original.upperlimit;

                      return row?.original?.displayflag === "false" &&
                        row?.original?.displayflag !== null ? (
                        <span>{`${value} ${row.original.uom}`}</span>
                      ) : (
                        <span
                          style={{
                            color: isOutOfRange ? "red" : "green",
                          }}
                        >
                          {`${value} ${row.original.uom}`}
                        </span>
                      );
                    },
                    width: "30%",
                  },
                  ,
                ],
                rows: transformAttributes(item.attributes),
              }}
            /> */}
          </div>
        ))}

      {openpopup ? (
        <ViewAttribute
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"View Attribute"}
          selectedData={selectedData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ItemAccordian;
