import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
// Images
import profilePicture from "assets/images/team-4.jpg";

// @mui icons
import Icon from "@mui/material/Icon";
import RoleList from "app/Role Master/componets/RoleList";
import DepartmentList from "app/departmentMaster/components/DepartmentList";

import AttributeList from "app/attributeMaster/componets/AttributeList";

import MachineList from "app/MachineMaster/componets/MachineList";

import DesignationList from "app/designationMaster/components/DesignationList";
import UserList from "app/UserMaster/components/UserList";

import StationList from "app/stationMaster/componets/StationList";
import BOMList from "app/bomMaster/componets/BOMList";
import ItemList from "app/itemMaster/componets/ItemList";

import Analytics from "app/analytics/Analytics";

import { UploadFile } from "@mui/icons-material";

import WebSocketUI from "app/webSocketUI/WebSocketUI";
import ProcessList from "app/Process Master/componets/ProcessList";
import ConfigurationList from "app/Configuration Master/components/ConfigurationList";
import ProductList from "productlist/ProductList";
import PartList from "productlist/PartList";
import ShiftList from "app/shiftMaster/componets/ShiftList";
import AdminChecklist from "app/ChecklistOperator/AdminChecklist";
import AdminPokaypkalist from "app/ChecklistOperator/AdminPokaypkalist";
import AssociationMaster from "app/bomMaster/componets/associationMaster";
import LineProduction from "app/lineProduction/LineProduction";
import OEE from "app/oEE/OEE";
import SPC from "app/spc/SPC";
// import AssociationMaster from "app/bomMaster/componets/associationMaster/index";


const routesAfterlogin = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },

      {
        name: "Analytics",
        key: "analytics",
        route: "app/Analytics",
        component: <Analytics />,
      },

      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
     
      {
        name: "Line Production",
        key: "line-production",
        route: "/app/admin/line-production",
        component: <LineProduction/>, 
      },
      {
        name: "OEE",
        key: "OEE",
        route: "/app/admin/OEE",
        component: <OEE/>, 
      },
      {
        name: "SPC",
        key: "SPC",
        route: "/app/admin/SPC",
        component: <SPC/>, 
      },
    ]
  },

  {
    type: "collapse",
    name: "User Management",
    key: "usermasters",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Role Master",
        key: "role-master",
        route: "app/RoleMaster/role-master",
        component: <RoleList />,
      },
      {
        name: "Department Master",
        key: "department-master",
        route: "app/DepartmentMaster/department-master",
        component: <DepartmentList />,
      },
      {
        name: "Designation Master",
        key: "designation-master",
        route: "app/DesignationMaster/designation-master",
        component: <DesignationList />,
      },

      {
        name: "User Master",
        key: "user-master",
        route: "app/UserMaster/user-master",
        component: <UserList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Station Management",
    key: "stationmasters",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Attribute Master",
        key: "attribute-master",
        route: "app/AttributeMaster/attribute-master",
        component: <AttributeList />,
      },

      {
        name: "Machine Master",
        key: "machine-master",
        route: "app/MachineMaster/machine-master",
        component: <MachineList />,
      },

      {
        name: "Station Master",
        key: "station-master",
        route: "app/StationMaster/station-master",
        component: <StationList />,
      },
      {
        name: "BOM Master",
        key: "bom-master",
        route: "app/BOMMaster/bom-master",
        component: <BOMList />,
      },
      {
        name: "Process Master",
        key: "process-master",
        route: "app/ProcessMaster/process-master",
        component: <ProcessList />,
      },
      {
        name: "Item Master",
        key: "item-master",
        route: "app/ItemMaster/item-master",
        component: <ItemList />,
      },

      // {
      //   name: "Configuration Master",
      //   key: "configuration-master",
      //   route: "app/ConfigurationMaster/configuration-master",
      //   component: <ConfigurationList />,
      // },

      // {
      //   name: "Upload File",
      //   key: "upload-file",
      //   route: "/app/Component/upload-file",
      //   component: <UploadFile />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Product Masters",
    key: "productmasters",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      // {
      //   name: "Product Master",
      //   key: "product-master",
      //   route: "app/ProductMaster/product-master",
      //   component: <ProductList/>,
      // },

      // {
      //   name: "Model Master",
      //   key: "model-master",
      //   route: "app/ModelMaster/model-master",
      //   component: <ModelList/>,
      // },

      // {
      //   name: "Component Master",
      //   key: "component-master",
      //   route: "app/ComponentMaster/component-master",
      //   component: <ComponentList/>,
      // },

      // {
      //   name: "Ankit List",
      //   key: "ankit-list",
      //   route: "/app/Component/ankit-list/:id" ,
      //   component: <Ankit/>,
      // },

      {
        name: "Part Listing",
        key: "part-list",
        route: "/app/Component/partlist",
        component: <PartList/>,
      },
      // {
      //   name: "Product Detail",
      //   key: "product-detail",
      //   route: "/app/Component/product-detail" ,
      //   component: <ProductDetail/>,
      // },

      // {
      //   name: "Steps",
      //   key: "steps-ui",
      //   route: "app/Steps/steps-ui",
      //   component: <Steps/>,
      // },
      {
        name: "Product Detail",
        key: "product-detail",
        route: "/app/Component/product-detail",
        component: <WebSocketUI />,
      },
      {
        name: "Product Listing",
        key: "product-list",
        route: "/app/Component/Productlist",
        component: <ProductList/>,
      },
    ],
  },

  {
    type: "collapse",
    name: "Association Master",
    key: "associationmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Association",
        key: "association",
        route: "/app/association-master/association",
        component: <AssociationMaster />,
      },
      
      {
        name: "Upload File",
        key: "upload-file",
        route: "/app/Component/upload-file",
        component: <UploadFile />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Shift Master",
    key: "shiftmasters",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Shift List",
        key: "shift",
        route: "/app/Shiftmaster/shift-list",
        component: <ShiftList/>, 
      },
     

      
      
    ],
  },



  {
    type: "collapse",
    name: "List Master",
    key: "listmasters",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
     
      {
        name: "Check List",
        key: "admin-checklist",
        route: "/app/admin/checklist-list",
        component: <AdminChecklist/>, 
      },
      {
        name: "Poka yoke",
        key: "poka-yoke",
        route: "/app/admin/pokayoke-list",
        component: <AdminPokaypkalist/>, 
      },

      
      
    ],
  },

];
export default routesAfterlogin;

