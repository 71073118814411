import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  graph3Messages: [],
  loading: false,
  error: null,
};

export const graph3MessagesSlice = createSlice({
  name: "graph3Messages",
  initialState,
  reducers: {
    setGraph3Messages: (state, action) => {
      state.graph3Messages = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading1: (state, action) => {
      state.loading = action.payload;
    },
    setError1: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setGraph3Messages, setLoading, setError } = graph3MessagesSlice.actions;

export default graph3MessagesSlice.reducer;
