
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create attribute

export const createTypeMaster = createAsyncThunk("createTypeMaster", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `typemaster-service/api/TypeMaster/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getTypeMaster = createAsyncThunk("getTypeMaster", async (data, {rejectWithValue}) => {
  console.log("formDataxyz", data);

  try {
    if(data!==undefined){
    const response = await axios.get(
      `typemaster-service/api/TypeMaster/v1/get?category=ITEM`,
     
    );   
    return await response.data;
    }
    else{
      const response = await axios.get(
        `typemaster-service/api/TypeMaster/v1/get?category=ATTRIBUTE`,
       
      );   
      return await response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateTypeMaster = createAsyncThunk("updateTypeMaster", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `typemaster-service/api/TypeMaster/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteTypeMaster = createAsyncThunk("deleteTypeMaster", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `typemaster-service/api/TypeMaster/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




