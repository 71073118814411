
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const createCategory = createAsyncThunk("createCategory", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `category-service/api/Category/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


// export const getCategory = createAsyncThunk("getCategory", async (data, {rejectWithValue}) => {
//   console.log("formData", data);

//   try {
//     const response = await axios.get(
//       `category-service/api/Category/v1/get?type=CHECKLIST`,
     
//     );
//     return await response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });

export const getCategory = createAsyncThunk("getCategory", async (data, {rejectWithValue}) => {
  console.log("formDatassss", data);

  try {
    if(data!==undefined){
    const response = await axios.get(

     

      `category-service/api/Category/v1/get?type=CHECKLIST`,

     
    );   
    return await response.data;
    }
    else{
      const response = await axios.get(
        `category-service/api/Category/v1/get`,
       
      );   
      return await response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }


});

export const updateCategory = createAsyncThunk("updateCategory", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteCategory = createAsyncThunk("deleteCategory", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


