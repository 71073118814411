import { createSlice } from "@reduxjs/toolkit";
import { createStation, deleteStation, getStation, updateStation } from "../service/Station";

const initialState = {
  station: [],
  loading: false,
  error: null,
};

export const stationDetail = createSlice({
  name: "stationDetail",
  initialState,
  extraReducers: {
    [createStation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createStation.fulfilled]: (state, action) => {
      console.log("state",state.station)
      state.loading = false;
      state.station.push(action.payload.data.dto);
    },
    [createStation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getStation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStation.fulfilled]: (state, action) => {
      state.loading = false;
      state.station = action.payload.data.dtoList;

    },
    [getStation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteStation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteStation.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.station = state.station.filter((station) => station.id !== id);
      }
    },
    [deleteStation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateStation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateStation.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.station.findIndex(station => station.id === action.payload.data.dto.id)
      state.station[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateStation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default stationDetail.reducer;
