// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";


import { BarLoader } from "react-spinners";

import { Card } from "@mui/material";

import MDButton from "components/MDButton";

import swal from "sweetalert";
import Grid from "@mui/material/Grid";

import axios from "../../../axiosinstance";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import { Autocomplete, TextField } from "@mui/material";

import { getStation } from "app/stationMaster/service/Station";
import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBadge from "components/MDBadge";
import CreateItemPopup from "./CreateItemPopup";
import MDTypography from "components/MDTypography";
import { MdDelete } from "react-icons/md";
import _ from 'lodash';

function ItemList() {
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);
 

  const [stationcode, setStationcode] = useState("");
  const [timer, setTimer] = useState(null);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(8);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [openpopupItem, setopenpopupItem] = useState(false);
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };

  const [color] = useState("#344767");

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

 
  async function fetchData(page, size, query) {
    setLoading(true);
    setData([]);
  
      await axios
        .get(
          `/itemmaster-service/api/itemMaster/v1/getV3?ispageable=true&page=${page}&size=${size}&st=${query}`
        )
       
      
        .then((response) => {
        
          if(  response?.data?.data?.dtoList.length===0){
            setCurrentPage(0)
          }   
        
          setTotalPages(response?.data?.data?.page?.totalPages);
          setData(response?.data?.data?.dtoList);
          setLoading(false);
        })
        .catch((e) => {
          console.log("error", e);
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          setData([]);
          setLoading(false);
        });
    
  }
  const fetchDataWithDelay = (currentPage, pageSize, query) => {
    setCurrentPage(0)
    // Clear the previous timer to restart the countdown
    clearTimeout(timer);

    // Set a new timer to fetch data after one second
    const newTimer = setTimeout(() => {
      fetchData(currentPage, pageSize, query);
    }, 1000);

    // Save the timer for potential clearing in the future
    setTimer(newTimer);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    fetchDataWithDelay(currentPage, pageSize, value);
  };

  useEffect(() => {
    // if(searchQuery){
    //   fetchData(page, size, query)
    // }
      getData(currentPage, pageSize, searchQuery);
    
  }, [currentPage, pageSize]);

  

  async function getData(pageSize, size, query) {
    setLoading(true);
    setData([]);
    await axios
      .get(
        `/itemmaster-service/api/itemMaster/v1/getV3?ispageable=true&page=${pageSize}&size=${size}&st=${query}`
     
      )
      .then((response) => {
        setData(response?.data?.data?.dtoList);
        setTotalPages(response?.data?.data?.page?.totalPages);
        setLoading(false);
      })
      .catch((e) => {
        swal({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        setData([]);
        setLoading(false);
      });
  }

  useEffect(() => {

    getData(0, pageSize, searchQuery);
  }, []);


  // Handle the next page button click
  function handleNextPage() {
    setCurrentPage((currentPage) => currentPage + 1);
  }

  // Handle the previous page button click
  function handlePreviousPage() {
    setCurrentPage((currentPage) => currentPage - 1);
  }
  function handlePreviousPage() {
    setCurrentPage((currentPage) => currentPage - 1);
  }

  // Render the table rows with add button
  function renderRows() {
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
          
            <td class="MuiBox-root css-1nos2st" style={{color:"rgb(123, 128, 154", fontSize: "0.875rem"}}>
              {item.type}
            </td>
            <td class="MuiBox-root css-1nos2st" style={{color:"rgb(123, 128, 154", fontSize: "0.875rem"}}>
              {item.name}
            </td>
            <td class="MuiBox-root css-1nos2st" style={{color:"rgb(123, 128, 154", fontSize: "0.875rem"}}>{item.description}</td>
            <td class="MuiBox-root css-1nos2st" style={{color:"rgb(123, 128, 154", fontSize: "0.875rem"}}>{item.code}</td>

            <td class="MuiBox-root css-1nos2st" style={{color:"rgb(123, 128, 154", fontSize: "0.875rem"}}>
            
             
               <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(row.original);
                                }}
                              />
                            </Link>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card style={{overflowX:"scroll"}}>
      <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Item Master
                      </MDTypography>
                    </span>

                    <span
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                    >
                      <MDButton
                        color="success"
                        onClick={() => setopenpopupItem(true)}
                      >
                        ADD
                      </MDButton>
                    </span>
                  </div>
                </MDBox>

        <MDBox pt={3} mb={3}>
        
          <MDBox>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={10}>
                  <></>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <MDInput
                      type="text"
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleChange}
                      label="Serach"
                    />
                  </Grid>
                </Grid>
              </div>
            </MDBox>
          </MDBox>
        </MDBox>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        <div>
          <table className="data-table2">
            <thead>
              <tr>
                <th>TYPE</th>
                <th>NAME</th>
                <th>DESCRIPTION</th>
                <th>CODE</th>
               

                <th>ACTION</th>
              </tr>
            </thead>
            {renderRows()}
          </table>
         
          <div className="pagination-buttons2">
            <MDButton
            variant="contained"
              size="small"
              mr={20}
              color="info"
              onClick={handlePreviousPage}
              disabled={currentPage === 0||data.length===0}
            >
              Previous
            </MDButton>
            <p className="total-pages2">
              Page {currentPage + 1} of {totalPages}
            </p>
      
      <MDButton
            variant="contained"
              size="small"
              color="success"
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1||data.length===0}
            >
              Next
            </MDButton>  
          </div>
        </div>
      </Card>
      <Footer />
      {openpopupItem && ( <CreateItemPopup
        openpopupItem={openpopupItem}
        setopenpopupItem={setopenpopupItem}
      />
       )}
    </DashboardLayout>
  );
}



export default ItemList;
