import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { TextField, CircularProgress, Select } from "@mui/material";
import MDInput from "components/MDInput";

import swal from "sweetalert";
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getNoOfParts } from "./service/Noofparts";
import { getFinalStation } from "./service/FinalStation";
import { updateNoOfPartData } from "./reducer/numberofpartDetailSlice";

function DaysAnalytics({
  color,
  title,
  description,

  chart,
  datemargin,
}) {
  const dispatch = useDispatch();
  const { noofpart, loading, error } = useSelector((state) => state.noofpart);
  const { finalstation, loading2, error2 } = useSelector(
    (state) => state.finalstation
  );
  const { graph1Messages, loading1, error1 } = useSelector(
    (state) => state.graph1Messages
  );
  const [analytic1message, setAnalytic1Message] = useState([]);
  const [datanew, setDataNew] = useState([]);
  const [labelsnew, setLabelsNew] = useState([]);

  const daylocal = localStorage.getItem("part1-Day")?.replace(/"/g, "");
  const [day, setDay] = useState(daylocal ? daylocal : "5");
  const date = localStorage.getItem("part-processed")?.replace(/"/g, "");
  //   const [selectedDate, setSelectedDate] = useState(date?date:new Date());
  //   console.log("seleheheh",selectedDate)
  const [selectedDate, setSelectedDate] = useState(
    date instanceof Date ? date : new Date()
  );

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const { data, options } = configs(
    labelsnew.length > 0 ? labelsnew : noofpart?.labels || [],
    datanew.length > 0
      ? { ...noofpart?.datasets, data: datanew }
      : noofpart.datasets || {}
  );

  useEffect(() => {
    if (datanew.length > 0) {
      dispatch(getNoOfParts(day));
    }
  }, [datanew]);

  const handleDayChange = async (event) => {
    const days = event.target.value;

    localStorage.setItem("part1-Day", JSON.stringify(days));
    setDay(days);

    const response = await dispatch(getNoOfParts(days));

    if (response.error && !loading) {
      swal({
        icon: "error",
        title: "Error",
        text: response?.payload?.message
          ? response?.payload?.message
          : error?.message,
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getNoOfParts(day));

      if (response.error && !loading) {
        swal({
          icon: "error",
          title: "Error",
          text: response?.payload?.message
            ? response?.payload?.message
            : error?.message,
        });
      }
    };

    fetchData();
    dispatch(getFinalStation());
  }, []);

  useEffect(() => {
    if (Object.keys(analytic1message)?.length > 0) {
      const dateFromGraph = new Date(analytic1message?.createddate)
        .toISOString()
        .split("T")[0];

      if (noofpart.labels && Array.isArray(noofpart.labels)) {
        const index =
          labelsnew?.length > 0
            ? labelsnew.indexOf(dateFromGraph)
            : noofpart.labels.indexOf(dateFromGraph);

        if (index !== -1) {
          const newData =
            datanew?.length > 0 ? [...datanew] : [...noofpart.datasets.data];
          newData[index] = (parseInt(newData[index]) + 1).toString();
          dispatch(updateNoOfPartData({ data: newData }));
          setDataNew(newData);
        } else {
          const newLabels =
            labelsnew?.length > 0
              ? [...labelsnew, dateFromGraph]
              : [...noofpart.labels, dateFromGraph];
          const newData =
            datanew?.length > 0
              ? [...datanew, "1"]
              : [...noofpart?.datasets?.data, "1"];
          newLabels.shift();
          newData.shift();
          dispatch(updateNoOfPartData({ labels: newLabels, data: newData }));
          setDataNew(newData);
          setLabelsNew(newLabels);
        }
      }
    }
  }, [analytic1message]);

  useEffect(() => {
    if (
      graph1Messages?.stationcode == finalstation &&
      graph1Messages?.recstatus == "OK"
    ) {
      setAnalytic1Message(graph1Messages);
    }
  }, [graph1Messages]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              {loading ? (
                <CircularProgress color="inherit" size={40} />
              ) : (
                <Line data={data} options={options} />
              )}
            </MDBox>
          ),
          [chart, color, loading]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <Select
              native
              variant="outlined"
              style={{ width: "20%", marginRight: "3px", marginLeft: "auto" }}
              name="days"
              value={day}
              id="days"
              onChange={(e) => {
                handleDayChange(e);

                // const selectedDepartmentObj =
                //   station.find((obj) => obj.code === stationvalue) || {};
              }}
            >
              <option value="" disabled selected>
                Select Days
              </option>
              <option value=""></option>
              <option key="1" value="1">
                Last 1 Day
              </option>
              <option key="2" value="2">
                Last 2 Day
              </option>
              <option key="3" value="3">
                Last 3 Day
              </option>
              <option key="4" value="4">
                Last 4 Day
              </option>
              <option key="5" value="5">
                Last 5 Day
              </option>
              <option key="6" value="6">
                Last 6 Day
              </option>
              <option key="7" value="7">
                Last 7 Day
              </option>
              <option key="8" value="8">
                Last 8 Day
              </option>
              <option key="9" value="9">
                Last 9 Day
              </option>
            </Select>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

DaysAnalytics.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  chart: PropTypes.string,
  datemargin: PropTypes.bool,
};

export default DaysAnalytics;
