import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Autocomplete } from "@mui/material";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateDepartment } from "../service/Department";
import { MoonLoader } from "react-spinners";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";

const Departmentedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedDepartmnet, openpopup, setopenpopup } = props;

  const { department, loading,error } = useSelector((state) => state.department);
 

  const defaultProps = {
    options: !department ? [{ name: "Loading...", id: 0 }] : department,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const [selectDep, setSelectDep] = useState("");

  console.log("select", selectedDepartmnet);

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedDepartmnet?.name || "",
      description: selectedDepartmnet?.description || "",
    },
    validationSchema,
    onSubmit: async (values) => {
     
      console.log(values);
      // Create a copy of selectedDepartmnet with updated values
      const updatedDepartmnet = {
        ...selectedDepartmnet,
        name: values.name,
        description: values.description,
        parentdepcode: values.parentdepcode
          ? values?.parentdepcode
          : selectedDepartmnet?.parentdepcode,
        parenthierarchicalcode: values?.parenthierarchicalcode
          ? values?.parenthierarchicalcode
          : selectedDepartmnet?.parenthierarchicalcode,
        hierarchicalcode: values.hierarchicalcode
          ? values.hierarchicalcode
          : selectedDepartmnet?.hierarchicalcode,
      };
     
     
      const response = await dispatch(updateDepartment(updatedDepartmnet));

      if (!response.error && !loading) {
        handleClose();

        swal("Good job!", "Role has been successfully updated.", "success");
      } else {
        handleClose();

        swal({
          icon: "error",
          title: "Error",
          text: error?.message
            ? error?.message
            : "An error occurred while updating the item",
        });
      }
    },
  });

  useEffect(() => {
    setSelectDep({ name: "Loading...", id: 0 });

    if (selectedDepartmnet) {
      for (let i = 0; i < department.length; i++) {
        if (department[i].code === selectedDepartmnet.parentdepcode) {
          setSelectDep(department[i]);
        }
      }
    }
    // Update the form values when selectedDepartmnet changes
    formik.setValues({
      name: selectedDepartmnet?.name || "",
      description: selectedDepartmnet?.description || "",
    });
  }, [selectedDepartmnet]);

  const handleClose = () => {
    setopenpopup(false);
    setSelectDep({ name: "Loading...", id: 0 });

    if (selectedDepartmnet) {
      for (let i = 0; i < department.length; i++) {
        if (department[i].code === selectedDepartmnet.parentdepcode) {
          setSelectDep(department[i]);
        }
      }
    }
  };

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ width: 550, height: 200 }}>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>
                Department Name
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label=<span>
                Department Description
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultProps}
              value={selectDep}
              onChange={(event, newValue) => {
                console.log(newValue);
                // handleChange(event);
                if (newValue === null) {
                  formik.values.parentdepcode = null;
                  formik.values.parenthierarchicalcode = null;
                  formik.values.hierarchicalcode = selectedDepartmnet.code;

                  setSelectDep(newValue);
                } else {
                  formik.values.parentdepcode = newValue?.code;
                  formik.values.parenthierarchicalcode =
                    newValue?.hierarchicalcode;
                  formik.values.hierarchicalcode =
                    newValue?.hierarchicalcode + "-" + selectedDepartmnet.code;
                  setSelectDep(newValue);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="Parent Department"
                />
              )}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="success" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="info" type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Departmentedit;
