import { createSlice } from "@reduxjs/toolkit";
import { getPartName } from "../service/PartName";

const initialState = {
  partname: "",
  loading: false,
  error: null,
};

export const partnameDetail = createSlice({
  name: "partname",
  initialState,
  extraReducers: {
    [getPartName.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPartName.fulfilled]: (state, action) => {
      state.loading = false;
      state.partname = action.payload;
    },
    [getPartName.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default partnameDetail.reducer;
