// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import MDInput from "components/MDInput";
// import MDBox from "components/MDBox";
// import Grid from "@mui/material/Grid";
// import AddBoxIcon from "@mui/icons-material/AddBox";
// import { useDispatch } from "react-redux";
// import { ErrorMessage, useFormik } from "formik";
// import * as Yup from "yup";
// import {
//   FormControl,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   FormHelperText,
//   TextField,
//   Tooltip,
//   Divider,
// } from "@mui/material";
// import Select from "@mui/material/Select";
// import { useSelector } from "react-redux";
// import FormField from "./FormField";
// import CreateProcess from "./CreateProcess";
// import { getProcess } from "../service/ProcessMaster";
// import { getTypeMaster } from "../service/TypeMaster";
// import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
// import { FaRegTimesCircle } from "react-icons/fa";
// import { updateBOMCreation } from "../service/BOMCreationMaster";
// import { MoonLoader } from "react-spinners";

// const BOMEdit = (props) => {
//   const dispatch = useDispatch();
//   const [data, setData] = useState([]);
//   const { title, selectedBOM, openpopup, setopenpopup } = props;
//   const { processmaster, loading2 } = useSelector(
//     (state) => state.processmaster
//   );
//   const [idCounter, setIdCounter] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const { itemmaster, loading1 } = useSelector((state) => state.itemmaster);
//   const [value, setValue] = useState("");
//   const [processvalue, setProcessValue] = useState("");
//   const [openpopupProcess, setopenpopupProcess] = useState(false);

//   const validationSchema = Yup.object({
//     modelno: Yup.string().required("Model No. is required"),
//     bomname: Yup.string().required("Name is required"),
//     pattern: Yup.string().required("Pattern is required"),
//     processid: Yup.string().required("Process Id is required"),
//     itemname: Yup.string().required("Item Name is required"),
//     itemquantity: Yup.string().required("Item Quantity is required"),
//   });

//   const columns = [
//     { Header: "item name", accessor: "itemname" },
//     { Header: "item quantity", accessor: "itemquantity" },
//     {
//       Header: "Action",
//       accessor: "uid",
//       Cell: ({ row }) => (
//         <span>
//           <Link to={{}}>
//             <FaRegTimesCircle
//               style={{
//                 color: "#f44336",
//                 width: "25px",
//                 height: "25px",
//                 cursor: "pointer",
//               }}
//               onClick={() => removeData(row.values.uid)}
//             />
//           </Link>
//         </span>
//       ),
//       width: "12%",
//     },
//   ];

//   const formik = useFormik({
//     initialValues: {
//       modelno: selectedBOM?.modelno || "",
//       bomname: selectedBOM?.name || "",
//       bomdescription: selectedBOM?.description || "",
//       pattern: selectedBOM?.pattern || "",
//       processid: selectedBOM?.processid || "",
//       itemname: selectedBOM?.items[0].itemid || "",
//       itemquantity: selectedBOM?.items[0].quantity || "",
//       itemid: "",
//     },
//     validationSchema,
//     onSubmit: (values, { resetForm }) => {
//       const newData = {
//         uid: idCounter,
//         modelno: values.modelno,
//         name: values.bomname,
//         description: values.bomdescription,
//         pattern: values.pattern,
//         itemname:
//           values.itemname ===
//           itemmaster.find((option) => option.sapcode === values.itemname)
//             ?.sapcode
//             ? itemmaster.find((option) => option.sapcode === values.itemname)
//                 ?.name
//             : values.itemname,
//         itemquantity: values.itemquantity,
//         itemid: values.itemid,
//         processid: values.processid,
//       };
//       const isItemNameAlreadyExists = data.some(
//         (item) => item.itemname === newData.itemname
//       );

//       if (isItemNameAlreadyExists) {
//         swal("", "This Item is already present in the table.", "warning");
//       } else {
//         setData([...data, newData]);
//         setIdCounter(idCounter + 1);
//       }
//       setValue("");
//       // setProcessValue("");
//       resetForm({ values: { ...values, itemquantity: "", itemname: "" } });
//     },
//   });

//   useEffect(() => {
//     formik.setValues({
//       modelno: selectedBOM?.modelno || "",
//       bomname: selectedBOM?.name || "",
//       bomdescription: selectedBOM?.description || "",
//       pattern: selectedBOM?.pattern || "",
//       processid: selectedBOM?.processid || "",
//       itemname: selectedBOM?.items[0].itemid || "",
//       itemquantity: selectedBOM?.items[0].quantity || "",
//     });
//     const updatedData = selectedBOM?.items.map((item, index) => ({
//       modelno: selectedBOM?.modelno,
//       bomname: selectedBOM?.name,
//       bomdescription: selectedBOM?.description,
//       pattern: selectedBOM?.pattern,
//       processid: selectedBOM?.processid,
//       itemname: item.itemname,
//       itemquantity: item.quantity,
//       itemid: item.itemid,
//       uid: idCounter + index,
//     }));

//     setData(updatedData);
//   }, [selectedBOM]);

//   const handleClose = () => {
//     setopenpopup(false);
//     formik.setValues({
//       modelno: selectedBOM?.modelno || "",
//       bomname: selectedBOM?.name || "",
//       bomdescription: selectedBOM?.description || "",
//       pattern: selectedBOM?.pattern || "",
//       processid: selectedBOM?.processid || "",
//       itemname: selectedBOM?.items[0].itemid || "",
//       itemquantity: selectedBOM?.items[0].quantity || "",
//     });
//     const updatedData = selectedBOM?.items.map((item, index) => ({
//       modelno: selectedBOM?.modelno,
//       bomname: selectedBOM?.name,
//       bomdescription: selectedBOM?.description,
//       pattern: selectedBOM?.pattern,
//       processid: selectedBOM?.processid,
//       itemname: item.itemname,
//       itemquantity: item.quantity,
//       itemid: item.itemid,
//       uid: idCounter + index,
//     }));

//     setData(updatedData);
//     setValue(null);
//     setProcessValue(null);
//   };

//   const removeData = (id) => {
//     const newData = data.filter((item) => item.uid !== id);
//     setData(newData);
//   };

//   async function finasubmit() {
//     formik.setTouched({
//       modelno: true,
//       bomname: true,
//       pattern: true,
//       processid: true,
//     });

//     await formik.validateForm();

//     const requiredFields = ["modelno", "bomname", "pattern", "processid"];
//     const hasEmptyFields = requiredFields.some(
//       (field) => !formik.values[field]
//     );

//     if (hasEmptyFields) {
//       swal("Validation Error", "Please fill in all required fields.", "error");
//       return;
//     }

//     setLoading(true);
//     if (data.length === 0) {
//       swal("Oops", "Please add data in table first!", "error");
//     } else {
//       var finalObjectlist = [];
//       for (let i = 0; i < data.length; i++) {
//         finalObjectlist.push({
//           itemname: data[i].itemname,
//           itemid: data[i].itemid,
//           quantity: data[i].itemquantity,
//         });
//       }
//       const requestData = {
//         ...selectedBOM,
//         modelno: formik.values.modelno?.trim(),
//         name: formik.values.bomname?.trim(),
//         description: formik.values.bomdescription?.trim(),
//         pattern: formik.values.pattern?.trim(),
//         items: finalObjectlist,
//         processid: formik.values.processid?.trim(),
//       };
//       try {
//         const response = await dispatch(updateBOMCreation(requestData));
//         handleClose();
//         setLoading(false);
//         swal("Good job!", "BOM has been successfully updated.", "success");
//       } catch (error) {
//         console.error("Error submitting form:", error);
//         setLoading(false);
//         handleClose();
//         swal("", "Something went wrong!", "error");
//       }
//     }
//   }

//   useEffect(() => {
//     dispatch(getProcess());
//     dispatch(getTypeMaster("ITEM"));
//   }, [dispatch]);

//   return (
//     <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
//       <DialogTitle
//         style={{
//           backgroundColor: "blue",
//           color: "#fff",
//           textAlign: "center",
//         }}
//       >
//         {title}
//       </DialogTitle>
//       <form onSubmit={formik.handleSubmit}>
//         <DialogContent>
//           <Grid container spacing={3} mt={0}>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 fullWidth
//                 variant="standard"
//                 type="modelno"
//                 label=<span>
//                   Model No.
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       top: "2px",
//                     }}
//                   >
//                     {" "}
//                     *
//                   </sup>
//                 </span>
//                 id="modelno"
//                 name="modelno"
//                 value={formik.values.modelno}
//                 onChange={formik.handleChange}
//                 error={formik.touched.modelno && Boolean(formik.errors.modelno)}
//                 helperText={
//                   formik.touched.modelno && formik.errors.modelno ? (
//                     <p
//                       style={{
//                         fontWeight: "400",
//                         color: "red",
//                         fontSize: "12px",
//                       }}
//                     >
//                       {formik.errors.modelno}
//                     </p>
//                   ) : null
//                 }
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 fullWidth
//                 variant="standard"
//                 type="bomname"
//                 label=<span>
//                   Name
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       top: "2px",
//                     }}
//                   >
//                     {" "}
//                     *
//                   </sup>
//                 </span>
//                 name="bomname"
//                 value={formik.values.bomname}
//                 onChange={formik.handleChange}
//                 error={formik.touched.bomname && Boolean(formik.errors.bomname)}
//                 helperText={
//                   formik.touched.bomname && formik.errors.bomname ? (
//                     <p
//                       style={{
//                         fontWeight: "400",
//                         color: "red",
//                         fontSize: "12px",
//                       }}
//                     >
//                       {formik.errors.bomname}
//                     </p>
//                   ) : null
//                 }
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 fullWidth
//                 variant="standard"
//                 type="bomdescription"
//                 label=<span>
//                   Description
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       top: "2px",
//                     }}
//                   >
//                     {" "}
//                   </sup>
//                 </span>
//                 name="bomdescription"
//                 value={formik.values.bomdescription}
//                 onChange={formik.handleChange}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <MDInput
//                 fullWidth
//                 variant="standard"
//                 type="pattern"
//                 label=<span>
//                   Pattern
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       top: "2px",
//                     }}
//                   >
//                     {" "}
//                     *
//                   </sup>
//                 </span>
//                 name="pattern"
//                 value={formik.values.pattern}
//                 onChange={formik.handleChange}
//                 error={formik.touched.pattern && Boolean(formik.errors.pattern)}
//                 helperText={
//                   formik.touched.pattern && formik.errors.pattern ? (
//                     <p
//                       style={{
//                         fontWeight: "400",
//                         color: "red",
//                         fontSize: "12px",
//                       }}
//                     >
//                       {formik.errors.pattern}
//                     </p>
//                   ) : null
//                 }
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <span style={{ display: "flex", alignItems: "end" }}>
//                   <InputLabel
//                     htmlFor="processid"
//                     style={{
//                       marginLeft: "-14px",
//                       paddingBottom: "2px",
//                       marginTop: "2px",
//                     }}
//                   >
//                     Process
//                     <sup
//                       style={{
//                         color: "red",
//                         fontSize: "small",
//                         fontWeight: "bolder",
//                         position: "relative",
//                         top: "2px",
//                       }}
//                     >
//                       {" "}
//                       *
//                     </sup>
//                   </InputLabel>
//                   <Select
//                     native
//                     variant="standard"
//                     style={{ width: "100%" }}
//                     name="processid"
//                     id="processid"
//                     value={processvalue || formik?.values?.processid}
//                     onChange={(event) => {
//                       const selectedprocess = event.target.value;
//                       setProcessValue(selectedprocess);
//                       console.log("processid", selectedprocess);
//                       formik.values.processid = selectedprocess;
//                     }}
//                   >
//                     <option value=""></option>
//                     {/* {processmaster.map((obj) => (
//                       <option key={obj.id} value={obj.code}>
//                         {obj.name}&nbsp;({obj.stationsequence})
//                       </option>
//                     ))} */}
//                     {processmaster.map((obj) => {
//                       const combinedText = `${obj.name} (${obj.stationsequence})`;
//                       let displayedText =
//                         combinedText.length > 40
//                           ? `${combinedText.slice(0, 37)}...`
//                           : combinedText;

//                       if (displayedText.endsWith("...")) {
//                         displayedText =
//                           combinedText.length > 43
//                             ? `${combinedText.slice(0, 38)}...`
//                             : combinedText;
//                       }

//                       return (
//                         <option
//                           key={obj.id}
//                           value={obj.code}
//                           title={combinedText}
//                         >
//                           {displayedText}
//                         </option>
//                       );
//                     })}
//                   </Select>
//                   <Tooltip title="Add Process" arrow>
//                     <AddBoxIcon
//                       fontSize="large"
//                       sx={{ cursor: "pointer" }}
//                       onClick={() => setopenpopupProcess(true)}
//                     />
//                   </Tooltip>
//                 </span>
//                 {formik.errors.processid && formik.touched.processid && (
//                   <FormHelperText
//                     error
//                     style={{ marginLeft: "0px", fontWeight: 400 }}
//                   >
//                     {formik.errors.processid}
//                   </FormHelperText>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={12} mt={0} mb={-3}>
//               <Divider fullWidth />
//             </Grid>
//             <Grid container spacing={3} ml={0} mt={0}>
//               <Grid item xs={12} sm={5.3}>
//                 <FormControl fullWidth>
//                   <InputLabel
//                     htmlFor="itemname"
//                     style={{
//                       marginLeft: "-14px",
//                       paddingBottom: "2px",
//                       marginTop: "2px",
//                     }}
//                   >
//                     Select Item
//                     <sup
//                       style={{
//                         color: "red",
//                         fontSize: "small",
//                         fontWeight: "bolder",
//                         position: "relative",
//                         top: "2px",
//                       }}
//                     >
//                       {" "}
//                       *
//                     </sup>
//                   </InputLabel>
//                   <Select
//                     native
//                     variant="standard"
//                     style={{ width: "100%" }}
//                     name="itemname"
//                     id="itemname"
//                     value={value || formik.values.itemname}
//                     onChange={(event) => {
//                       const selectedValue = event.target.value;
//                       setValue(selectedValue);
//                       const selectedOption = itemmaster.find(
//                         (option) => option.sapcode === selectedValue
//                       );
//                       formik.values.itemname = selectedOption?.name;
//                       formik.values.itemid = selectedOption?.sapcode;
//                     }}
//                   >
//                     <option value=""></option>
//                     {itemmaster
//                       .filter((item) => item.type === "PART")
//                       .map((obj) => (
//                         <option key={obj.id} value={obj.sapcode}>
//                           {obj.name}
//                         </option>
//                       ))}
//                   </Select>
//                   {formik.errors.itemname && formik.touched.itemname && (
//                     <FormHelperText
//                       error
//                       style={{ marginLeft: "0px", fontWeight: 400 }}
//                     >
//                       {formik.errors.itemname}
//                     </FormHelperText>
//                   )}
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={5.3}>
//                 <MDInput
//                   fullWidth
//                   variant="standard"
//                   type="number"
//                   label=<span>
//                     Item quantity
//                     <sup
//                       style={{
//                         color: "red",
//                         fontSize: "small",
//                         fontWeight: "bolder",
//                         position: "relative",
//                         top: "2px",
//                       }}
//                     >
//                       {" "}
//                       *
//                     </sup>
//                   </span>
//                   name="itemquantity"
//                   value={formik.values.itemquantity}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.itemquantity &&
//                     Boolean(formik.errors.itemquantity)
//                   }
//                   helperText={
//                     formik.touched.itemquantity &&
//                     formik.errors.itemquantity ? (
//                       <p
//                         style={{
//                           fontWeight: "400",
//                           color: "red",
//                           fontSize: "12px",
//                         }}
//                       >
//                         {formik.errors.itemquantity}
//                       </p>
//                     ) : null
//                   }
//                 />
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 sm={1}
//                 ml={-2}
//                 sx={{
//                   display: "flex",
//                   justifyContent: "start",
//                   alignItems: "center",
//                 }}
//               >
//                 <MDButton
//                   variant="gradient"
//                   color="info"
//                   size="small"
//                   type="submit"
//                 >
//                   Add
//                 </MDButton>
//               </Grid>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={12}
//               style={{
//                 border: "1px solid #727375",
//                 borderRadius: "5px",
//               }}
//               mr={2}
//               mb={0}
//               mt={4}
//               ml={3}
//             >
//               <DataTable
//                 showTotalEntries={false}
//                 entriesPerPage={false}
//                 table={{ columns, rows: data }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button type="submit" disabled={loading} onClick={() => finasubmit()}>
//             {loading ? <MoonLoader color="black" size={16} /> : "Update"}
//           </Button>
//         </DialogActions>
//       </form>
//       <CreateProcess
//         openpopupProcess={openpopupProcess}
//         setopenpopupProcess={setopenpopupProcess}
//       />
//     </Dialog>
//   );
// };

// export default BOMEdit;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDispatch } from "react-redux";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

import CreateProcess from "./CreateProcess";
import { getProcess } from "../service/ProcessMaster";

import { updateBOMCreation } from "../service/BOMCreationMaster";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";

const BOMEdit = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { title, selectedBOM, openpopup, setopenpopup } = props;
  const { processmaster, loading2 } = useSelector(
    (state) => state.processmaster
  );
  const [idCounter, setIdCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const { itemmaster, loading1 } = useSelector((state) => state.itemmaster);
  const [value, setValue] = useState("");
  const [processvalue, setProcessValue] = useState("");
  const [openpopupProcess, setopenpopupProcess] = useState(false);

  const validationSchema = Yup.object({
    modelno: Yup.string().required("Model No. is required"),
    bomname: Yup.string().required("Name is required"),
    pattern: Yup.string().required("Pattern is required"),
    processid: Yup.string().required("Process Id is required"),
  });

  const formik = useFormik({
    initialValues: {
      modelno: selectedBOM?.modelno || "",
      bomname: selectedBOM?.name || "",
      bomdescription: selectedBOM?.description || "",
      pattern: selectedBOM?.pattern || "",
      processid: selectedBOM?.processid || "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const requestData = {
        ...selectedBOM,
        modelno: values.modelno?.trim(),
        name: values.bomname?.trim(),
        description: values.bomdescription?.trim(),
        pattern: values.pattern?.trim(),

        processid: values.processid?.trim(),
      };

      try {
        const response = await dispatch(updateBOMCreation(requestData));
        handleClose();
        setLoading(false);
        swal("Good job!", "BOM has been successfully updated.", "success");
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);
        handleClose();
        swal("", "Something went wrong!", "error");
      }

      resetForm({ values: { ...values } });
    },
  });

  useEffect(() => {
    formik.setValues({
      modelno: selectedBOM?.modelno || "",
      bomname: selectedBOM?.name || "",
      bomdescription: selectedBOM?.description || "",
      pattern: selectedBOM?.pattern || "",
      processid: selectedBOM?.processid || "",
    });
    // const updatedData = selectedBOM?.items.map((item, index) => ({
    //   modelno: selectedBOM?.modelno,
    //   bomname: selectedBOM?.name,
    //   bomdescription: selectedBOM?.description,
    //   pattern: selectedBOM?.pattern,
    //   processid: selectedBOM?.processid,
    //   itemname: item.itemname,

    // }));

    // setData(updatedData);
  }, [selectedBOM]);

  const handleClose = () => {
    setopenpopup(false);
    formik.setValues({
      modelno: selectedBOM?.modelno || "",
      bomname: selectedBOM?.name || "",
      bomdescription: selectedBOM?.description || "",
      pattern: selectedBOM?.pattern || "",
      processid: selectedBOM?.processid || "",
    });
  };

  useEffect(() => {
    dispatch(getProcess());
  }, [dispatch]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={3} mt={0}>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                variant="standard"
                type="modelno"
                label=<span>
                  Model No.
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                id="modelno"
                name="modelno"
                value={formik.values.modelno}
                onChange={formik.handleChange}
                error={formik.touched.modelno && Boolean(formik.errors.modelno)}
                helperText={
                  formik.touched.modelno && formik.errors.modelno ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.modelno}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                variant="standard"
                type="bomname"
                label=<span>
                  Name
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name="bomname"
                value={formik.values.bomname}
                onChange={formik.handleChange}
                error={formik.touched.bomname && Boolean(formik.errors.bomname)}
                helperText={
                  formik.touched.bomname && formik.errors.bomname ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.bomname}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                variant="standard"
                type="bomdescription"
                label=<span>
                  Description
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                  </sup>
                </span>
                name="bomdescription"
                value={formik.values.bomdescription}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                variant="standard"
                type="pattern"
                label=<span>
                  Pattern
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name="pattern"
                value={formik.values.pattern}
                onChange={formik.handleChange}
                error={formik.touched.pattern && Boolean(formik.errors.pattern)}
                helperText={
                  formik.touched.pattern && formik.errors.pattern ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.pattern}
                    </p>
                  ) : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                variant="standard"
                type="text"
                label=<span>
                  Process Id
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name="processid"
                value={formik.values.processid}
                placeholder="XX||XX"
                onChange={formik.handleChange}
                error={
                  formik.touched.processid && Boolean(formik.errors.processid)
                }
                helperText={
                  formik.touched.processid && formik.errors.processid ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.processid}
                    </p>
                  ) : null
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="info" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton type="submit" size="small" color="info" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
      {openpopupProcess && (
        <CreateProcess
          openpopupProcess={openpopupProcess}
          setopenpopupProcess={setopenpopupProcess}
        />
      )}
    </Dialog>
  );
};

export default BOMEdit;
