import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSelector, useDispatch } from "react-redux";
import { deleteMachine, getMachine } from "../../MachineMaster/service/Machine";
import { deleteCategory, getCategory } from "../service/Category";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import { Grid, Input, InputLabel, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateChecklist from "./CreateChecklist";
import CreateMachine from "./CreateMachine";
import Dialog from "@mui/material/Dialog";
import { createStation } from "../service/Station";
import Tooltip from "@mui/material/Tooltip";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { fileUpload } from "../service/FileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { upload } from "@testing-library/user-event/dist/upload";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  deleteMachinechecklist,
  getMachinechecklist,
} from "../service/MachineChecklist";
import CreatePokayoke from "./CreatePokayoke";
import { deletePokayoke, getPokayoke } from "../service/Pokayoke";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const validationSchema = Yup.object({
  stationcode: Yup.string().required("Station Code is required"),
  stationname: Yup.string().required("Station Name is required"),
  // stationdescription: Yup.string().required("Station Descrption is required"),
  // tags: Yup.string().required("Tags is required"),
});

export default function CreateStationPopup(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      stationcode: "",
      stationname: "",
      stationdescription: "",
      bypass: "",
      rework: "",
      setuppart: "",
      totalreworkcount: "",
      tags: [],
      upload: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      console.log("Form Values:", values);
      const requestData = {
        code: values.stationcode?.trim(),
        name: values.stationname?.trim(),
        description: values.stationdescription?.trim(),
        totalreworkcount: values.totalreworkcount.toString(),
        bypass: values.bypass ? values.bypass.toString() : "false",
        rework: values.rework ? values.rework.toString() : "false",
        setuppart: values.setuppart ? values.setuppart.toString() : "false",
        tags: values.tags,
        recstatus: "OPEN",
        resourcecode: "DAYCO",
        status: "Active",
        syncstatus: "SYNCED",
        type: "CHECKLIST",
      };
      console.warn("requestData", requestData);
       const response = await dispatch(createStation(requestData));

      if (!response.error) {
        const code = response?.payload?.data?.dto?.code;
        UploadFile(selectedFile, code);
        setLoading(false);
        swal(
          "Good job!",
          "Station has been successfully registered!",
          "success"
        );
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
        setLoading(false);
      }
      resetForm();
      handleClose();
    },
  });

  const UploadFile = (document, station_code) => {
    const formData = new FormData();
    const body = { code: station_code, resourcecode: "DAYCO" };
    formData.append("file", document);
    formData.append("body", JSON.stringify(body));
    dispatch(fileUpload(formData));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // console.log("upload file", selectedFile);
  const handleChooseFileClick = () => {
    // Trigger the file input's click event
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    dispatch(getMachine());
    dispatch(getPokayoke());
    dispatch(getMachinechecklist());
  }, [dispatch]);

  const handleClose = () => {
    setopenpopupStation(false);
  };

  const { openpopupStation, setopenpopupStation } = props;

  // Render the component
  return (
    <Dialog
      open={openpopupStation}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <form onSubmit={formik.handleSubmit}>
            <Card>
              <MDBox mx={2} mt={-3}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={-2}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="small"
                    color="white"
                    mt={1}
                  >
                    Station Master
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox p={3}>
                <Grid container spacing={3} mt={0.5}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="stationcode"
                        name="stationcode"
                        label=<span>
                          Station Code
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        value={formik.values.stationcode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.stationcode &&
                          Boolean(formik.errors.stationcode)
                        }
                        helperText={
                          formik.touched.stationcode &&
                          formik.errors.stationcode
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="staionname"
                        name="stationname"
                        label=<span>
                          Station Name
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        value={formik.values.stationname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.stationname &&
                          Boolean(formik.errors.stationname)
                        }
                        helperText={
                          formik.touched.stationname &&
                          formik.errors.stationname
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="staiondescription"
                        name="stationdescription"
                        label="Station Description"
                        variant="standard"
                        value={formik.values.stationdescription}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.stationdescription &&
                          Boolean(formik.errors.stationdescription)
                        }
                        helperText={
                          formik.touched.stationdescription &&
                          formik.errors.stationdescription
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="tags"
                        name="tags"
                        label="Tags"
                        variant="standard"
                        value={formik.values.tags}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          formik.setFieldValue(
                            "tags",
                            newValue ? [newValue] : []
                          );
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.tags && Boolean(formik.errors.tags)
                        }
                        helperText={formik.touched.tags && formik.errors.tags}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="totalreworkcount"
                        type="number"
                        name="totalreworkcount"
                        label="Total Rework Count"
                        variant="standard"
                        value={formik.values.totalreworkcount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalreworkcount &&
                          Boolean(formik.errors.totalreworkcount)
                        }
                        helperText={
                          formik.touched.totalreworkcount &&
                          formik.errors.totalreworkcount
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.rework}
                            onClick={(e) =>
                              formik.setFieldValue("rework", e.target.checked)
                            }
                            name="rework"
                          />
                        }
                        label="Rework"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.bypass}
                            onClick={(e) =>
                              formik.setFieldValue("bypass", e.target.checked)
                            }
                            name="bypass"
                          />
                        }
                        label="Bypass"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.setuppart}
                            onClick={(e) =>
                              formik.setFieldValue(
                                "setuppart",
                                e.target.checked
                              )
                            }
                            name="setuppart"
                          />
                        }
                        label="Setup Part"
                      />
                    </FormGroup>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={4.85}
                    sx={{ display: "grid", placeItems: "end" }}
                  >
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Upload Document"
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: Boolean(selectedFile?.name),
                      }}
                      value={selectedFile?.name}
                      sx={{ borderBottom: "0px solid #dce0e2" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    mr={3.1}
                    ml={-2}
                    sx={{ display: "grid", placeItems: "end" }}
                  >
                    <input
                      type="file"
                      name="document"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <Tooltip title="Upload Document" arrow>
                      <MDButton
                        variant="outlined"
                        color="info"
                        onClick={handleChooseFileClick}
                      >
                        <CloudUploadIcon />
                      </MDButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <MDBox
                  mt={5}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton type="submit" variant="gradient" color="dark">
                    {loading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
}














































// import React, { useEffect, useState, useRef } from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import Checkbox from "@mui/material/Checkbox";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import { useSelector, useDispatch } from "react-redux";
// import { deleteMachine, getMachine } from "../../MachineMaster/service/Machine";
// import { deleteCategory, getCategory } from "../service/Category";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import { MoonLoader } from "react-spinners";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import FormControl from "@mui/material/FormControl";
// import { Grid, Input, InputLabel, Typography } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import CreateChecklist from "./CreateChecklist";
// import CreateMachine from "./CreateMachine";
// import Dialog from "@mui/material/Dialog";
// import { createStation } from "../service/Station";
// import Tooltip from "@mui/material/Tooltip";
// import AddBoxIcon from "@mui/icons-material/AddBox";
// import { fileUpload } from "../service/FileUpload";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { upload } from "@testing-library/user-event/dist/upload";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
// import {
//   deleteMachinechecklist,
//   getMachinechecklist,
// } from "../service/MachineChecklist";
// import CreatePokayoke from "./CreatePokayoke";
// import { deletePokayoke, getPokayoke } from "../service/Pokayoke";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const validationSchema = Yup.object({
//   checklist: Yup.array()
//     .min(1, "Select at least one checkbox")
//     .required("Required"),
//   machinelist: Yup.array()
//     .min(1, "Select at least one machinelist")
//     .required("Required"),
//   pokayoga: Yup.array()
//     .min(1, "Select at least one pokayoga")
//     .required("Required"),
//   stationcode: Yup.string().required("Station Code is required"),
//   stationname: Yup.string().required("Station Name is required"),
//   // stationdescription: Yup.string().required("Station Descrption is required"),
//   // tags: Yup.string().required("Tags is required"),
// });

// export default function CreateStationPopup(props) {
//   const [loading, setLoading] = useState(false);

//   const { machine } = useSelector((state) => state.machine);
//   const { pokayoke } = useSelector((state) => state.pokayoke);
//   const { machinechecklist } = useSelector((state) => state.machinechecklist);
//   const dispatch = useDispatch();
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [openpokayoke, setopenpokayoke] = useState(false);
//   const [openpopupChecklist, setopenpopupChecklist] = useState(false);
//   const [openpopupMachine, setopenpopupMachine] = useState(false);
//   // const [ checkListEdit, setChecklistEdit ] = useState({});

//   const fileInputRef = useRef(null);
//   const formik = useFormik({
//     initialValues: {
//       checklist: [],
//       machinelist: [],
//       pokayoga: [],
//       stationcode: "",
//       stationname: "",
//       stationdescription: "",
//       bypass: "",
//       rework: "",
//       setuppart: "",
//       totalreworkcount: "",
//       tags: [],
//       upload: null,
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       setLoading(true);
//       console.log("Form Values:", values);
//       const requestData = {
//         code: values.stationcode?.trim(),
//         name: values.stationname?.trim(),
//         description: values.stationdescription?.trim(),
//         totalreworkcount: values.totalreworkcount.toString(),
//         bypass: values.bypass ? values.bypass.toString() : "false",
//         rework: values.rework ? values.rework.toString() : "false",
//         setuppart: values.setuppart ? values.setuppart.toString() : "false",
//         tags: values.tags,
//         category: values.checklist,
//         machine: values.machinelist,
//         pokayoke: values.pokayoga,
//         recstatus: "OPEN",
//         resourcecode: "DAYCO",
//         status: "Active",
//         syncstatus: "SYNCED",
//         type: "CHECKLIST",
//       };
//       console.warn("requestData", requestData);
//        const response = await dispatch(createStation(requestData));

//       if (!response.error) {
//         const code = response?.payload?.data?.dto?.code;
//         UploadFile(selectedFile, code);
//         setLoading(false);
//         swal(
//           "Good job!",
//           "Station has been successfully registered!",
//           "success"
//         );
//       } else {
//         swal({
//           icon: "error",
//           title: "Error",
//           text: "Something went wrong",
//         });
//         setLoading(false);
//       }
//       resetForm();
//       handleClose();
//     },
//   });

//   const UploadFile = (document, station_code) => {
//     const formData = new FormData();
//     const body = { code: station_code, resourcecode: "DAYCO" };
//     formData.append("file", document);
//     formData.append("body", JSON.stringify(body));
//     dispatch(fileUpload(formData));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setSelectedFile(file);
//   };

//   // console.log("upload file", selectedFile);
//   const handleChooseFileClick = () => {
//     // Trigger the file input's click event
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   function onDeleteCategory(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deleteMachinechecklist(item))
//           .then(() => {
//             formik.setFieldValue(
//               "checklist",
//               formik.values.checklist.filter((value) => value !== item)
//             );
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   function onDeletePokayoke(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deletePokayoke(item))
//           .then(() => {
//             formik.setFieldValue(
//               "checklist",
//               formik.values.checklist.filter((value) => value !== item)
//             );
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   function onDeleteMachine(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deleteMachine(item))
//           .then(() => {
//             formik.setFieldValue(
//               "machinelist",
//               formik.values.machinelist.filter((value) => value !== item)
//             );
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   useEffect(() => {
//     dispatch(getMachine());
//     dispatch(getPokayoke());
//     dispatch(getMachinechecklist());
//   }, [dispatch]);

//   const handleClose = () => {
//     setopenpopupStation(false);
//   };

//   const { openpopupStation, setopenpopupStation } = props;

//   // Render the component
//   return (
//     <Dialog
//       open={openpopupStation}
//       onClose={handleClose}
//       fullWidth
//       maxWidth="md"
//     >
//       <Grid
//         container
//         justifyContent="center"
//         alignItems="center"
//         sx={{ mt: 8 }}
//       >
//         <Grid item xs={12} lg={12}>
//           <form onSubmit={formik.handleSubmit}>
//             <Card>
//               <MDBox mx={2} mt={-3}>
//                 <MDBox
//                   variant="gradient"
//                   bgColor="info"
//                   borderRadius="lg"
//                   coloredShadow="success"
//                   mx={2}
//                   mt={-3}
//                   p={2}
//                   mb={-2}
//                   textAlign="center"
//                 >
//                   <MDTypography
//                     variant="h4"
//                     fontWeight="small"
//                     color="white"
//                     mt={1}
//                   >
//                     Station Master
//                   </MDTypography>
//                 </MDBox>
//               </MDBox>

//               <MDBox p={3}>
//                 <Grid container spacing={3} mt={0.5}>
//                   <Grid item xs={12} sm={6}>
//                     <FormControl fullWidth>
//                       <TextField
//                         id="stationcode"
//                         name="stationcode"
//                         label=<span>
//                           Station Code
//                           <sup
//                             style={{
//                               color: "red",
//                               fontSize: "small",
//                               fontWeight: "bolder",
//                               position: "relative",
//                               top: "2px",
//                             }}
//                           >
//                             {" "}
//                             *
//                           </sup>
//                         </span>
//                         variant="standard"
//                         value={formik.values.stationcode}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={
//                           formik.touched.stationcode &&
//                           Boolean(formik.errors.stationcode)
//                         }
//                         helperText={
//                           formik.touched.stationcode &&
//                           formik.errors.stationcode
//                         }
//                       />
//                     </FormControl>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <FormControl fullWidth>
//                       <TextField
//                         id="staionname"
//                         name="stationname"
//                         label=<span>
//                           Station Name
//                           <sup
//                             style={{
//                               color: "red",
//                               fontSize: "small",
//                               fontWeight: "bolder",
//                               position: "relative",
//                               top: "2px",
//                             }}
//                           >
//                             {" "}
//                             *
//                           </sup>
//                         </span>
//                         variant="standard"
//                         value={formik.values.stationname}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={
//                           formik.touched.stationname &&
//                           Boolean(formik.errors.stationname)
//                         }
//                         helperText={
//                           formik.touched.stationname &&
//                           formik.errors.stationname
//                         }
//                       />
//                     </FormControl>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <FormControl fullWidth>
//                       <TextField
//                         id="staiondescription"
//                         name="stationdescription"
//                         label="Station Description"
//                         variant="standard"
//                         value={formik.values.stationdescription}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={
//                           formik.touched.stationdescription &&
//                           Boolean(formik.errors.stationdescription)
//                         }
//                         helperText={
//                           formik.touched.stationdescription &&
//                           formik.errors.stationdescription
//                         }
//                       />
//                     </FormControl>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <FormControl fullWidth>
//                       <TextField
//                         id="tags"
//                         name="tags"
//                         label="Tags"
//                         variant="standard"
//                         value={formik.values.tags}
//                         onChange={(e) => {
//                           const newValue = e.target.value;
//                           formik.setFieldValue(
//                             "tags",
//                             newValue ? [newValue] : []
//                           );
//                         }}
//                         onBlur={formik.handleBlur}
//                         error={
//                           formik.touched.tags && Boolean(formik.errors.tags)
//                         }
//                         helperText={formik.touched.tags && formik.errors.tags}
//                       />
//                     </FormControl>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <FormControl fullWidth>
//                       <TextField
//                         id="totalreworkcount"
//                         type="number"
//                         name="totalreworkcount"
//                         label="Total Rework Count"
//                         variant="standard"
//                         value={formik.values.totalreworkcount}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={
//                           formik.touched.totalreworkcount &&
//                           Boolean(formik.errors.totalreworkcount)
//                         }
//                         helperText={
//                           formik.touched.totalreworkcount &&
//                           formik.errors.totalreworkcount
//                         }
//                       />
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <FormGroup>
//                       <FormControlLabel
//                         control={
//                           <Switch
//                             checked={formik.values.rework}
//                             onClick={(e) =>
//                               formik.setFieldValue("rework", e.target.checked)
//                             }
//                             name="rework"
//                           />
//                         }
//                         label="Rework"
//                       />
//                     </FormGroup>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <FormGroup>
//                       <FormControlLabel
//                         control={
//                           <Switch
//                             checked={formik.values.bypass}
//                             onClick={(e) =>
//                               formik.setFieldValue("bypass", e.target.checked)
//                             }
//                             name="bypass"
//                           />
//                         }
//                         label="Bypass"
//                       />
//                     </FormGroup>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <FormGroup>
//                       <FormControlLabel
//                         control={
//                           <Switch
//                             checked={formik.values.setuppart}
//                             onClick={(e) =>
//                               formik.setFieldValue(
//                                 "setuppart",
//                                 e.target.checked
//                               )
//                             }
//                             name="setuppart"
//                           />
//                         }
//                         label="Setup Part"
//                       />
//                     </FormGroup>
//                   </Grid>

//                   <Grid
//                     item
//                     xs={12}
//                     sm={4.85}
//                     sx={{ display: "grid", placeItems: "end" }}
//                   >
//                     <TextField
//                       fullWidth
//                       variant="standard"
//                       label="Upload Document"
//                       InputProps={{ readOnly: true }}
//                       InputLabelProps={{
//                         shrink: Boolean(selectedFile?.name),
//                       }}
//                       value={selectedFile?.name}
//                       sx={{ borderBottom: "0px solid #dce0e2" }}
//                     />
//                   </Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     sm={1}
//                     mr={3.1}
//                     ml={-2}
//                     sx={{ display: "grid", placeItems: "end" }}
//                   >
//                     <input
//                       type="file"
//                       name="document"
//                       onChange={handleFileChange}
//                       ref={fileInputRef}
//                       style={{ display: "none" }}
//                     />
//                     <Tooltip title="Upload Document" arrow>
//                       <MDButton
//                         variant="outlined"
//                         color="info"
//                         onClick={handleChooseFileClick}
//                       >
//                         <CloudUploadIcon />
//                       </MDButton>
//                     </Tooltip>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <span style={{ display: "flex", alignItems: "center" }}>
//                       <Autocomplete
//                         multiple
//                         fullWidth
//                         id="checklist-tags-demo"
//                         options={machinechecklist}
//                         disableCloseOnSelect
//                         getOptionLabel={(option) => option?.name}
//                         onChange={(e, value) =>
//                           formik.setFieldValue("checklist", value)
//                         }
//                         value={formik.values.checklist}
//                         onBlur={formik.handleBlur}
//                         renderOption={(props, option, { selected }) => (
//                           <div
//                             {...props}
//                             style={{
//                               display: "flex",
//                               justifyContent: "space-between",
//                               alignItems: "center",
//                               padding: "8px",
//                               borderBottom: "1px solid #ccc",
//                             }}
//                           >
//                             <div
//                               style={{ display: "flex", alignItems: "center" }}
//                             >
//                               <Checkbox
//                                 icon={icon}
//                                 checkedIcon={checkedIcon}
//                                 checked={selected}
//                               />
//                               <span>{option?.name}</span>
//                               <Tooltip title={option?.desc} arrow>
//                               <span>{option?.name}</span>
//                       </Tooltip>
//                             </div>
//                             <DeleteIcon
//                               fontSize="small"
//                               sx={{ color: "red" }}
//                               onClick={() => {
//                                 onDeleteCategory(option);
//                               }}
//                             />
//                           </div>
//                         )}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             label=<span>
//                               Machine Check List
//                               <sup
//                                 style={{
//                                   color: "red",
//                                   fontSize: "small",
//                                   fontWeight: "bolder",
//                                   position: "relative",
//                                   top: "2px",
//                                 }}
//                               >
//                                 {" "}
//                                 *
//                               </sup>
//                             </span>
//                             placeholder=""
//                             variant="standard"
//                             error={
//                               formik.touched.checklist &&
//                               Boolean(formik.errors.checklist)
//                             }
//                             helperText={
//                               formik.touched.checklist &&
//                               formik.errors.checklist
//                             }
//                           />
//                         )}
//                       />
//                       <Tooltip title="Add Checklist" arrow>
//                         <AddBoxIcon
//                           fontSize="large"
//                           sx={{ cursor: "pointer" }}
//                           onClick={() => setopenpopupChecklist(true)}
//                         />
//                       </Tooltip>
//                     </span>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <span style={{ display: "flex", alignItems: "center" }}>
//                       <Autocomplete
//                         multiple
//                         fullWidth
//                         id="machinelist-tags-demo"
//                         options={machine}
//                         disableCloseOnSelect
//                         getOptionLabel={(option) => option?.name}
//                         onChange={(e, value) =>
//                           formik.setFieldValue("machinelist", value)
//                         }
//                         value={formik.values.machinelist}
//                         onBlur={formik.handleBlur}
//                         renderOption={(props, option, { selected }) => (
//                           <div
//                             {...props}
//                             style={{
//                               display: "flex",
//                               justifyContent: "space-between",
//                               alignItems: "center",
//                               padding: "8px",
//                               borderBottom: "1px solid #ccc",
//                             }}
//                           >
//                             <div
//                               style={{ display: "flex", alignItems: "center" }}
//                             >
//                               <Checkbox
//                                 icon={icon}
//                                 checkedIcon={checkedIcon}
//                                 checked={selected}
//                               />
//                               <span>
//                                 {option?.name}
//                               </span>
//                             </div>
//                             <DeleteIcon
//                               fontSize="small"
//                               sx={{ color: "red" }}
//                               onClick={() => {
//                                 onDeleteMachine(option);
//                               }}
//                             />
//                           </div>
//                         )}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             label=<span>
//                               Machine List
//                               <sup
//                                 style={{
//                                   color: "red",
//                                   fontSize: "small",
//                                   fontWeight: "bolder",
//                                   position: "relative",
//                                   top: "2px",
//                                 }}
//                               >
//                                 {" "}
//                                 *
//                               </sup>
//                             </span>
//                             placeholder=""
//                             variant="standard"
//                             error={
//                               formik.touched.machinelist &&
//                               Boolean(formik.errors.machinelist)
//                             }
//                             helperText={
//                               formik.touched.machinelist &&
//                               formik.errors.machinelist
//                             }
//                           />
//                         )}
//                       />
//                       <Tooltip title="Add Machine" arrow>
//                         <AddBoxIcon
//                           fontSize="large"
//                           sx={{ cursor: "pointer" }}
//                           onClick={() => setopenpopupMachine(true)}
//                         />
//                       </Tooltip>
//                     </span>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <span style={{ display: "flex", alignItems: "center" }}>
//                       <Autocomplete
//                         multiple
//                         fullWidth
//                         id="machinelist-tags-demo"
//                         options={pokayoke}
//                         disableCloseOnSelect
//                         getOptionLabel={(option) => option?.name}
//                         onChange={(e, value) =>
//                           formik.setFieldValue("pokayoga", value)
//                         }
//                         value={formik.values.pokayoga}
//                         onBlur={formik.handleBlur}
//                         renderOption={(props, option, { selected }) => (
//                           <div
//                             {...props}
//                             style={{
//                               display: "flex",
//                               justifyContent: "space-between",
//                               alignItems: "center",
//                               padding: "8px",
//                               borderBottom: "1px solid #ccc",
//                             }}
//                           >
//                             <div
//                               style={{ display: "flex", alignItems: "center" }}
//                             >
//                               <Checkbox
//                                 icon={icon}
//                                 checkedIcon={checkedIcon}
//                                 checked={selected}
//                               />
                             
//                               <Tooltip title={option?.desc} arrow>
//                               <span>{option?.name}</span>
//                       </Tooltip>
//                             </div>
//                             <DeleteIcon
//                               fontSize="small"
//                               sx={{ color: "red" }}
//                               onClick={() => {
//                                 onDeletePokayoke(option);
//                               }}
//                             />
//                           </div>
//                         )}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             label=<span>
//                               Poka yoke
//                               <sup
//                                 style={{
//                                   color: "red",
//                                   fontSize: "small",
//                                   fontWeight: "bolder",
//                                   position: "relative",
//                                   top: "2px",
//                                 }}
//                               >
//                                 {" "}
//                                 *
//                               </sup>
//                             </span>
//                             placeholder=""
//                             variant="standard"
//                             error={
//                               formik.touched.pokayoga &&
//                               Boolean(formik.errors.pokayoga)
//                             }
//                             helperText={
//                               formik.touched.pokayoga && formik.errors.pokayoga
//                             }
//                           />
//                         )}
//                       />
//                       <Tooltip title="pokayoke" arrow>
//                         <AddBoxIcon
//                           fontSize="large"
//                           sx={{ cursor: "pointer" }}
//                           onClick={() => setopenpokayoke(true)}
//                         />
//                       </Tooltip>
//                     </span>
//                   </Grid>
//                 </Grid>

//                 <MDBox
//                   mt={5}
//                   width="100%"
//                   display="flex"
//                   justifyContent="flex-end"
//                 >
//                   <MDButton type="submit" variant="gradient" color="dark">
//                     {loading ? (
//                       <MoonLoader color="#f2fefa" size={16} />
//                     ) : (
//                       "Submit"
//                     )}
//                   </MDButton>
//                 </MDBox>
//               </MDBox>
//             </Card>
//           </form>
//         </Grid>
//       </Grid>
//       <CreateChecklist
//         openpopupChecklist={openpopupChecklist}
//         setopenpopupChecklist={setopenpopupChecklist}
//         // setChecklistedit = {checkListEdit}
//       />
//       <CreateMachine
//         openpopupMachine={openpopupMachine}
//         setopenpopupMachine={setopenpopupMachine}
//       />

//       <CreatePokayoke
//         setopenpokayoke={setopenpokayoke}
//         openpokayoke={openpokayoke}
//       />
//     </Dialog>
//   );
// }
