import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import { TextField, Select, InputLabel } from "@mui/material";
import MDInput from "components/MDInput";

import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

import { getAttribute } from "app/attributeMaster/service/Attribute";
import { getAttributeVariation } from "./service/Attributevariation";
import MDButton from "components/MDButton";
import { updateNoOfattributevariation } from "./reducer/attributevariationDetailSlice";
// 3graph=========================
function Stationwiseattribute({
  color,
  title,
  description,

  chart,
  datemargin,
}) {
  const dispatch = useDispatch();
  // const { partprocessed, loading, error } = useSelector(
  //   (state) => state.partprocessed
  // );
  const { station } = useSelector((state) => state.station);
  const { attribute } = useSelector((state) => state.attribute);
  const [datanew, setDataNew] = useState([]);
  const [labelsnew, setLabelsNew] = useState([]);
  const { graph3Messages, loading1, error1 } = useSelector(
    (state) => state.graph3Messages
  );

  const { attributevariation, loading, error } = useSelector(
    (state) => state.attributevariation
  );

  const date = localStorage.getItem("attribute-date")?.replace(/"/g, "");
  const starttimelocal = localStorage
    .getItem("attribute-starttime")
    ?.replace(/"/g, "");
  const endtimelocal = localStorage
    .getItem("attribute-endtime")
    ?.replace(/"/g, "");

  const stationcodelocal = localStorage
    .getItem("station-code")
    ?.replace(/"/g, "");
  const attributenamelocal = localStorage
    .getItem("attribute-name")
    ?.replace(/"/g, "");
  const attributelocal = localStorage.getItem("attribute-list");

  const [attributeList, setAttributeList] = useState(
    JSON.parse(attributelocal) ? JSON.parse(attributelocal) : []
  );
  const [stationcode, setStationcode] = useState(
    stationcodelocal ? stationcodelocal : ""
  );
  const [attributename, setAttributename] = useState(
    attributenamelocal ? attributenamelocal : ""
  );
  const [starttime, setStartTime] = useState(
    starttimelocal ? starttimelocal : ""
  );
  const [endtime, setEndTime] = useState(endtimelocal ? endtimelocal : "");
  const [partdata, setPartData] = useState([]);
  const [analytic3message, setAnalytic3Message] = useState([]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(
    date ? date : formatDate(new Date())
  );

  const dateString = selectedDate;
  const timeString = starttime;
  const timeString2 = endtime;

  // Combine date and time strings into a single string
  const combinedStartDateTime = `${dateString} ${timeString}:00`;
  const combinedENDtDateTime = `${dateString} ${timeString2}:00`;

  const { data, options } = configs(
    labelsnew.length > 0 ? labelsnew : attributevariation.labels || [],
    datanew.length > 0
      ? { ...attributevariation?.datasets, data: datanew }
      : attributevariation.datasets || {}
  );

  const handleStartTime = async (event) => {
    localStorage.setItem(
      "attribute-starttime",
      JSON.stringify(event?.target?.value)
    );

    setStartTime(event.target.value);
  };
  const handleEndTime = async (event) => {
    localStorage.setItem(
      "attribute-endtime",
      JSON.stringify(event?.target?.value)
    );
    setEndTime(event.target.value);
  };

  const handleDateChange = async (event) => {
    setSelectedDate(event?.target?.value);
    localStorage.setItem(
      "attribute-date",
      JSON.stringify(event?.target?.value)
    );
  };

  function getAttributeData(code) {
    const result = attribute?.filter((item) => item.stationcode === code);
    localStorage.setItem("attribute-list", JSON.stringify(result));

    setAttributeList(result);
  }

  useEffect(() => {
    if (datanew.length > 0) {
      const fetchData = async () => {
        const data = {
          attributeName: attributename,
          stationcode: stationcode,
          enddate: combinedENDtDateTime,
          startdate: combinedStartDateTime,
        };

        await dispatch(getAttributeVariation(data));
      };
      fetchData();
    }
  }, [datanew]);

  useEffect(() => {
    dispatch(getAttribute());

    const formatTimeAndSetDefaults = (date) => {
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      setStartTime(formattedTime);
    };
    const formatTimeAndSetDefaults2 = (date) => {
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      setEndTime(formattedTime);
    };

    // ... (your existing code)

    // Set default values for time if local storage doesn't have values
    if (!starttimelocal) {
      const defaultTime = new Date(); // Use the default date object or set your default time
      formatTimeAndSetDefaults(defaultTime);
    }
    if (!endtimelocal) {
      const defaultTime = new Date(); // Use the default date object or set your default time
      formatTimeAndSetDefaults2(defaultTime);
    }

    const fetchData = async () => {
      const data = {
        attributeName: attributename,
        stationcode: stationcode,
        enddate: combinedENDtDateTime,
        startdate: combinedStartDateTime,
      };

      const response = await dispatch(getAttributeVariation(data));

      if (response.error && !loading) {
        swal({
          icon: "error",
          title: "Error",
          text: response?.payload?.message
            ? response?.payload?.message
            : error?.message,
        });
      }
    };

    fetchData();
  }, []);

  const filter = async (
    attributename,
    stationcode,
    combinedENDtDateTime,
    combinedStartDateTime
  ) => {
    const data = {
      attributeName: attributename,
      stationcode: stationcode,
      enddate: combinedENDtDateTime,
      startdate: combinedStartDateTime,
    };

    const response = await dispatch(getAttributeVariation(data));

    if (response.error && !loading) {
      swal({
        icon: "error",
        title: "Error",
        text: response?.payload?.message
          ? response?.payload?.message
          : error?.message,
      });
    }
  };

  useEffect(() => {
    if (
      new Date(graph3Messages?.createddate) >=
        new Date(combinedStartDateTime) &&
      new Date(graph3Messages?.createddate) <= new Date(combinedENDtDateTime) &&
      graph3Messages?.stationcode == stationcode &&
      graph3Messages?.name == attributename &&
      /^[-+]?\d*\.?\d+$/.test(graph3Messages?.value)
    ) {
      setAnalytic3Message(graph3Messages);
    }
  }, [graph3Messages]);

  useEffect(() => {
    if (Object.keys(analytic3message)?.length > 0) {
      const attributedate = new Date(graph3Messages?.createddate)
        ?.toISOString()
        .replace("T", " ")
        .slice(0, 19);
      if (
        attributevariation.labels &&
        Array.isArray(attributevariation.labels)
      ) {
        const index =
          labelsnew?.length > 0
            ? labelsnew.indexOf(attributedate)
            : attributevariation.labels.indexOf(attributedate);
        if (index !== -1) {
          const newData =
            datanew?.length > 0
              ? [...datanew]
              : [...attributevariation.datasets.data];
          newData[index] = (
            parseFloat(newData[index]) + parseFloat(analytic3message?.value)
          ).toString();
          dispatch(updateNoOfattributevariation({ data: newData }));
          setDataNew(newData);
        } else {
          const newLabels =
            labelsnew?.length > 0
              ? [...labelsnew, attributedate]
              : [...attributevariation.labels, attributedate];
          const newData =
            datanew?.length > 0
              ? [...datanew, analytic3message?.value]
              : [
                  ...attributevariation?.datasets?.data,
                  analytic3message?.value,
                ];
          // newLabels.shift();
          // newData.shift();
          dispatch(
            updateNoOfattributevariation({ labels: newLabels, data: newData })
          );
          setDataNew(newData);
          setLabelsNew(newLabels);
        }
      }
    }
  }, [analytic3message]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              {loading ? (
                <CircularProgress color="inherit" size={40} />
              ) : (
                <Line data={data} options={options} />
              )}
            </MDBox>
          ),
          [chart, color, loading]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <Select
              native
              variant="outlined"
              style={{ width: "30%", marginRight: "1px" }}
              value={stationcode}
              name="stationvalue"
              id="stationvalue"
              onChange={(e) => {
                const stationvalue = e.target.value;
                localStorage.setItem(
                  "station-code",
                  JSON.stringify(stationvalue)
                );

                setStationcode(e.target.value);
                setAttributename("");

                // const selectedDepartmentObj =
                //   station.find((obj) => obj.code === stationvalue) || {};

                getAttributeData(stationvalue);
              }}
            >
              <option value="" disabled selected>
                Select Station
              </option>
              {station.map((obj) => (
                <option key={obj.id} value={obj.code}>
                  {obj.name}
                </option>
              ))}
            </Select>
            <Select
              native
              variant="outlined"
              style={{ width: "30%", marginRight: "3px", marginLeft: "auto" }}
              name="attributeame"
              value={attributename}
              id="attributeame"
              onChange={(e) => {
                const attribute = e.target.value;
                localStorage.setItem(
                  "attribute-name",
                  JSON.stringify(attribute)
                );

                setAttributename(attribute);
                // const selectedDepartmentObj =
                //   station.find((obj) => obj.code === stationvalue) || {};
              }}
            >
              <option value="" disabled selected>
                Select Attribute
              </option>
              <option value=""></option>
              {attributeList.map((obj) => (
                <option key={obj.id} value={obj.name}>
                  {obj.name}
                </option>
              ))}
            </Select>

            <MDInput
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: "30%", marginRight: "3px" }}
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />

            <MDInput
              type="time"
              value={starttime}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: "30%", marginRight: "3px" }}
              label="Start Time"
              onChange={handleStartTime}
            />
            <MDInput
              type="time"
              value={endtime}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: "30%", marginRight: "3px" }}
              label="End Time"
              onChange={handleEndTime}
            />
            <MDButton
              style={{ width: "13%", marginRight: "3px" }}
              color="success"
              onClick={() => {
                filter(
                  attributename,
                  stationcode,
                  combinedENDtDateTime,
                  combinedStartDateTime
                );
              }}
            >
              filter
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Stationwiseattribute.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  chart: PropTypes.string,
  datemargin: PropTypes.bool,
};

export default Stationwiseattribute;
