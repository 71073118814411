import { createSlice } from "@reduxjs/toolkit";
import { createRole, deleteRole, getRole, updateRole } from "../service/Role";





const initialState = {
  role: [],
  loading: false,
  error: null,
};

export const roleDetail = createSlice({
  name: "roleDetail",
  initialState,
  extraReducers: {
    [createRole.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createRole.fulfilled]: (state, action) => {
      console.log("state",state.role)
      state.loading = false;
      state.role.push(action.payload.data.dto);
    },
    [createRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getRole.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.role = action.payload.data.dtoList;

    },
    [getRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteRole.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.role = state.role.filter((role) => role.id !== id);
      }
    },
    [deleteRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
   
    },
    [updateRole.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateRole.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.role.findIndex(role => role.id === action.payload.data.dto.id)
      state.role[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default roleDetail.reducer;
