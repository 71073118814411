import swal from 'sweetalert';
const initialState = {
    auth: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")) : {},
    Username: localStorage.getItem("profile-Name") ? JSON.parse(localStorage.getItem("profile-Name")) : {},
   // Investment: localStorage.getItem("investment-profile") ? JSON.parse(localStorage.getItem("investment-profile")) : {},
    isAuthenticated: localStorage.getItem("mmu-auth") ? true : false,

    errors: {},

    loading: false
}
const AuthReducer = (state = initialState, action) => {
   

    switch (action.type) {
        case "LOGIN_PENDING":
            return { loading: true }

        case 'LOGIN_FULFILLED':
            swal("Welcome Back!", action.payload?.data?.dto.firstname + " " + action.payload?.data?.dto.lastname, "success");
          
            if (action.payload?.data?.dto !== "") {
           
                localStorage.setItem('mmu-auth', JSON.stringify(action.payload?.data?.dto))
                localStorage.setItem('profile-Name', JSON.stringify(action.payload?.data?.dto.firstname + " "
                    + action.payload?.data?.dto.lastname))
                return {
                    
                    auth: action.payload?.data?.dto,
                    isAuthenticated: true,
                    loading: false     

                }
                

            }
            else {
                swal("Alert!", "...Internal Server Error!");
                localStorage.removeItem('mmu-auth')
                localStorage.removeItem('profile-Name')
                localStorage.removeItem('part-date')
                localStorage.removeItem('part-processed')
                localStorage.removeItem('attribute-name')
                localStorage.removeItem('attribute-list')
                localStorage.removeItem('attribute-date')
                localStorage.removeItem('station-code')
                localStorage.removeItem('attribute-endtime')
                localStorage.removeItem('attribute-starttime')



                localStorage.removeItem('partattribute-name')
                localStorage.removeItem('partattribute-list')
              
                localStorage.removeItem('partstation-code')
           
                localStorage.removeItem('part4-Day')
                localStorage.removeItem('part1-Day')
               // localStorage.removeItem('investment-profile')
                return {
                    auth: {},
                    Username: {},
                  //  Investment:{},
                    isAuthenticated: false
                }
            }
        case "LOGIN_REJECTED":
            // swal("Alert!", "...Incorrect Username or Password!");
            if (action.payload?.response?.status === 401) {
                swal("Alert!", "Incorrect Password!");
            } else {
                swal("Alert!", "Something went wrong!");
            }
            localStorage.removeItem('mmu-auth')
            localStorage.removeItem('profile-Name')
            localStorage.removeItem('part-date')
            localStorage.removeItem('part-processed')
            localStorage.removeItem('attribute-name')
            localStorage.removeItem('attribute-list')
            localStorage.removeItem('attribute-date')
            localStorage.removeItem('station-code')
            localStorage.removeItem('attribute-endtime')
            localStorage.removeItem('attribute-starttime')
            localStorage.removeItem('partattribute-name')
            localStorage.removeItem('partattribute-list')
          
            localStorage.removeItem('partstation-code')
       
            localStorage.removeItem('part4-Day')
            localStorage.removeItem('part1-Day')
           // localStorage.removeItem('investment-profile')
            return {
                auth: {},
                Username: {},
              //  Investment:{},
                isAuthenticated: false,
                errors: action.payload,
                loading: false
            }

        case "LOGOUT_FULFILLED":
            localStorage.removeItem('mmu-auth')
            localStorage.removeItem('profile-Name')
            localStorage.removeItem('part-date')
            localStorage.removeItem('part-processed')
            localStorage.removeItem('attribute-name')
            localStorage.removeItem('attribute-list')
            localStorage.removeItem('attribute-date')
            localStorage.removeItem('station-code')
            localStorage.removeItem('attribute-endtime')
            localStorage.removeItem('attribute-starttime')
            localStorage.removeItem('partattribute-name')
            localStorage.removeItem('partattribute-list')
          
            localStorage.removeItem('partstation-code')
       
            localStorage.removeItem('part4-Day')
            localStorage.removeItem('part1-Day')

            
           
          //  localStorage.removeItem('investment-profile')
            return {
                auth: {},
                Username: {},
              //  Investment:{},
                isAuthenticated: false
            }
        default:
            return state
    }
}

export default AuthReducer