import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{roleName},
} = checkout;

const validations = [
  Yup.object().shape({
    [roleName.name]: Yup.string().required(roleName.errorMsg),
  }),
];

export default validations;
