
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const createHeaderchecklist = createAsyncThunk("createHeaderchecklist", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `checklist-header/api/CheckListHeader/v2/create`,  
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// export const getHeaderchecklist = createAsyncThunk("getHeaderchecklist", async (data, {rejectWithValue}) => {
 

//   try {
//     const response = await axios.get(
//       `checklist-header/api/CheckListHeader/v2/get?operatorid=${data.operatorid}&stationcode=${data.stationcode}&type=${data.type}`,
     
//     );
//     return await response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });


export const getHeaderchecklist = createAsyncThunk("getHeaderchecklist", async (data, { rejectWithValue }) => {
  console.warn("formDataxyz", data);

  try {
    let url = 'checklist-header/api/CheckListHeader/v2/get?';
    if (data?.operatorid !== undefined && data?.operatorid !== '') {
      url += `operatorid=${data.operatorid}`;
    }
    if (data?.stationcode !== undefined && data?.stationcode !== '') {
      url += `${url.includes('operatorid=') ? '&' : ''}stationcode=${data.stationcode}`;
    }
    if (data?.type !== undefined) {
      url += `${url.includes('operatorid=') || url.includes('stationcode=') ? '&' : ''}type=${data.type}`;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

export const updateHeaderchecklist = createAsyncThunk("updateHeaderchecklist", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `checklist-header/api/CheckListHeader/v2/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteHeaderchecklist = createAsyncThunk("deleteHeaderchecklist", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `checklist-header/api/CheckListHeader/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});









