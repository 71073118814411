import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

//edit icon
import { FaRegEdit } from "react-icons/fa";
import { deleteRole, getRole } from "../service/Role";
import Roleedit from "./Roleedit";
import CreateRolePopup from "./CreateRolePopup";
import MDButton from "components/MDButton";

function RoleList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role, loading, error } = useSelector((state) => state.role);
  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [openpopupRole, setopenpopupRole] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function editBrand(item) {
   

    setopenpopup(true);
    setSelectedRole(item);
  }

  async function onDelete(item) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    });

    if (willDelete) {
      swal("Deleting...", {
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
      });

      // Dispatch the deleteBrand action
      const response = await dispatch(deleteRole(item));

      if (!response.error && !loading) {
        swal("Deleted!", "Your item has been deleted.", "success");
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: error?.message
            ? error.message
            : "An error occurred while deleting the item",
        });
      }
    } else {
      swal("Cancelled", "Your item is safe.", "info");
    }
  }

  useEffect(() => {
    dispatch(getRole());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Card>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Role Master
                      </MDTypography>
                    </span>

                    <span
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                    >
                      <MDButton
                        color="success"
                        onClick={() => setopenpopupRole(true)}
                      >
                        ADD
                      </MDButton>
                    </span>
                  </div>
                </MDBox>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "name", accessor: "name" },
                      { Header: "description", accessor: "description" },
                      {
                        Header: "Created Date",
                        accessor: "createddate",
                        Cell: ({ value }) => {
                          const formattedDate = new Date(value).toDateString();
                          return formattedDate;
                        },
                      },

                      {
                        Header: "Action",
                        accessor: "action",

                        Cell: ({ row }) => (
                          <div>
                            <Link to={{}}>
                              <FaRegEdit
                                style={iconStyleedit}
                                onClick={() => {
                                  editBrand(row.original);
                                }}
                              />
                            </Link>

                            <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(row.original);
                                }}
                              />
                            </Link>
                          </div>
                        ),
                      },
                    ],
                    rows: role || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Roleedit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Role Edit"}
        selectedRole={selectedRole}
      />
      <CreateRolePopup
        openpopupRole={openpopupRole}
        setopenpopupRole={setopenpopupRole}
      />
    </DashboardLayout>
  );
}

export default RoleList;
