// import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "../../../axiosinstance";
// //create role


// export const getBomstation = createAsyncThunk(
//   "getBomstation",
//   async (data, { rejectWithValue }) => {
//     console.log("formData", data);

//     try {
//       if (data !== undefined) {
//         const response = await axios.get(
//           `bomcreation-master/api/BOMCreation/v2/getStation?bomStatus=true&stationCode=${data}`
         
//         );
//         return await response.data;
//       } else {
//         const response = await axios.get(
//           `bomcreation-master/api/BOMCreation/v2/getStation`
//         );
//         return await response.data;
//       }
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );






import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role


export const getBomstation = createAsyncThunk(
  "getBomstation",
  async (data, { rejectWithValue }) => {
    console.log("formDatasss", data);

    try {
      if (data !== undefined) {
        const response = await axios.get(
          `bomcreation-master/api/BOMCreation/v2/getStationV2?partNo=${data?.name}&stationCode=${data?.code}`
         
        );
        return await response?.data;
      } else {
        const response = await axios.get(
          `bomcreation-master/api/BOMCreation/v2/getStation`
        );
        return await response?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);