import { createSlice } from "@reduxjs/toolkit";
import { getBomstation } from "../service/BomStation";

const initialState = {
  bomstation: {},
  loading: false,
  error: null,
};

export const bomstationDetail = createSlice({
  name: "bomstationDetail",
  initialState,
  extraReducers: {
    [getBomstation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBomstation.fulfilled]: (state, action) => {
      state.loading = false;
     
      // state.bomstation = action.payload.data.dto.stations;
      state.bomstation = {...action?.payload?.data?.dto};
   
    },
    [getBomstation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default bomstationDetail.reducer;
