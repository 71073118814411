import { createSlice } from "@reduxjs/toolkit";
import { getFinalStation } from "../service/FinalStation";

const initialState = {
  finalstation: [],
  loading: false,
  error: null,
};

export const finalstationDetail = createSlice({
  name: "finalstation",
  initialState,
  extraReducers: {
    [getFinalStation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getFinalStation.fulfilled]: (state, action) => {
      state.loading = false;
      state.finalstation = action.payload;
    },
    [getFinalStation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default finalstationDetail.reducer;
