import { createSlice } from "@reduxjs/toolkit";
import { getPartProcessed } from "../service/Partprocessed";






const initialState = {
  partprocessed: {
    datasets: { label: 'Parts Processed Per Station', data: [] },
    error: null,
    labels: [],
    objects: null,
  },
  loading: false,
  error: null,
};

export const partprocessedDetail = createSlice({
  name: "partprocessedDetail",
  initialState,
  reducers: {
    updateNoOfPartProcessed: (state, action) => {
      const newData = action.payload;
      state.partprocessed.labels = newData?.labels;
      state.partprocessed.datasets.data = newData?.data;
    },
  },
  extraReducers: {
  
    [getPartProcessed.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPartProcessed.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.partprocessed = action.payload;

    },
    [getPartProcessed.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
  },
});

export const { updateNoOfPartProcessed } = partprocessedDetail.actions;
export default partprocessedDetail.reducer;
//2 graph