import {
  Card,
  CircularProgress,
  Grid,
  Input,
  useMediaQuery,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import "./LineProduction.css";
import LineProductionTable from "components/LineProductionTable/LineProductionTable";
import { useSelector, useDispatch } from "react-redux";
import { getBOMCreation } from "app/bomMaster/service/BOMCreationMaster";
import axios from "../../axiosinstance";
import MDDatePickerMod from "components/MDDatePickerMod";
import { reportServiceAction } from "redux/Actions/reportServiceAction";
import moment from "moment-timezone";
import { resetReportServiceList } from "redux/slices/getReportServiceData";

const defaultData = {
  linecode: "NA",
  line: "NA",
  shiftno: "NA",
  tablecell: [
    { "Targeted Machine": "-" },
    { "Bottleneck cycle time (Sec)": "-" },
    { "Operator efficiency factor": "-" },
    { "Target (Nos.)": "-" },
    { "Actual (Nos.)": "-" },
    { "Loss (Nos.)": "-" },
    { "Actual Line efficiency": "-" },
    { "Line efficiency Loss": "-" },
    { Shift: "-" },
  ],
  barchart: {
    labels: ["Target (Nos)", "Actual OK Production (Nos)", "Loss (Nos)"],
    values: [0, 0, 0],
  },
  piechart: {
    labels: ["Actual line efficiency", "Line efficiency loss"],
    values: [0, 0],
  },
};

const LineProduction = () => {
  const getCurrentDateString = () => {
    const date = new Date();
    return date.toISOString().slice(0, 10);
  };

  const getCurrentDateStringReadable = () => {
    const date = new Date();
    return `${String(date.getDate()).padStart(2, "0")} / ${String(
      date.getMonth() + 1
    ).padStart(2, "0")} / ${date.getFullYear()}`;
  };

  const getCurrentTimeString = () => {
    return `${String(currentTime.getHours()).padStart(2, "0")}:${String(
      currentTime.getMinutes()
    ).padStart(2, "0")}`;
  };

  const auth = localStorage.getItem("mmu-auth")
    ? JSON.parse(localStorage.getItem("mmu-auth"))
    : null;

  let lineproduction = auth
    ? JSON.parse(localStorage.getItem("lineproduction")) || {}
    : {};
  const initialDate = auth
    ? lineproduction.date || getCurrentDateString()
    : getCurrentDateString();
  const initialModel = auth ? lineproduction.model || "" : "";
  const [date, setDate] = useState(initialDate);
  const [model, setModel] = useState(initialModel);
  const [data, setData] = useState([defaultData]);
  const [productionData, setProductionData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const { bomcreationmaster } = useSelector(
    (state) => state.bomcreationmaster || []
  );
  const dispatch = useDispatch();
  const { getReportServiceData } = useSelector((state) => state || []);
  const productionMessage = useSelector(
    (state) => state.productionMessage.productionMessage || []
  );

  const currentTimeString = getCurrentTimeString();
  const [currentDateString, setCurrentDateString] = useState(
    getCurrentDateStringReadable()
  );
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getBOMCreation());
  }, []);

  const formatNumber = (number) =>
    typeof number === "string" && number.includes(".")
      ? `${number.split(".")[0]}.${number.split(".")[1].slice(0, 2)}`
      : number;

  useEffect(() => {
    if (productionMessage) {
      const formattedDate = moment(productionMessage.date).format("YYYY-MM-DD");
      if (formattedDate === date && productionMessage.modelno === model) {
        const matchedItem = getReportServiceData?.list?.find(
          (i) => i.linecode === productionMessage?.linecode
        );
        if (matchedItem) {
          setData((prevData) => {
            return prevData.map((item) =>
              item.linecode === matchedItem.linecode
                ? {
                    linecode: productionMessage?.linecode,
                    line: productionMessage?.linename,
                    shiftno: productionMessage?.shift?.title.replace("-", " "),
                    tablecell: [
                      { "Targeted Machine": productionMessage?.laststation },
                      {
                        "Bottleneck cycle time (Sec)": `${formatNumber(
                          productionMessage?.bottleneckcycletime
                        )} ${
                          productionMessage?.bottleneckcycletime == "NaN"
                            ? ``
                            : `sec`
                        }`,
                      },
                      {
                        "Operator efficiency factor": `${formatNumber(
                          productionMessage?.operatorefficiencyfactor
                        )}${
                          productionMessage?.operatorefficiencyfactor == "NaN"
                            ? ``
                            : `%`
                        }`,
                      },
                      {
                        "Target (Nos.)": formatNumber(
                          productionMessage?.targetno
                        ),
                      },
                      {
                        "Actual (Nos.)": formatNumber(
                          productionMessage?.actualokpart
                        ),
                      },
                      { "Loss (Nos.)": formatNumber(productionMessage?.loss) },
                      {
                        "Actual Line efficiency": `${formatNumber(
                          productionMessage?.actuallineefficiency
                        )}${
                          productionMessage?.actuallineefficiency == "NaN"
                            ? ``
                            : `%`
                        }`,
                      },
                      {
                        "Line efficiency Loss": `${formatNumber(
                          productionMessage?.lineefficiencyloss
                        )}${
                          productionMessage?.lineefficiencyloss == "NaN"
                            ? ``
                            : `%`
                        }`,
                      },
                      {
                        Shift: `${productionMessage?.shift?.title.replace(
                          "-",
                          " "
                        )}(${productionMessage?.shift?.starttime} - ${
                          productionMessage?.shift?.endtime
                        })`,
                      },
                    ],
                    barchart: {
                      labels: [
                        "Target (Nos)",
                        "Actual OK Production (Nos)",
                        "Loss (Nos)",
                      ],
                      values: [
                        formatNumber(productionMessage?.targetno),
                        formatNumber(productionMessage?.actualokpart),
                        formatNumber(productionMessage?.loss),
                      ],
                    },
                    piechart: {
                      labels: [
                        "Actual line efficiency",
                        "Line efficiency loss",
                      ],
                      values: [
                        formatNumber(productionMessage?.actuallineefficiency),
                        formatNumber(productionMessage?.lineefficiencyloss),
                      ],
                    },
                  }
                : item
            );
          });
          setProductionData([productionMessage]);
        }
      }
    }
  }, [productionMessage, getReportServiceData]);

  useEffect(() => {
    if (
      bomcreationmaster?.length > 0 &&
      !model &&
      Object.keys(lineproduction) == 0
    ) {
      setModel(bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno);
      fetchData(
        bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno,
        date
      );
    }
  }, [bomcreationmaster, date, lineproduction]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = new Date();
        if (newTime.getMinutes() !== prevTime.getMinutes()) {
          return newTime;
        }
        return prevTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDate = getCurrentDateStringReadable();
      if (newDate !== currentDateString) {
        setCurrentDateString(newDate);
        const newISODate = new Date().toISOString().slice(0, 10);
        setDate(newISODate);
        fetchData(model, newISODate);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentDateString, model]);

  const fetchData = (model, date) => {
    const formatteddate = new Date(date).toLocaleDateString("en-GB");
    try {
      //   const response = await axios.get(
      //     `report-service/api/Report/v2/getByModelAndDate?modelno=${model}&date=${formatteddate}`
      //   );
      //   const responseData = response?.data?.data?.dtoList || [];

      dispatch(
        reportServiceAction({ model: model, formatteddate: formatteddate })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([defaultData]);
      setProductionData([]);
    }
  };

  useEffect(() => {
    if (!bomcreationmaster.length) {
      dispatch(resetReportServiceList());
      return;
    }

    const lineprod = JSON.parse(localStorage.getItem("lineproduction"));
    if (lineprod && auth) {
      const formatteddate = new Date(lineprod.date).toLocaleDateString("en-GB");
      try {
        dispatch(
          reportServiceAction({
            model: lineprod?.model,
            formatteddate: formatteddate,
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([defaultData]);
        setProductionData([]);
      }
    }
  }, [bomcreationmaster]);

  useEffect(() => {
    if (auth == null || !auth) {
      setDate(getCurrentDateString());
      setModel(bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno);
      fetchData(
        bomcreationmaster?.find((i) => i.recstatus == "true")?.modelno,
        date
      );
    }
  }, [auth, bomcreationmaster]);

  useEffect(() => {
    if (getReportServiceData?.list?.length > 0) {
      setProductionData(getReportServiceData?.list);
      setData(
        getReportServiceData?.list?.map((item) => ({
          linecode: item?.linecode,
          line: item?.linename,
          shiftno: item?.shift?.title.replace("-", " "),
          tablecell: [
            { "Targeted Machine": item?.laststation },
            {
              "Bottleneck cycle time (Sec)": `${formatNumber(
                item?.bottleneckcycletime
              )} ${item?.bottleneckcycletime == "NaN" ? `` : `sec`}`,
            },
            {
              "Operator efficiency factor": `${formatNumber(
                item?.operatorefficiencyfactor
              )}${item?.operatorefficiencyfactor == "NaN" ? `` : `%`}`,
            },
            { "Target (Nos.)": formatNumber(item?.targetno) },
            { "Actual (Nos.)": formatNumber(item?.actualokpart) },
            { "Loss (Nos.)": formatNumber(item?.loss) },
            {
              "Actual Line efficiency": `${formatNumber(
                item?.actuallineefficiency
              )}${item?.actuallineefficiency == "NaN" ? `` : `%`}`,
            },
            {
              "Line efficiency Loss": `${formatNumber(
                item?.lineefficiencyloss
              )}${item?.lineefficiencyloss == "NaN" ? `` : `%`}`,
            },
            {
              Shift: `${item?.shift?.title.replace("-", " ")}(${
                item?.shift?.starttime
              } - ${item?.shift?.endtime})`,
            },
          ],
          barchart: {
            labels: [
              "Target (Nos)",
              "Actual OK Production (Nos)",
              "Loss (Nos)",
            ],
            values: [
              formatNumber(item?.targetno),
              formatNumber(item?.actualokpart),
              formatNumber(item?.loss),
            ],
          },
          piechart: {
            labels: ["Actual line efficiency", "Line efficiency loss"],
            values: [
              formatNumber(item?.actuallineefficiency),
              formatNumber(item?.lineefficiencyloss),
            ],
          },
        }))
      );
    } else {
      setData([defaultData]);
    }

    if (getReportServiceData?.error) {
      swal(
        "Oops!",
        getReportServiceData?.error?.response?.data || "Something went wrong.",
        "error"
      );
    }
  }, [getReportServiceData]);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);
    let lineproduction =
      JSON.parse(localStorage.getItem("lineproduction")) || {};
    lineproduction = {
      ...lineproduction,
      date: selectedDate,
    };
    if (model) {
      lineproduction.model = model;
      fetchData(model, selectedDate);
    }
    localStorage.setItem("lineproduction", JSON.stringify(lineproduction));
  };

  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    setModel(selectedModel);
    let lineproduction =
      JSON.parse(localStorage.getItem("lineproduction")) || {};
    lineproduction = {
      ...lineproduction,
      model: selectedModel,
    };
    if (date) {
      lineproduction.date = date;
      fetchData(selectedModel, date);
    }
    localStorage.setItem("lineproduction", JSON.stringify(lineproduction));
  };

  const lineproductiondate = {
    background: "#ffffff",
    height: "46px",
    width: "100%",
    borderRadius: "8px",
    padding: "0px 10px",
  };

  const LineProductionCard = () => (
    <Card
      sx={{
        backgroundColor: "#015883",
        color: "#ffffff",
        borderRadius: `${!auth && "0px"}`,
      }}
    >
      <Grid
        container
        spacing={isMidScreen ? 1 : 0}
        xs={isMidScreen ? 11 : 11.5}
        mt={isMidScreen ? 0 : 1.5}
        ml="auto"
        mr="auto"
        className="lineproductionparent"
      >
        <Grid item xs={12} md={3.05} className="lineproductiongrid">
          Today - {currentDateString}
        </Grid>
        <Grid
          item
          xs={12}
          md={2.9}
          className="lineproductiongrid lineproductiongridmodel"
        >
          Model
        </Grid>
        <Grid item xs={12} md={3.05} className="lineproductiongrid">
          Current Time - {currentTimeString}
        </Grid>
        <Grid container item xs={12} md={3} className="lineproductiongrid1">
          <Grid item xs={8.5} lg={9} className="lineproductiontable">
            Planned Downtime (Min.)
          </Grid>
          <Grid item xs={3.5} lg={3} className="lineproductiontable2">
            {productionData.length > 0
              ? productionData[0]?.shift?.planneddowntime
              : "NA"}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        xs={isMidScreen ? 11 : 11.6}
        mt={0}
        ml="auto"
        mr="auto"
        className="lineproductionparent"
      >
        <Grid item xs={12} md={3} className="lineproductiongrid">
          <MDDatePickerMod
            input={{ placeholder: "Select a date" }}
            value={date}
            onChange={handleDateChange}
            disabled={!auth && true}
          />
          {/* <Input
              sx={lineproductiondate}
              placeholder="Select Date"
              type="date"
              value={date}
              onChange={handleDateChange}
            /> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={isMidScreen ? 3 : 2.92}
          className="lineproductiongrid"
        >
          <select
            style={lineproductiondate}
            value={model}
            onChange={handleModelChange}
          >
            {bomcreationmaster?.length == 0 && (
              <option
                className="lineproductionoptions"
                value=""
                disabled
                selected
              >
                Select Model
              </option>
            )}
            {bomcreationmaster?.map((item, index) => (
              <option
                className="lineproductionoptions"
                value={item.modelno}
                key={index}
              >
                {item.modelno}
              </option>
            ))}
          </select>
        </Grid>
        <Grid
          item
          xs={12}
          md={window.location.pathname.includes("admin") ? 2.98 : 3}
          className="lineproductiongrid"
        >
          <Input
            sx={lineproductiondate}
            value={
              productionData.length > 0
                ? `${productionData[0]?.shift?.title.replace("-", " ")}(${
                    productionData[0]?.shift?.starttime
                  } - ${productionData[0]?.shift?.endtime})`
                : "Set Shift"
            }
            readOnly
          />
        </Grid>
        <Grid container item xs={12} md={3} className="lineproductiongrid1">
          <Grid item xs={8.5} lg={9} className="lineproductiontable">
            Available Time (Min.)
          </Grid>
          <Grid item xs={3.5} lg={3} className="lineproductiontable2">
            {productionData.length > 0
              ? productionData[0]?.shift?.availabletime
              : "NA"}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className="lineproductiongrid2"
        mt={3}
        pl={isMidScreen && 3}
        mb={2}
        pr={isMidScreen && 3}
      >
        {data.map((item, index) => (
          <Grid
            item
            xs={12}
            md={window.location.pathname.includes("admin") ? 5.6 : 5.65}
            key={index}
          >
            {getReportServiceData?.loading ? (
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Card>
            ) : (
              <LineProductionTable data={item} />
            )}
          </Grid>
        ))}
      </Grid>
    </Card>
  );

  return (
    <>
      {auth ? (
        <DashboardLayout>
          <DashboardNavbar />
          <LineProductionCard />
        </DashboardLayout>
      ) : (
        <LineProductionCard />
      )}
    </>
  );
};

export default LineProduction;
