/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "machine-form",
  formField: {
    machinecode: {
      name: "machinecode",
      label: "Machine Code",
      type: "text",
      errorMsg: "Machine Code is required.",
    },
    machinename: {
      name: "machinename",
      label: "Machine Name",
      type: "text",
      errorMsg: "Machine Name Is Required.",
    },
    machineDescrption: {
      name: "machineDescrption",
      label: "Machine Description",
      type: "text",
      errorMsg: "Machine Description is required.",
    },
    tags: {
      name: "tags",
      label: "Tags",
      type: "text",
      errorMsg: "Tags  Is Required.",
    },
  },
};

export default form;
