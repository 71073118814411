import axios from '../axiosinstance';

export const getApiResponse = async(url, data) =>{
    let resp ={}
    try {
        if(data === ""){
            resp = await axios.get(url);
        }else {
            resp = await axios.get(url, data);
        }
        return resp.data;
    } catch (error) {
        console.log(error," get Api response error" )
    }
};