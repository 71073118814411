import { useEffect, useRef} from "react";
import QRCodeComponent from "./QRCodeComponent";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import MDButton from "./MDButton";
import PrintIcon from "@mui/icons-material/Print";
import { useSelector } from "react-redux";

const PrintQRCode = ({ data,isprint,rowdata, title,stationcode }) => {

  const { filteredMessages, error } = useSelector(
    (state) => state.filteredMessages
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  useEffect(()=>{
    if(isprint == true)
handlePrint()
  },[])

  return (
    <Box style={{ textAlign: "center", display: "grid", placeItems: "center" }}>
      <Box
        id="QRCodeToPrint"
        ref={componentRef}
        style={{ textAlign: "center" }}
      >
        {data && <QRCodeComponent value={data && data} size={120} id="id-xyz" />}
        <Box fontSize={12}>{title}</Box>
        {/* <Box fontSize={12}>{data?.split("/").pop()}</Box> */}
      </Box>
      {(filteredMessages?.header?.stationcode || stationcode) === "LAMAST01" ?(
        <MDButton
        style={{ whiteSpace: "nowrap" }}
        size="small"
        color="success"
        variant="outlined"
        onClick={()=>handlePrint()}
        disabled={filteredMessages && filteredMessages?.status?.toString() !== "ok" ?
        false: true}
      
      >
     
        <PrintIcon />
        &nbsp; Print QR Code
      </MDButton>
      ):(
        <></>
      )}
     
    </Box>
  );
};

export default PrintQRCode;
