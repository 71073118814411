import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { FormControlLabel, Switch } from "@mui/material";
import { updateStation } from "../service/Station";

//edit icon
import { FaRegEdit } from "react-icons/fa";
import CreateStationPopup from "./CreateStationPopup";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { deleteStation, getStation } from "../service/Station";
import Stationedit from "./Stationedit";
import {
  deleteStationAssociation,
  getStationAssociation,
} from "../service/StationAssociation";
import { deleteCheckList, getCheckList } from "../service/Checklist";
import { element } from "prop-types";
import { getFile } from "../service/FileUpload";

function StationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { station, loading } = useSelector((state) => state.station);
  const { checklist, loading1 } = useSelector((state) => state.checklist);
  const[loader,setLoader]=useState(false)
  const { stationassociation, loading2 } = useSelector(
    (state) => state.stationassociation
  );
  const { fileupload, loading3 } = useSelector((state) => state.fileupload);
 
  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectfile, setSelectFile] = useState({});
  const [openpopupStation, setopenpopupStation] = useState(false);

async function editBrand(item) {
    setLoader(true);

    setopenpopup(true);

    // Fetch and set the file
    const response = await dispatch(getFile(item?.code));
    setSelectFile(response?.payload[0]);

    setSelectedStation(item);
    setLoader(false);
}

  const handleReworkChange = (checked, rowData) => {
    const updatedRowData = { ...rowData, rework: checked ? "true" : "false" };
    dispatch(updateStation(updatedRowData));
  };

  const handleSetupPartChange = (checked, rowData) => {
    const updatedRowData = { ...rowData, setuppart: checked ? "true" : "false" };
    dispatch(updateStation(updatedRowData)); 
  };
  const toggler = (checked, rowData) => {
    const updatedRowData = { ...rowData, bypass: checked ? "true" : "false" };
    dispatch(updateStation(updatedRowData)); 
  };
  // console.log("ffffff", selectfile);

  function onDelete(item) {
    item = {
      ...item,
      category: checklist
        ?.filter(
          (element) => element?.stationcode?.trim() === item?.code?.trim()
        )
        .map((element) => element.category),
      machine: stationassociation
        ?.filter(
          (element) => element?.stationcode?.trim() === item?.code?.trim()
        )
        .map((element) => element.machine),
    };
    console.log("del item", item);
    // Show a confirmation dialog using SweetAlert
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User clicked "Delete," proceed with deletion
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });

        // Dispatch the deleteBrand action
        dispatch(deleteStation(item))
          .then(() => {
            for (
              let i = 0;
              i <
              checklist?.filter(
                (element) => element?.stationcode?.trim() === item?.code?.trim()
              )?.length;
              i++
            ) {
              dispatch(
                deleteCheckList(
                  checklist?.filter(
                    (element) =>
                      element?.stationcode?.trim() === item?.code?.trim()
                  )[i]
                )
              );
            }

            for (
              let i = 0;
              i <
              stationassociation?.filter(
                (element) => element?.stationcode?.trim() === item?.code?.trim()
              )?.length;
              i++
            ) {
              dispatch(
                deleteStationAssociation(
                  stationassociation?.filter(
                    (element) =>
                      element?.stationcode?.trim() === item?.code?.trim()
                  )[i]
                )
              );
            }
            swal("Deleted!", "Your item has been deleted.", "success");
          })
          .catch((error) => {
            // Handle errors, display an error message, and close the Swal dialog
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        // User clicked "Cancel," do nothing
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getStation());
  }, []);

  useEffect(() => {
    dispatch(getCheckList());
    dispatch(getStationAssociation());
  }, [dispatch]);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  //  console.warn(station);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Card>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Station Master
                      </MDTypography>
                    </span>

                    <span
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                    >
                      <MDButton
                        color="success"
                        onClick={() => setopenpopupStation(true)}
                      >
                        ADD
                      </MDButton>
                    </span>
                  </div>
                </MDBox>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "station code", accessor: "code" },
                      { Header: "station name", accessor: "name" },


                      {
                        Header: "bypass",
                        accessor: "bypass",
                        Cell: ({ row }) => (
                          <Switch
                            checked={row?.original?.bypass === "true"}
                            onClick={(e) => toggler(e.target.checked, row.original)}
                          />
                        ),
                      },

                      {
                        Header: "rework",
                        accessor: "rework",

                        Cell: ({ row }) => (
                          <Switch
                            checked={row?.original?.rework === "true"}
                            onClick={(e) => handleReworkChange(e.target.checked, row.original)}
                          />
                        ),


                      },
                      {
                        Header: "setuppart",
                        accessor: "setuppart",

                        Cell: ({ row }) => (
                          <Switch
                            checked={row?.original?.setuppart === "true"}
                            onClick={(e) => handleSetupPartChange(e.target.checked, row.original)}
                          />
                        ),

                      },

                      {
                        Header: "total rework count",
                        accessor: "totalreworkcount",
                      },
                      {
                        Header: "Action",
                        accessor: "action",

                        Cell: ({ row }) => (
                          <div>
                            <Link to={{}}>
                              <FaRegEdit
                                style={iconStyleedit}
                                onClick={() => {
                                  editBrand(row.original);
                                }}
                              />
                            </Link>

                            <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(row.original);
                                }}
                              />
                            </Link>
                          </div>
                        ),
                      },
                    ],
                    rows: station || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      {/* { selectfile !== undefined && ( */}
        <Stationedit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Station Edit"}
          selectedStation={selectedStation}
          selectedFileGet={selectfile}
          loader={loader}
        />
      {/* )} */}

      <CreateStationPopup
        openpopupStation={openpopupStation}
        setopenpopupStation={setopenpopupStation}
      />
    </DashboardLayout>
  );
}

export default StationList;















































// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { MdDelete } from "react-icons/md";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";
// import { useDispatch, useSelector } from "react-redux";
// //import { brandList, deleteBrand } from "Services/Brand Service/Brand";
// import CircularProgress from "@mui/material/CircularProgress";
// import { FormControlLabel, Switch } from "@mui/material";
// import { updateStation } from "../service/Station";

// //edit icon
// import { FaRegEdit } from "react-icons/fa";
// // import { deleteStation, getStation } from "../service/Station";
// // import Stationedit from "./Stationedit";
// import CreateStationPopup from "./CreateStationPopup";
// import MDButton from "components/MDButton";
// import MDBox from "components/MDBox";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import { deleteStation, getStation } from "../service/Station";
// import Stationedit from "./Stationedit";
// import {
//   deleteStationAssociation,
//   getStationAssociation,
// } from "../service/StationAssociation";
// import { deleteCheckList, getCheckList } from "../service/Checklist";
// import { element } from "prop-types";
// import { getFile } from "../service/FileUpload";

// function StationList() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { station, loading } = useSelector((state) => state.station);
//   const { checklist, loading1 } = useSelector((state) => state.checklist);
//   const[loader,setLoader]=useState(false)
//   const { stationassociation, loading2 } = useSelector(
//     (state) => state.stationassociation
//   );
//   const { fileupload, loading3 } = useSelector((state) => state.fileupload);
 
//   // const [loading, setLoading] = useState(true);

//   const [openpopup, setopenpopup] = useState(false);
//   const [selectedStation, setSelectedStation] = useState(null);
//   const [selectchecklist, setSelectChecklist] = useState([]);

//   const [selectedpokayoka, setSelectedpokayoka] = useState([]);
  
//   const [selectmachinelist, setSelectMachineList] = useState([]);
//   const [selectfile, setSelectFile] = useState({});
//   const [openpopupStation, setopenpopupStation] = useState(false);

// //   async function editBrand(item) {
// //  setopenpopup(true);

// //     dispatch(getStationAssociation());
// //     dispatch(getCheckList());

// //     // setSelectChecklist(
// //     //   checklist.filter((i) => i?.stationcode === item?.code).map((i) => i)
// //     // );
// //     setSelectChecklist(
// //       checklist.filter((i) => i?.stationcode === item?.code && i?.category?.type === 'MACHINECHECKLIST')
// //     );
// //     setSelectedpokayoka(
// //       checklist.filter((i) => i?.stationcode === item?.code && i?.category?.type === 'POKAYOKE')
// //     );
   
   
// //     setSelectMachineList(
// //       stationassociation
// //         .filter((i) => i?.stationcode === item?.code)
// //         .map((i) => i)
// //     );
   

// //     // setSelectedpokayoka(
// //     //   pokayoke
// //     //     .filter((i) => i?.stationcode === item?.code)
// //     //     .map((i) => i)
// //     // );
// //     const response = await dispatch(getFile(item?.code));
// //     setSelectFile(response?.payload[0]);

// //     // setopenpopup(true);
// //     setSelectedStation(item);
// //   }

// async function editBrand(item) {
//   // try {
//     setLoader(true);

//     setopenpopup(true);

//     // Dispatch actions to get station association and checklist
//     // await Promise.all([
//       dispatch(getStationAssociation()),
//       dispatch(getCheckList()),
//     // ]);

//     // Filter and set checklists
//     setSelectChecklist(
//       checklist?.filter(
//         (i) => i?.stationcode === item?.code && i?.category?.type === 'MACHINECHECKLIST'
//       )
//     );

//     setSelectedpokayoka(
//       checklist?.filter(
//         (i) => i?.stationcode === item?.code && i?.category?.type === 'POKAYOKE'
//       )
//     );

//     // Filter and set station associations
//     setSelectMachineList(
//       stationassociation?.filter((i) => i?.stationcode === item?.code)
//     );

//     // Fetch and set the file
//     const response = await dispatch(getFile(item?.code));
//     setSelectFile(response?.payload[0]);

//     // Set the selected station
//     setSelectedStation(item);
//     setLoader(false);
//   // } catch (error) {
//   //   console.error("Error editing brand:", error);
//   // } finally {
//   //   setLoader(false); // Stop the loader
//   // }
// }

// console.log(openpopup, "opne popup 135")
// console.log(loader, "loader 136")
// // console.log(selectedStation, "selectedStation 137")
// console.log(selectchecklist, "selectchecklist")
// console.log(selectfile, "selectfile")

 

//   const handleReworkChange = (checked, rowData) => {
//     const updatedRowData = { ...rowData, rework: checked ? "true" : "false" };
//     dispatch(updateStation(updatedRowData));
//   };

//   const handleSetupPartChange = (checked, rowData) => {
//     const updatedRowData = { ...rowData, setuppart: checked ? "true" : "false" };
//     dispatch(updateStation(updatedRowData)); 
//   };
//   const toggler = (checked, rowData) => {
//     const updatedRowData = { ...rowData, bypass: checked ? "true" : "false" };
//     dispatch(updateStation(updatedRowData)); 
//   };
//   // console.log("ffffff", selectfile);

//   function onDelete(item) {
//     item = {
//       ...item,
//       category: checklist
//         ?.filter(
//           (element) => element?.stationcode?.trim() === item?.code?.trim()
//         )
//         .map((element) => element.category),
//       machine: stationassociation
//         ?.filter(
//           (element) => element?.stationcode?.trim() === item?.code?.trim()
//         )
//         .map((element) => element.machine),
//     };
//     console.log("del item", item);
//     // Show a confirmation dialog using SweetAlert
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         // User clicked "Delete," proceed with deletion
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });

//         // Dispatch the deleteBrand action
//         dispatch(deleteStation(item))
//           .then(() => {
//             for (
//               let i = 0;
//               i <
//               checklist?.filter(
//                 (element) => element?.stationcode?.trim() === item?.code?.trim()
//               )?.length;
//               i++
//             ) {
//               dispatch(
//                 deleteCheckList(
//                   checklist?.filter(
//                     (element) =>
//                       element?.stationcode?.trim() === item?.code?.trim()
//                   )[i]
//                 )
//               );
//             }

//             for (
//               let i = 0;
//               i <
//               stationassociation?.filter(
//                 (element) => element?.stationcode?.trim() === item?.code?.trim()
//               )?.length;
//               i++
//             ) {
//               dispatch(
//                 deleteStationAssociation(
//                   stationassociation?.filter(
//                     (element) =>
//                       element?.stationcode?.trim() === item?.code?.trim()
//                   )[i]
//                 )
//               );
//             }
//             swal("Deleted!", "Your item has been deleted.", "success");
//           })
//           .catch((error) => {
//             // Handle errors, display an error message, and close the Swal dialog
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         // User clicked "Cancel," do nothing
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   useEffect(() => {
//     dispatch(getStation());
//   }, []);

//   useEffect(() => {
//     dispatch(getCheckList());
//     dispatch(getStationAssociation());
//   }, [dispatch]);

//   const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
//   const iconStyleedit = {
//     fontSize: "1.5em",
//     color: "blue",
//     marginRight: "9px",
//   };
//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     flexDirection: "column",
//     marginRight: "10px",
//   };
//   //  console.warn(station);
//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox>
//         <MDBox>
//           <Card>
//             {loading ? (
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   height: "100vh",
//                 }}
//               >
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 <MDBox pl={3} pr={3} lineHeight={1}>
//                   <div style={addButtonStyle}>
//                     <span
//                       style={{
//                         alignSelf: "flex-start",
//                         marginTop: "20px",
//                       }}
//                     >
//                       <MDTypography variant="h5" fontWeight="medium">
//                         Station Master
//                       </MDTypography>
//                     </span>

//                     <span
//                       style={{
//                         alignSelf: "flex-end",
//                         marginRight: "10px",
//                         marginTop: "-23px",
//                         color: "#344767",
//                       }}
//                     >
//                       <MDButton
//                         color="success"
//                         onClick={() => setopenpopupStation(true)}
//                       >
//                         ADD
//                       </MDButton>
//                     </span>
//                   </div>
//                 </MDBox>
//                 <DataTable
//                   canSearch={true}
//                   table={{
//                     columns: [
//                       { Header: "station code", accessor: "code" },
//                       { Header: "station name", accessor: "name" },


//                       {
//                         Header: "bypass",
//                         accessor: "bypass",
//                         Cell: ({ row }) => (
//                           <Switch
//                             checked={row?.original?.bypass === "true"}
//                             onClick={(e) => toggler(e.target.checked, row.original)}
//                           />
//                         ),
//                       },

//                       {
//                         Header: "rework",
//                         accessor: "rework",

//                         Cell: ({ row }) => (
//                           <Switch
//                             checked={row?.original?.rework === "true"}
//                             onClick={(e) => handleReworkChange(e.target.checked, row.original)}
//                           />
//                         ),


//                       },
//                       {
//                         Header: "setuppart",
//                         accessor: "setuppart",

//                         Cell: ({ row }) => (
//                           <Switch
//                             checked={row?.original?.setuppart === "true"}
//                             onClick={(e) => handleSetupPartChange(e.target.checked, row.original)}
//                           />
//                         ),

//                       },

//                       {
//                         Header: "total rework count",
//                         accessor: "totalreworkcount",
//                       },
//                       {
//                         Header: "Action",
//                         accessor: "action",

//                         Cell: ({ row }) => (
//                           <div>
//                             <Link to={{}}>
//                               <FaRegEdit
//                                 style={iconStyleedit}
//                                 onClick={() => {
//                                   editBrand(row.original);
//                                 }}
//                               />
//                             </Link>

//                             <Link to={{}}>
//                               <MdDelete
//                                 style={iconStyle}
//                                 onClick={() => {
//                                   onDelete(row.original);
//                                 }}
//                               />
//                             </Link>
//                           </div>
//                         ),
//                       },
//                     ],
//                     rows: station || [],
//                   }}
//                 />
//               </>
//             )}
//           </Card>
//         </MDBox>
//       </MDBox>
//       {selectchecklist.length > 0 && selectfile !== undefined ? (
//         <Stationedit
//           openpopup={openpopup}
//           setopenpopup={setopenpopup}
//           title={"Station Edit"}
//           selectedStation={selectedStation}
//           selectedChecklist={selectchecklist}
//           selectedMachinelist={selectmachinelist}
//           selectedpokayoka={selectedpokayoka}
//           selectedFileGet={selectfile}
//           loader={loader}
//         />
//       ) : selectchecklist.length >= 0 ? (
//         <Stationedit
//           openpopup={openpopup}
//           setopenpopup={setopenpopup}
//           title={"Station Edit"}
//           selectedStation={selectedStation}
//           selectedChecklist={selectchecklist}
//           selectedMachinelist={selectmachinelist}
//           selectedpokayoka={selectedpokayoka}
//           loader={loader}
//         />
//       ) : (
//         <></>
//       )}

//       <CreateStationPopup
//         openpopupStation={openpopupStation}
//         setopenpopupStation={setopenpopupStation}
//       />
//     </DashboardLayout>
//   );
// }

// export default StationList;

