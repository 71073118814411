import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const getNoOfParts = createAsyncThunk(
  "getNoOfParts",
  async (data, { rejectWithValue }) => {

    try {
      const response = await axios.get(
       
        `visualization-service/api/visualization/v4/getNoOfParts?days=${data}`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//1 Graph api=================