import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataTable from "react-data-table-component";
import { Button } from "@mui/material";


// Helper function to get current time
const getCurrentTime = () => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

function ShiftInfo({ formData }) {
  const [downTimeData, setDownTimeData] = useState({
    name: "",
    from: "",
    to: "",
    downtime: ""
  });
  
  // const [downTimes, setDownTimes] = useState([]);

  const { formField, values, errors, touched, downTimeField, downTimes, setDownTimes  } = formData;
  const { shiftName, starttime, endtime, planneddowntime } = formField;
  const { name, from, to, downtime } = downTimeField;
  let { shiftName: shiftNameV, starttime: starttimeV, endtime: endtimeV, planneddowntime: planneddowntimeV } = values;

  useEffect(() => {
    if (!starttimeV) values.starttime = getCurrentTime();
    if (!endtimeV) values.endtime = getCurrentTime();
  }, [values, starttimeV, endtimeV]);
 
  const columns = [
    { name: "Name", selector: row => row.name, center: true },
    { name: "From", selector: row => row.from, center: true },
    { name: "To", selector: row => row.to, center: true },
    { name: "Downtime (in minutes)", selector: row => row.downtime, center: true },
    {
      name: "Action",
      cell: (row) => (
        <Button
      
          style={{color:'red'}}
          onClick={() => removedata(row)}
        >
          X
        </Button>
      ),
      center: true,
    },
  ];
  const removedata = (row) => {
    // Filter out the row to be removed
    const updatedData = downTimes.filter((item) => item !== row);
    setDownTimes(updatedData);
  };
  useEffect(() => {
    if (downTimeData.from && downTimeData.to) {
      const fromTime = new Date(`1970-01-01T${downTimeData.from}:00`);
      let toTime = new Date(`1970-01-01T${downTimeData.to}:00`);
      
      // if (toTime > fromTime) {
      //   // Calculate downtime in minutes
      //   const downtimeMinutes = Math.round((toTime - fromTime) / (1000 * 60));
      //   setDownTimeData((prevData) => ({ ...prevData, downtime: downtimeMinutes.toString() }));
      // } else {
      //   setDownTimeData((prevData) => ({ ...prevData, downtime: "" }));
      // }
      if (toTime > fromTime) {
        // Calculate downtime in minutes
        const downtimeMinutes = Math.round((toTime - fromTime) / (1000 * 60));
        setDownTimeData((prevData) => ({ ...prevData, downtime: downtimeMinutes.toString() }));
      } else {
        toTime = new Date(`1970-01-02T${downTimeData.to}:00`);
        const downtimeMinutes = Math.round((toTime - fromTime) / (1000 * 60));
        setDownTimeData((prevData) => ({ ...prevData, downtime: downtimeMinutes.toString() }));
      }
    }
  }, [downTimeData.from, downTimeData.to]);

  // const handleAddDownTime = () => {
  //   setDownTimes([...downTimes, { ...downTimeData }]);
  //   setDownTimeData({ name: "", from: "", to: "", downtime: "" });
  // };
  const handleAddDownTime = () => {
    const { name, from, to, downtime } = downTimeData;

    // Validate that all fields are filled
    if (!name || !from || !to || !downtime) {
      swal("Error", "Please fill in all fields before adding.", "error");
      return;
    }

    // const fromTime = new Date(`1970-01-01T${from}:00`);
    // const toTime = new Date(`1970-01-01T${to}:00`);
    // if (toTime <= fromTime) {
    //   swal("Error", "'To' time should be greater than 'From' time.", "error");
    //   return;
    // }

    // Add downtime entry
    setDownTimes([...downTimes, { ...downTimeData }]);
    setDownTimeData({ name: "", from: "", to: "", downtime: "" });
  };

  // Render the component
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Shift Master</MDTypography>
        <MDTypography variant="button" color="text">Mandatory information</MDTypography>
      </MDBox>

      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={shiftName.type}
              label={<span>{shiftName.label}<sup style={{ color: "red", fontSize: "small" }}> *</sup></span>}
              name={shiftName.name}
              value={shiftNameV}
              error={errors.shiftName && touched.shiftName}
              success={shiftNameV.length > 0 && !errors.shiftName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={starttime.type}
              label={starttime.label}
              name={starttime.name}
              value={starttimeV}
              success={starttimeV.length > 0 && !errors.starttime}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={endtime.type}
              label={endtime.label}
              name={endtime.name}
              value={endtimeV}
              success={endtimeV.length > 0 && !errors.endtime}
            />
          </Grid>
        </Grid>

        {/* Down Time Section */}
        <MDBox lineHeight={2}>
          <MDTypography variant="h6">Down Time</MDTypography>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormField
              type={name.type}
              label={<span>{name.label}<sup style={{ color: "red", fontSize: "small" }}> *</sup></span>}
              name={name.name}
              value={downTimeData.name}
              onChange={(e) => setDownTimeData({ ...downTimeData, name: e.target.value })}
              error={errors.name && touched.name}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              type={from.type}
              label={from.label}
              name={from.name}
              value={downTimeData.from}
              onChange={(e) => setDownTimeData({ ...downTimeData, from: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              type={to.type}
              label={to.label}
              name={to.name}
              value={downTimeData.to}
              onChange={(e) => setDownTimeData({ ...downTimeData, to: e.target.value })}
              // onChange={(e) => {
              //   const toValue = e.target.value;
              //   const fromValue = downTimeData.from;
                
              //   if (fromValue && new Date(`1970-01-01T${toValue}:00`) <= new Date(`1970-01-01T${fromValue}:00`)) {
              //     swal("Error", "'To' time should be greater than 'From' time.", "error");
              //   } else {
              //     setDownTimeData({ ...downTimeData, to: toValue });
              //   }
              // }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <FormField
              type={downtime.type}
              label={downtime.label}
              name={downtime.name}
              value={downTimeData.downtime}
              
              onChange={(e) => setDownTimeData({ ...downTimeData, downtime: e.target.value })}
            /> */}
               <FormField
              type="number"
              label={downtime.label}
              name={downtime.name}
              value={downTimeData.downtime}
              onChange={(e) => setDownTimeData({ ...downTimeData, downtime: e.target.value })}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDButton variant="gradient" color="light" onClick={handleAddDownTime}>ADD</MDButton>
          </Grid>
        </Grid>
{/* 
        <MDBox mt={3}>
          <TableContainer component={Paper}>
            <Table aria-label="downTime table">
              <TableHead style={{width:"100%"}}>
                <TableRow>
                  <TableCell style={{ textAlign: "center", width: "25%" }}>Name</TableCell>
                  <TableCell style={{ textAlign: "center", width: "25%" }}>From</TableCell>
                  <TableCell style={{ textAlign: "center", width: "25%" }}>To</TableCell>
                  <TableCell style={{ textAlign: "center", width: "25%" }}>Downtime (in minutes)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {downTimes?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ textAlign: "center", width: "25%" }}>{item.name}</TableCell>
                    <TableCell style={{ textAlign: "center", width: "25%" }}>{item.from}</TableCell>
                    <TableCell style={{ textAlign: "center", width: "25%" }}>{item.to}</TableCell>
                    <TableCell style={{ textAlign: "center", width: "25%" }}>{item.downtime}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MDBox> */}
         <MDBox mt={3} component={Paper}>
          <DataTable
            columns={columns}
            data={downTimes}
            pagination
            responsive
            highlightOnHover
            noDataComponent={<div style={{ padding: "20px", textAlign: "center" }}>No down time. </div>}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

ShiftInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ShiftInfo;
























// import PropTypes from "prop-types";
// import * as React from "react";

// // @mui/material components
// import Grid from "@mui/material/Grid";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// // vitals page components
// import FormField from "../FormField";

// import { useEffect, useState } from "react";

// import { BarLoader } from "react-spinners";
// import MDButton from "components/MDButton";

// // Get the current time in the format HH:MM
// const getCurrentTime = () => {
//   const date = new Date();
//   const hours = String(date.getHours()).padStart(2, '0');
//   const minutes = String(date.getMinutes()).padStart(2, '0');
//   return `${hours}:${minutes}`;
// };

// function ShiftInfo({ formData }) {
//   const [loading, setLoading] = useState(false);
//   const [color] = useState("#344767");
//   const [downTimeData, setDownTimeData] = useState({
//     name: "",
//     from: "",
//     to: "",
//     downTime: ""
//   });

//   const { formField, values, errors, touched, downTimeField } = formData;

//   const { shiftName, starttime, endtime, planneddowntime } = formField;
//   const { name, from, to, downtime } = downTimeField;
//   var { shiftName: shiftNameV, starttime: starttimeV, endtime: endtimeV, planneddowntime: planneddowntimeV } = values;

//   // Set default value for starttime and endtime if they are empty
//   useEffect(() => {
//     if (!starttimeV) {
//       values.starttime = getCurrentTime();
//       starttimeV = values.starttime;
//     }
//     if (!endtimeV) {
//       values.endtime = getCurrentTime();
//       endtimeV = values.endtime;
//     }
//   }, [values, starttimeV, endtimeV]);

//   return (
//     <MDBox>
//       <MDBox lineHeight={0}>
//         <MDTypography variant="h5">Shift Master</MDTypography>
//         <MDTypography variant="button" color="text">
//           Mandatory informations
//         </MDTypography>
//       </MDBox>

//       <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

//       {!loading && (
//         <MDBox mt={1.625}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <FormField
//                 type={shiftName.type}
//                 label={<span>{shiftName.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
//                 name={shiftName.name}
//                 value={shiftNameV}
//                 placeholder={shiftName.placeholder}
//                 error={errors.shiftName && touched.shiftName}
//                 success={shiftNameV.length > 0 && !errors.shiftName}
//               />
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <FormField
//                 type={starttime.type}
//                 label={starttime.label}
//                 name={starttime.name}
//                 value={starttimeV}
//                 placeholder={starttime.placeholder}
//                 success={starttimeV.length > 0 && !errors.starttime}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormField
//                 type={endtime.type}
//                 label={endtime.label}
//                 name={endtime.name}
//                 value={endtimeV}
//                 placeholder={endtime.placeholder}
//                 success={endtimeV.length > 0 && !errors.endtime}
//               />
//             </Grid>
//             {/* <Grid item xs={12} sm={6}>
//               <FormField
//                 type={planneddowntime.type}
//                 label={planneddowntime.label}
//                 name={planneddowntime.name}
//                 value={planneddowntimeV}
//                 placeholder={planneddowntime.placeholder}
//                 success={planneddowntimeV.length > 0 && !errors.planneddowntime}
//               />
//             </Grid> */}
//           </Grid>

//           <MDBox lineHeight={2}>
//             <MDTypography variant="h6">Down Time</MDTypography>
//           </MDBox>

//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={3}>
//               <FormField
//                 type={name.type}
//                 label={<span>{name.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
//                 name={name.name}
//                 value={downTimeData.name}
//                 placeholder={name.placeholder}
//                 error={errors.name && touched.name}
//                 success={downTimeData.name && !errors.name}
//               />
//             </Grid>

//             <Grid item xs={12} sm={3}>
//               <FormField
//                 type={from.type}
//                 label={from.label}
//                 name={from.name}
//                 value={downTimeData.from}
//                 placeholder={from.placeholder}
//                 success={downTimeData.from && !errors.starttime}
//               />
//             </Grid>
//             <Grid item xs={12} sm={3}>
//               <FormField
//                 type={to.type}
//                 label={to.label}
//                 name={to.name}
//                 value={downTimeData.to}
//                 placeholder={to.placeholder}
//                 success={downTimeData.to && !errors.endtime}
//               />
//             </Grid>

//             <Grid item xs={12} sm={3}>
//               <FormField
//                 type={downtime.type}
//                 label={downtime.label}
//                 name={downtime.name}
//                 value={downTimeData.downTime}
//                 placeholder={downtime.placeholder}
//                 success={downTimeData.downTime && !errors.endtime}
//               />
//             </Grid>

//             <Grid item xs={12} sm={3}>
//               <MDButton
//                 variant="gradient"
//                 color="light"
//               // onClick={handleBack}
//               >
//                 ADD
//               </MDButton>
//             </Grid>

//           </Grid>
//         </MDBox>
//       )}
//     </MDBox>
//   );
// }

// // typechecking props for ShiftInfo
// ShiftInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

// export default ShiftInfo;
