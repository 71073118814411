import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const fileUpload = createAsyncThunk("fileUpload", async (data, {rejectWithValue}) => {
    console.log("formData", data);
    try {
      const response = await axios.post(
        `new-upload-service-mongo/api/attachment/upload`,
        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });
  
  export const getFile = createAsyncThunk("getFile", async (data, {rejectWithValue}) => {
   console.log("apidata",data)
   
    try {
      const response = await axios.get(
       
        `new-upload-service-mongo/api/attachment/getAll/code/${data}`
       
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });
  export const deleteFile = createAsyncThunk("deleteFile", async (data, {rejectWithValue}) => {
    console.log("formData", data);
  
    try {
      const response = await axios.delete(
        `new-upload-service-mongo/api/attachment/delete/${data}`,
        data
       
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });