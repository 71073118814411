// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   operatorCode: null,
//   // Add other initial state properties as needed
// };

// export const codeDetail = createSlice({
//   name: "codeDetail",
//   initialState,
//   reducers: {
//     setOperatorCode: (state, action) => {
//       state.operatorCode = action.payload;
//     },
//     // Add other reducer functions as needed
//   },
// });

// export const { setOperatorCode } = codeDetail.actions;

// export default codeDetail.reducer;
// codeDetailSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operatorCode: localStorage.getItem("operatorCode") || null,
  // Add other initial state properties as needed
};

const codeDetailSlice = createSlice({
  name: "codeDetail",
  initialState,
  reducers: {
    setOperatorCode: (state, action) => {
      // Update the state with the action payload
      state.operatorCode = action.payload;
      // Also store it in localStorage for persistence
      localStorage.setItem("operatorCode", action.payload);
    },
    // Add other reducer functions as needed
  },
});

export const { setOperatorCode } = codeDetailSlice.actions;
export default codeDetailSlice.reducer;
