import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Stomp from "stompjs";
import SockJS from "sockjs-client";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { setFilteredMessages } from "app/webSocketUI/reducer/filteredMessagesSlice";
import { useDispatch } from "react-redux";
import { setGraph1Messages } from "app/analytics/reducer/graph1MessagesSlice";
import { setGraph3Messages } from "app/analytics/reducer/graph3MessagesSlice";
import Steps from "layouts/pages/widgets/components/Steps";
import { setProductionMessage } from "redux/slices/getProductionMessage";
import { resetReportServiceList } from "redux/slices/getReportServiceData";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [stompClient, setStompClient] = useState(null);
  const { operatorCode } = useSelector((state) => state.operatorCode);
  const [messages, setMessages] = useState([]);
  const [graph1message, setGraph1Message] = useState([]);
  const [graph3message, setGraph3Message] = useState([]);
  const [daycoDashboardMessage, setDaycoDashboardMessage] = useState([]);
  const Dispatch = useDispatch();

  useEffect(() => {
    const connectWebSocket = () => {
      const socket = new SockJS("https://chat.softwaremathematics.com/ws");
      const client = Stomp.over(socket);

      client.connect({}, () => {
        client.subscribe("/topic/messages", (message) => {
          let receivedMessage = JSON.parse(message.body);
          setMessages(receivedMessage);
        });
        client.subscribe("/topic/firstgraph", (message) => {
          let graph1 = JSON.parse(message.body);
          setGraph1Message(graph1);
        });
        client.subscribe("/topic/thirdgraph", (message) => {
          let graph3 = JSON.parse(message.body);
          setGraph3Message(graph3);
        });
        client.subscribe("/topic/daycodashboard", (message) => {
          let daycodashmessage = JSON.parse(message.body);
          setDaycoDashboardMessage(daycodashmessage);
        });
      });

      setStompClient(client);
      console.log = () => {};
    };

    // if (localStorage.getItem("profile-Name") !== null) {
    //   connectWebSocket();
    // }

    // return () => {

    //   if (!localStorage.getItem("profile-Name")) {
    //     stompClient?.disconnect();
    //   }
    // };

    // }, [localStorage.getItem("profile-Name")]);
    connectWebSocket();
  }, []);

  useEffect(() => {
    if (Object.keys(messages).length > 0) {
      if (
        JSON.parse(messages?.content)?.header?.stationcode?.toUpperCase() ===
        localStorage.getItem("operatorCode")?.toUpperCase()
      ) {
        Dispatch(setFilteredMessages(JSON.parse(messages?.content)));
      }
    }
  }, [messages, localStorage.getItem("operatorCode")]);

  useEffect(() => {
    if (Object.keys(graph1message).length > 0) {
      Dispatch(setGraph1Messages(JSON.parse(graph1message?.content)));
    }
  }, [graph1message]);

  useEffect(() => {
    if (Object.keys(graph3message).length > 0) {
      Dispatch(setGraph3Messages(JSON.parse(graph3message?.content)));
    }
  }, [graph3message]);

  useEffect(() => {
    if (Object.keys(daycoDashboardMessage).length > 0) {
      Dispatch(
        setProductionMessage(JSON.parse(daycoDashboardMessage?.content))
      );
    }
  }, [daycoDashboardMessage]);

  // Accessing and logging the "code" query parameter
  const codeParam = queryParams.get("code");

  const [router, setRouter] = useState(routes);

  const settingusername = () => {
    if (routes !== null) {
      routes
        .filter((item) => item.key === "brooklyn-alice")
        .map((item) => {
          item.name = localStorage.getItem("profile-Name");
        });
      setRouter(routes);
    }
  };

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);

  //  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (
      !isAuthenticated &&
      !pathname.includes("/app/line-production") &&
      !pathname.includes("/app/OEE")
    ) {
      navigate(`/authentication/sign-in/basic?code=${codeParam}`);

      // settingusername()
    }
    if (
      pathname.includes("/app/line-production") ||
      pathname.includes("/app/OEE")
    ) {
      Dispatch({ type: "LOGOUT_FULFILLED" });
      Dispatch(resetReportServiceList());
    }
  }, [isAuthenticated, pathname, navigate]);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Material Dashboard PRO"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route
            path="*"
            element={<Navigate to="/pages/profile/profile-overview" />}
          />
          {/* <Route path="/app/Steps/steps-ui/:id/:name" element={<Steps />} /> */}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {localStorage.getItem("profile-Name") != null && (
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="DAYCO"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}

          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route
          path="*"
          element={<Navigate to="/pages/profile/profile-overview" />}
        />
        {/* <Route path="/app/Steps/steps-ui/:id/:name" element={<Steps />} /> */}
      </Routes>
    </ThemeProvider>
  );
}

// useEffect(() => {
//   console.log("Inside useEffect");
//   const storedUser = localStorage.getItem("authenticatedUser");

//   if (!isAuthenticated &&
//     !storedUser && !pathname.includes("/app/ForgotPassword/forgot-password")&&
//   !pathname.includes("/app/newregistration/registration") ) {
//     console.log("Redirecting to sign-in page");
//     navigate("/authentication/sign-in/basic");
//   }
//   // Additional checks or actions as needed
// }, [isAuthenticated, pathname, navigate]);
