const form = {
    formId: "new-configuration-form",
    formField: {
      Sequence: {
        name: "Sequence",
        label: "Sequence",
        type: "text",
        errorMsg: "Sequence is required.",
      },
      readPath: {
        name: "readPath",
        label: "Read Path",
        type: "text",
        errorMsg: "Read Path is required.",
      },
      readFileName: {
        name: "readFileName",
        label: "Read File Name",
        type: "text",
        errorMsg: "Read File Name is required.",
      },
      writePath: {
        name: "writePath",
        label: "Write Path",
        type: "text",
        errorMsg: "Write Path is required.",
      },
      writeFileName: {
        name: "writeFileName",
        label: "Write File Name",
        type: "text",
        errorMsg: "Write File Name is required.",
      },
      keyToCheck: {
        name: "keyToCheck",
        label: "Key To Check",
        type: "text",
        errorMsg: "Key To Check is required.",
      },
      statusOut: {
        name: "statusOut",
        label: "Status Out",
        type: "text",
        errorMsg: "Status Out is required.",
      },
      uri: {
        name: "uri",
        label: "URI",
        type: "text",
        errorMsg: "Uri is required.",
      },
      uriMethodType: {
        name: "uriMethodType",
        label: "URI Method Type",
        type: "text",
        errorMsg: "Uri Method Type is required.",
      },
      param: {
        name: "param",
        label: "Param",
        type: "text",
        errorMsg: "Param is required.",
      },
      // status: {
      //   name: "status",
      //   label: "Status",
      //   type: "text",
      //   errorMsg: "Status is required.",
      // },
      archiveLoc: {
        name: "archiveLoc",
        label: "Archive Loc",
        type: "text",
        errorMsg: "Archive Loc is required.",
      },
      delay: {
        name: "delay",
        label: "Delay",
        type: "text",
        errorMsg: "Delay is required.",
      },
      outProp: {
        name: "outProp",
        label: "Out Prop",
        type: "text",
        errorMsg: "Out Prop is required.",
      },
      statusMap: {
        name: "statusMap",
        label: "Status Map",
        type: "text",
        errorMsg: "Status Map is required.",
      },
      // notFound: {
      //   name: "notFound",
      //   label: "Not Found",
      //   type: "text",
      //   errorMsg: "Not Found is required.",
      // },
      listMap: {
        name: "listMap",
        label: "List Map",
        type: "text",
        errorMsg: "List Map is required.",
      },
      mapObject: {
        name: "mapObject",
        label: "Map Object",
        type: "text",
        errorMsg: " Map Object is required.",
      },
      listMapKeys: {
        name: "listMapKeys",
        label: "List Map Keys",
        type: "text",
        errorMsg: "List Map Keys is required.",
      },
      stationCode: {
        name: "stationCode",
        label: "Station Code",
        type: "text",
        errorMsg: "Station Code is required.",
      },
      stationName: {
        name: "stationName",
        label: "Station Name",
        type: "text",
        errorMsg: "Station Name is required.",
      },
      arcreq: {
        name: "arcreq",
        label: "ARCREQ",
        type: "text",
        errorMsg: "Arcreq is required.",
      },
      // coparc: {
      //   name: "coparc",
      //   label: "COPARC",
      //   type: "text",
      //   errorMsg: "Coparc is required.",
      // },
      cleancsv: {
        name: "cleancsv",
        label: "CLEAN CSV",
        type: "text",
        errorMsg: "CLEAMCSV is required.",
      },
      fileType: {
        name: "fileType",
        label: "File Type",
        type: "text",
        errorMsg: "File Type is required.",
      },
      startLine: {
        name: "startLine",
        label: "Start Line",
        type: "text",
        errorMsg: "Start Line is required.",
      },
      variablePackage: {
        name: "variablePackage",
        label: "Variable Package",
        type: "text",
        errorMsg: "Variable Package is required.",
      },
      w_variablePackage: {
        name: "w_variablePackage",
        label: "W Variable Package",
        type: "text",
        errorMsg: "W Variable Package is required.",
      },
      // dcflag: {
      //   name: "dcflag",
      //   label: "DC Flag",
      //   type: "text",
      //   errorMsg: "DC Flag Package is required.",
      // },
      wcfilter: {
        name: "wcfilter",
        label: "WC Filter",
        type: "text",
        errorMsg: "WC Filter Package is required.",
      },
  
    
    },
  };
  
  export default form;
  
  