import { createSlice } from "@reduxjs/toolkit";
import { getAttributeVariationbypart } from "../service/Attributebypart";

const initialState = {
  attributevariationpart: [],
  loading: false,
  error: null,
};

export const attributevariationDetail = createSlice({
  name: "attributevariationDetail",
  initialState,
  extraReducers: {
    [getAttributeVariationbypart.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAttributeVariationbypart.fulfilled]: (state, action) => {
      state.loading = false;
      state.attributevariationpart = action.payload;
    },
    [getAttributeVariationbypart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default attributevariationDetail.reducer;
///4 graph reducers
