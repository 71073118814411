import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const getPartName = createAsyncThunk(
  "getPartName",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `itemmaster-service/api/PartStatus/v2/getPartName?stationCode=${data}`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
