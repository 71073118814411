// ErrorCard.js

import React from 'react';

const Errorcard = ({ error }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '16px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
      
          width: '100%',
          textAlign: 'center',
        }}
      >
        <img
          src="https://aliceblueonline.com/wp-content/uploads/2022/10/Form-Caricature.png"
          alt="Error Illustration"
          style={{ maxWidth: '90%' }}
        />
         <p style={{ fontSize: '1.2em', fontWeight: 'bold' ,marginBottom:"20%"} }>{error}</p>
      </div>
    </div>
  );
};




export default Errorcard;