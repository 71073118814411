import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role
 
 
export const getProductdetail = createAsyncThunk(
  "getProductdetail",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);
 
    try {
      if (data !== undefined) {
        const response = await axios.get(
          `/itemmaster-service/api/itemMaster/v1/get?stationcode=${data.stationcode}&ispageable=true&page=${data.page}&size=${data.size}&st=${data.query}&type=PRODUCT`
         
        );
        return await response.data;
      }
      
      
      else {
        const response = await axios.get(
          `/itemmaster-service/api/itemMaster/v1/get`
        );
        return await response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
