/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

function MDDatePickerMod({ value, input, onChange, disabled, ...rest }) {
  const parsedValue = value ? new Date(value) : new Date();

  const handleInputClick = (event) => {
    if (disabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <Flatpickr
      {...rest}
      options={{ dateFormat: "d-m-Y" }}
      value={parsedValue}
      onChange={(date) => {
        const selectedDate = date[0] ? date[0].toLocaleDateString('en-GB') : '';
        const [day, month, year] = selectedDate.split('/');
        const formattedDate = `${year}-${month}-${day}`;
        onChange({ target: { value: formattedDate } });
      }}
      render={({ defaultValue }, ref) => (
        <MDInput
          {...input}
          defaultValue={defaultValue}
          placeholder="Select Date"
          inputRef={ref}
          sx={{
            background: "#ffffff",
            width: "100%",
            borderRadius: "8px",
            cursor: disabled ? "not-allowed" : "pointer",
            pointerEvents: disabled ? "none" : "auto",
          }}
          onClick={handleInputClick}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

// Setting default values for the props of MDDatePickerMod
MDDatePickerMod.defaultProps = {
  input: {},
  disabled: false, // Default disabled prop value
};

// Typechecking props for the MDDatePickerMod
MDDatePickerMod.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool, // Typechecking for disabled prop
};

export default MDDatePickerMod;