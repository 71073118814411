import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField: { type, name, displayname, uomsymbol, modelno, stationcode, processid},
} = checkout;

const validations = [
  Yup.object().shape({
    [type.name]: Yup.string().required(type.errorMsg),
    [name.name]: Yup.string().required(name.errorMsg),
    [displayname.name]: Yup.string().required(displayname.errorMsg),
    [uomsymbol.name]: Yup.string().required(uomsymbol.errorMsg),
    [modelno.name]: Yup.string().required(modelno.errorMsg),
    // [machinecode.name]: Yup.string().required(machinecode.errorMsg),
    [stationcode.name]: Yup.string().when([type.name], {
      is: "MACHINE",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(stationcode.errorMsg),
    }),
    [processid.name]: Yup.string().when([type.name], {
      is: (val) => val === "MACHINE" || val === "STATION",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(processid.errorMsg),
    }),
  }),
];

export default validations;
