import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import "./upload.css"; // CSS file for styling
import swal from "sweetalert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FileUpload from "react-material-file-upload";
import axios from "../../axiosinstance";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import { fileUpload } from "app/stationMaster/service/FileUpload";
import { MoonLoader } from "react-spinners";
import MDInput from "components/MDInput";

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  // category: Yup.string().required("Category is required"),

  station: Yup.string().required("Station is required"),
});
const UploadDocument = (props) => {
  const { tittle, uploadpopup, setUploadpopup, getData } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const { station } = useSelector((state) => state.station);
  const { error } = useSelector((state) => state.fileupload);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [files, setFiles] = useState([]);

  const [submitFlag, setSubmitFlag] = useState(false);
  const [finalSubmitLoading, setFinalSubmitLoading] = useState(false);

  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("login-auth"))
  );

  // file upload function===========
  function onfileChange(e) {
    console.log(e);
    setFiles(e);
    setSubmitFlag(true);
  }

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //handle submit===================
  const handleSubmit = async (values) => {
    setFinalSubmitLoading(true);
    const formData = new FormData();

    // Update the formData object
    formData.append("file", files[0]);
    const request = {
      resourcecode: "DAYCO",
      username: auth.firstname + " " + auth.lastname,

      doctype: values.category,
      description: values.description,

      code: values.station,
    };
    const myJSON = JSON.stringify(request);
    formData.append("body", myJSON);

    const response = await dispatch(fileUpload(formData));

    // if (response && response.status === 200) {
    //   swal('Uploaded Successful', { icon: 'success' });
    //   getData();
    //   handleClose();
    // } else {
    //   swal('Something wants wrong', { icon: 'error' });
    //   handleClose();
    // }
    if (!response.error) {
      swal("Good job!", "Suessfully Done", "success");
      handleClose();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: error?.message,
      });

      handleClose();
    }

    handleClose();
  };
  // close pop-up function=================
  const handleClose = () => {
    setFiles([]);
    setSubmitFlag(false);
    setUploadpopup(false);
    setFinalSubmitLoading(false);
  };

  return (
    <Dialog open={uploadpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {tittle}
      </DialogTitle>
      <DialogContent>
        <div className="form-container">
          <Formik
            initialValues={{
              name: "",
              description: "",
              category: "",
              station: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <Field
                  as={MDInput}
                  label="Type here..."
                  multiline
                  rows={2}
                  name="description"
                  id="description"
                  className="form-input"
                  style={{ width: "100%" }}
                />
                <ErrorMessage
                  name="description"
                  component="span"
                  className="error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="station">Station:</label>
                <Field
                  as="select"
                  id="station"
                  name="station"
                  className="form-input"
                >
                  <option value="">Select Station</option>

                  {station.map((option, index) => (
                    <option key={option.id} value={option.code}>
                      {option.name} ( {option.code} )
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="station"
                  component="span"
                  className="error"
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="category">Category:</label>
                <Field
                  as="select"
                  id="category"
                  name="category"
                  className="form-input"
                >
                  <option value="">Select a category</option>
                  <option value="Lab Test">Lab Test</option>
                  <option value="Prescription">Prescription</option>
                  <option value="General">General</option>
                </Field>
                <ErrorMessage
                  name="category"
                  component="span"
                  className="error"
                />
              </div> */}

              <MDBox
                className="Upload"
                style={{
                  marginRight: "0px",
                  marginLeft: "0px",
                  marginTop: "10px",
                }}
              >
                <h5 style={{ margin: "1px", textAlign: "center" }}>
                  Please Select the file from Your System which you want to
                  upload
                </h5>
                <FileUpload
                  buttonText={<span style={{ color: "white" }}>Upload</span>}
                  value={files}
                  onChange={onfileChange}
                />
                {/* {!loading && ( */}
                <MDButton
                  disabled={!submitFlag}
                  style={{
                    position: "relative",
                    left:
                      windowWidth < 400
                        ? "65%"
                        : windowWidth < 550
                        ? "73%"
                        : "80%",
                    marginTop: 20,
                  }}
                  type="submit"
                  variant="gradient"
                  color="dark"
                  size="small"
                >
                  {finalSubmitLoading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "submit"
                  )}
                </MDButton>

                {/* )} */}
              </MDBox>
            </Form>
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDocument;
