import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
 
  TextField,
  CircularProgress,
} from "@mui/material";
import MDInput from "components/MDInput";

import swal from "sweetalert";
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPartProcessed } from "./service/Partprocessed";
import { updateNoOfPartProcessed } from "./reducer/partprocessedDetailSlice";

function PartsProcessed({
  color,
  title,
  description,
 
  chart,
  datemargin,
}) {
  const dispatch = useDispatch();
  const { partprocessed, loading, error } = useSelector(
    (state) => state.partprocessed
  );
  const { graph1Messages, loading1, error1 } = useSelector(
    (state) => state.graph1Messages
  );
  const [analytic2message, setAnalytic2Message] = useState([]);
  const [datanew, setDataNew] = useState([]);
  const [labelsnew, setLabelsNew] = useState([]);

  const date = localStorage.getItem("part-processed")?.replace(/"/g, "");
//   const [selectedDate, setSelectedDate] = useState(date?date:new Date());
//   console.log("seleheheh",selectedDate)
const [selectedDate, setSelectedDate] = useState(date instanceof Date ? date : new Date());

const formatDate = (date) => {
  if (!date) {
    return "";
  }

  const currentDate = date instanceof Date ? date : new Date(date);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

  useEffect(() => {
    if (datanew.length > 0) {
      dispatch(getPartProcessed(formatDate(selectedDate)));
    }
  }, [datanew]);
  
  useEffect(() => {
    if (
      graph1Messages?.recstatus == "OK" && new Date(graph1Messages?.createddate).toISOString().split("T")[0]==new Date(selectedDate).toISOString().split('T')[0]
    ) {
      setAnalytic2Message(graph1Messages);
    }
  }, [graph1Messages]);
 
  const { data, options } = configs(
    labelsnew.length > 0 ? labelsnew : partprocessed.labels || [],
    datanew.length > 0
      ? { ...partprocessed?.datasets, data: datanew }
      : partprocessed.datasets || {}
  );

  const handleDateChange = async (event) => {
    setSelectedDate(event?.target?.value);
    localStorage.setItem("part-processed", JSON.stringify(event?.target?.value));

    const response = await dispatch(getPartProcessed(event?.target?.value));

    if (response.error && !loading) {
      swal({
        icon: "error",
        title: "Error",
        text: response?.payload?.message
          ? response?.payload?.message
          : error?.message,
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (date) {
        setSelectedDate(date);
        const response = await dispatch(getPartProcessed(date));

        if (response.error && !loading) {
          swal({
            icon: "error",
            title: "Error",
            text: response?.payload?.message
              ? response?.payload?.message
              : error?.message,
          });
        }
      }
      else{
        setSelectedDate(formatDate(selectedDate));
        const response = await dispatch(getPartProcessed(formatDate(selectedDate)));

        if (response.error && !loading) {
          swal({
            icon: "error",
            title: "Error",
            text: response?.payload?.message
              ? response?.payload?.message
              : error?.message,
          });
        }
      }
     
    };

    fetchData()
  }, []); 

  useEffect(() => {
    if (Object.keys(analytic2message)?.length > 0) {
      const stationname = analytic2message?.stationname + '(' + analytic2message?.stationcode + ')'
      if (partprocessed.labels && Array.isArray(partprocessed.labels)) {
        const index = labelsnew?.length > 0 ? labelsnew.indexOf(stationname) : partprocessed.labels.indexOf(stationname);

        if (index !== -1) {
          const newData =
            datanew?.length > 0 ? [...datanew] : [...partprocessed.datasets.data];
          newData[index] = (parseInt(newData[index]) + 1).toString();
          dispatch(updateNoOfPartProcessed({ data: newData }));
          setDataNew(newData);
        } else {
          const newLabels = labelsnew?.length > 0 ? [...labelsnew, stationname] : [...partprocessed.labels, stationname];
          const newData = datanew?.length > 0 ? [...datanew, "1"] : [...partprocessed?.datasets?.data, "1"];
          // newLabels.shift();
          // newData.shift();
          dispatch(updateNoOfPartProcessed({ labels: newLabels, data: newData }));
          setDataNew(newData);
          setLabelsNew(newLabels);
        }
      }
    }
  }, [analytic2message]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              {loading ? (
                <CircularProgress color="inherit" size={40} />
              ) : (
                <Line data={data} options={options} />
              )}
            </MDBox>
          ),
          [chart, color, loading]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDInput
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{
                width: 160,
                minHeight: 30,
                ml: "auto",
                mr: 1,
                marginBottom: datemargin ? 1 : 0,
              }}
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

PartsProcessed.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  chart: PropTypes.string,
  datemargin: PropTypes.bool,
};

export default PartsProcessed;
