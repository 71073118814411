// src/redux/slices/getProductionMessageSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productionMessage: null,
};

const getProductionMessageSlice = createSlice({
  name: 'productionMessage',
  initialState,
  reducers: {
    setProductionMessage(state, action) {
      state.productionMessage = action.payload;
    },
  },
});

export const { setProductionMessage } = getProductionMessageSlice.actions;

export default getProductionMessageSlice.reducer;
