// // prop-type is a library for typechecking of props
// import PropTypes from "prop-types";
// import * as React from "react";

// // @mui material components
// import Grid from "@mui/material/Grid";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";


// import {
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   FormHelperText,
// } from "@mui/material";

// import { useDispatch, useSelector } from "react-redux"; 

// // vitals page components
// import FormField from "../FormField";

// import { useEffect, useState } from "react";

// import { BarLoader } from "react-spinners";

// import { useFormikContext } from "formik";
// import { getDepartment } from "app/departmentMaster/service/Department";

// function DepartmentInfo({ formData }) {
 
//   const [color] = useState("#344767");
//   const { setFieldValue, setFieldError } = useFormikContext();

//   const { formField, values, errors, touched } = formData;
//   const dispatch = useDispatch();
//   const {department, loading} = useSelector((state) => state.department);

//   const { departmentName, departmentDescrption,parentdepartmnet } = formField;
//   var { departmentName: departmentNameV, departmentDescrption: departmentDescrptionV,parentdepartmnet:parentdepartmnetV } = values;
//   useEffect(() => {
//     dispatch(getDepartment());
//   }, [])
//   return (
//     <MDBox>
//       <MDBox lineHeight={0}>
//         <MDTypography variant="h5">Department Master</MDTypography>
//         <MDTypography variant="button" color="text">
//           Mandatory informations
//         </MDTypography>
//       </MDBox>

//       <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

//       {!loading && (
//         <MDBox mt={1.625}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <FormField
//                 type={departmentName.type}
//                 label=<span>{departmentName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
//                 name={departmentName.name}
//                 value={departmentNameV}
//                 placeholder={departmentName.placeholder}
//                 error={errors.departmentName && touched.departmentName}
//                 success={departmentNameV.length > 0 && !errors.departmentName}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormField
//                 type={departmentDescrption.type}
//                 label=<span>{departmentDescrption.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
//                 name={departmentDescrption.name}
//                 value={departmentDescrptionV}
//                 placeholder={departmentDescrption.placeholder}
//                 error={errors.departmentDescrption && touched.departmentDescrption}
//                 success={departmentDescrptionV.length > 0 && !errors.departmentDescrption}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//                   <FormControl
//                     fullWidth
//                     error={!!errors.parentdepartmnet && touched.parentdepartmnet}
//                   >
//                     <InputLabel htmlFor="parentdepartmnet" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Parent Department<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
//                     <Select
//                       native
//                       variant="standard"
//                       style={{ width: "100%" }}
//                       name="parentdepartmnet"
//                       id="parentdepartmnet"
//                       value={values.parentdepartmnet}
                     


//                       onChange={(e) => {
//                             const productdepartmnet = e.target.value;
//                             console.log("parenthierarchical",productdepartmnet)
//                             const selectedDepartmentObj = department.find(obj => obj.code === productdepartmnet) || {};
//                             console.log("selectedDepartmentObj",selectedDepartmentObj.hierarchicalcode)
                           
//                              setFieldValue("parentdepartmnet", productdepartmnet);
//                              setFieldValue("hierarchicalcode", selectedDepartmentObj.hierarchicalcode);
                            
//                           }}
//                     >
                    
//                      <option value="" disabled></option>
//                       {department.map((obj) => (
//                         // HERE WE NEED DEPARTMNET CODE NOT ROLE CODE
//                         <option key={obj.id} value={obj.code}>  
//                           {obj.name}
//                         </option>
//                       ))}
//                     </Select>
//                     {errors.parentdepartmnet && touched.parentdepartmnet && (
//                       <FormHelperText error style={{marginLeft:"0px"}}>{errors.parentdepartmnet}</FormHelperText>
//                     )}
//                   </FormControl>
//                 </Grid>
//           </Grid>
//           <Grid container spacing={3}></Grid>
//         </MDBox>
//       )}
//     </MDBox>
//   );
// }

// // typechecking props for DepartmentInfo
// DepartmentInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

// export default DepartmentInfo;





// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux"; 
import { getDepartment } from "app/departmentMaster/service/Department";


function DepartmentInfo({ formData }) {

  const [color] = useState("#344767");

  const [hierarchicalCode, setHierarchicalCode] = React.useState(null);

  const { formField, values, errors, touched } = formData;
    const dispatch = useDispatch();
  const {department, loading} = useSelector((state) => state.department);
  const defaultProps = {
    options: !department ? [{ name: "Loading...", id: 0 }] : department,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { departmentName,  departmentDescrption} = formField;
  var {
    departmentName: departmentNameV,
     departmentDescrption:  departmentDescrptionV,
  
    
  } = values;
 



  //  useEffect(() => {
  //   dispatch(getDepartment());
  // }, [])

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Department Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={departmentName.type}
                label=<span>{departmentName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={departmentName.name}
                value={departmentNameV}
                placeholder={departmentName.placeholder}
                error={errors.departmentName && touched.departmentName}
                success={departmentNameV.length > 0 && !errors.departmentName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={ departmentDescrption.type}
                label=<span>{departmentDescrption.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> </sup></span>
                name={ departmentDescrption.name}
                value={ departmentDescrptionV}
                placeholder={ departmentDescrption.placeholder}
                success={ departmentDescrptionV.length > 0 && !errors. departmentDescrption}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
           
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultProps}
                value={hierarchicalCode}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setHierarchicalCode(newValue);
                  console.log("newValue", newValue)

                  values.department = newValue?.code;
                  values.parenthierarchical=newValue?.hierarchicalcode;
                
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.department && touched.departmentList}
                    // success={department.length > 0 && !errors.department}
                    variant="standard"
                    label= "Parent Department"
                  />
                )}
              />
              
          
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DepartmentInfo
DepartmentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DepartmentInfo;
