import { Card, CircularProgress, Grid } from "@mui/material";
import { resetFilteredAttribute } from "app/attributeMaster/reducer/attributeDetailSlice";
import { getAttributebyModelandStation } from "app/attributeMaster/service/Attribute";
import { getBOMCreation } from "app/bomMaster/service/BOMCreationMaster";
import { getStation } from "app/stationMaster/service/Station";
import MDButton from "components/MDButton";
import MDDatePickerMod from "components/MDDatePickerMod";
import DefaultLineChartMod from "examples/Charts/LineCharts/DefaultLineChartMod";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { spcServiceAction } from "redux/Actions/spcServiceAction";
import swal from "sweetalert";

const SPC = () => {
  const getCurrentDateString = () => {
    const date = new Date();
    return date.toISOString().slice(0, 10);
  };

  const savedState = JSON.parse(localStorage.getItem("spcFilterState"));
  const [date, setDate] = useState(savedState?.date || getCurrentDateString());
  const [model, setModel] = useState(savedState?.model || "");
  const [stationV, setStationV] = useState(savedState?.stationV || "");
  const [attributeV, setAttributeV] = useState(savedState?.attributeV || "");
  const [selectedAttribute, setSelectedAttribute] = useState(
    savedState?.attributeV || ""
  );
  const { bomcreationmaster } = useSelector((state) => state.bomcreationmaster);
  const { station } = useSelector((state) => state.station);
  const { filteredattribute } = useSelector(
    (state) => state.filteredattribute || []
  );
  const { getSPCServiceData } = useSelector((state) => state);

  const operatorCode = localStorage.getItem("operatorCode");
  const rolecode = JSON.parse(localStorage.getItem("mmu-auth"))?.rolecode;

  const dispatch = useDispatch();

  useEffect(() => {
    if (operatorCode == "null" && rolecode == "OP001") {
      return;
    }

    if (operatorCode && rolecode == "OP001") {
      dispatch(getBOMCreation());
      dispatch(getStation(operatorCode));
      dispatch(resetFilteredAttribute());
    } else {
      dispatch(getBOMCreation());
      dispatch(getStation());
      dispatch(resetFilteredAttribute());
    }

    if (savedState) {
      if (savedState?.model && savedState?.stationV) {
        dispatch(
          getAttributebyModelandStation({
            model: savedState?.model,
            station: savedState?.stationV,
          })
        );
      }
      dispatch(
        spcServiceAction({
          model: savedState?.model || "",
          stationcode: savedState?.stationV || "",
          attributename: savedState?.attributeV || "",
          date: new Date(
            savedState?.date || getCurrentDateString()
          ).toLocaleDateString("en-GB"),
        })
      );
    }
  }, []);

  useEffect(() => {
    const attributeExists = filteredattribute.some(
      (attr) => attr.name === savedState?.attributeV
    );
    if (attributeExists) {
      setAttributeV(savedState?.attributeV);
      setSelectedAttribute(savedState?.attributeV);
    } else {
      setAttributeV("");
      setSelectedAttribute("");
    }
  }, [filteredattribute]);

  useEffect(() => {
    if (operatorCode && rolecode == "OP001") {
      setStationV(station?.map((item) => item?.code));
    }
  }, [station]);

  const lineproductiondate = {
    background: "#ffffff",
    height: "46px",
    width: "100%",
    borderRadius: "8px",
    padding: "0px 10px",
  };

  const handleFilterClick = () => {
    if (!date || !model || !stationV || !attributeV) {
      swal({
        title: "",
        text: "Please fill in all fields!",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const filterState = {
      date,
      model,
      stationV,
      attributeV,
    };
    localStorage.setItem("spcFilterState", JSON.stringify(filterState));

    dispatch(
      spcServiceAction({
        model: model,
        stationcode: stationV,
        attributename: attributeV,
        date: new Date(date).toLocaleDateString("en-GB"),
      })
    );

    setSelectedAttribute(attributeV);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ backgroundColor: "#015883", color: "#ffffff", p: 2 }}>
        <Grid container spacing={2} mt={2} mb={5} justifyContent="center">
          <Grid item xs={12} sm={6} md={2}>
            <MDDatePickerMod
              input={{ placeholder: "Select a date" }}
              value={date}
              onChange={(event) => setDate(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <select
              style={lineproductiondate}
              value={model}
              onChange={(event) => {
                setModel(event.target.value);
                if (station?.length > 0 && stationV) {
                  dispatch(
                    getAttributebyModelandStation({
                      model: event.target.value,
                      station: stationV,
                    })
                  );
                }
                if (operatorCode && rolecode === "OP001") {
                  dispatch(
                    getAttributebyModelandStation({
                      model: event.target.value,
                      station: station?.map((item) => item?.code),
                    })
                  );
                }
              }}
            >
              {bomcreationmaster?.length === 0 ? (
                <option value="" disabled selected>
                  Select Model
                </option>
              ) : (
                <>
                  <option value="" disabled>
                    Select Model
                  </option>
                  {bomcreationmaster?.map((item, index) => (
                    <option value={item.modelno} key={index}>
                      {item.modelno}
                    </option>
                  ))}
                </>
              )}
            </select>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <select
              style={lineproductiondate}
              value={stationV}
              onChange={(event) => {
                setStationV(event.target.value);
                if (bomcreationmaster?.length > 0 && model) {
                  dispatch(
                    getAttributebyModelandStation({
                      model: model,
                      station: event.target.value,
                    })
                  );
                }
              }}
            >
              {station?.length === 0 ? (
                <option value="" disabled selected>
                  Select Station
                </option>
              ) : operatorCode && rolecode === "OP001" ? (
                <>
                  {station?.map((item, index) => (
                    <option value={item.code} key={index}>
                      {item.name}({item.code})
                    </option>
                  ))}
                </>
              ) : (
                <>
                  <option value="" disabled>
                    Select Station
                  </option>
                  {station?.map((item, index) => (
                    <option value={item.code} key={index}>
                      {item.name}({item.code})
                    </option>
                  ))}
                </>
              )}
            </select>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <select
              style={lineproductiondate}
              value={attributeV}
              onChange={(event) => {
                const selectedAttrObj = filteredattribute?.find(
                  (item) => item.name === event.target.value
                );
                if (
                  selectedAttrObj &&
                  (selectedAttrObj.upperlimit === "" ||
                    selectedAttrObj.lowerlimit === "")
                ) {
                  swal({
                    title: "",
                    text: "Please fill in both upper limit and lower limit for the selecting attribute!",
                    icon: "warning",
                    confirmButtonText: "OK",
                  });
                  return;
                }
                setAttributeV(event.target.value);
              }}
            >
              <option value="" disabled>
                Select Attribute
              </option>
              {filteredattribute?.map((item, index) => (
                <option value={item.name} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </Grid>
          <Grid
            item
            xs={12}
            md={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <MDButton variant="contained" onClick={handleFilterClick} fullWidth>
              Filter
            </MDButton>
          </Grid>
          <Grid item xs={12}>
            {getSPCServiceData?.loading ? (
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh"
                  }}
                >
                  <CircularProgress />
                </div>
              </Card>
            ) : (
              <DefaultLineChartMod
                title="SPC"
                xAxisTitle={selectedAttribute}
                chart={{
                  labels: getSPCServiceData?.list.map(
                    (i) =>
                      i?.createddate &&
                      i?.createddate.split("T")[1].split(".")[0]
                  ),
                  datasets: [
                    {
                      label: "Upper Limit",
                      color: "dark",
                      data: getSPCServiceData?.list.map(
                        (i) => i?.upperlimit && i?.upperlimit
                      ),
                    },
                    {
                      label: "Actual Value",
                      color: "dark",
                      data: getSPCServiceData?.list.map(
                        (i) => i?.value && i?.value
                      ),
                    },
                    {
                      label: "Lower Limit",
                      color: "dark",
                      data: getSPCServiceData?.list.map(
                        (i) => i?.lowerlimit && i?.lowerlimit
                      ),
                    },
                  ],
                }}
                lineColors={["#fadc96", "#34495e", "#7dd3e1"]}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default SPC;
