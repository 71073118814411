import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const createChecklistservice = createAsyncThunk(
  "createChecklistservice",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.post(
        `checklist-service/api/CheckList/v2/createList`,

        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getChecklistservice = createAsyncThunk(
  "getChecklistservice",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      if (data !== undefined) {
        const response = await axios.get(
          `checklist-service/api/CheckList/v2/get?code=${data}`
        );
        return await response.data;
      } else {
        const response = await axios.get(
          `checklist-service/api/CheckList/v2/get`
        );
        return await response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateChecklistservice = createAsyncThunk(
  "updateChecklistservice",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.put(
        `checklist-service/api/CheckList/v2/update`,
        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteChecklistservice = createAsyncThunk(
  "deleteChecklistservice",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.put(
        `checklist-service/api/CheckList/v2/delete`,
        data
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
