import { createSlice } from "@reduxjs/toolkit";
import { createPokayoke, deletePokayoke, getPokayoke, updatePokayoke } from "../service/Pokayoke";






const initialState = {
  pokayoke: [],
  loading: false,
  error: null,
};

export const pokayokeDetail = createSlice({
  name: "pokayokeDetail",
  initialState,
  extraReducers: {
    [createPokayoke.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createPokayoke.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.pokayoke.push(action.payload.data.dto);
    },
    [createPokayoke.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getPokayoke.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPokayoke.fulfilled]: (state, action) => {
      state.loading = false;
      state.pokayoke = action.payload.data.dtoList;

    },
    [getPokayoke.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deletePokayoke.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deletePokayoke.fulfilled]: (state, action) => {
      state.loading = false;
      
      const { id } = action.payload.data.dto;
      if (id) {
        state.pokayoke = state.pokayoke.filter((pokayoke) => pokayoke.id !== id);
      }
    },
    [deletePokayoke.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updatePokayoke.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updatePokayoke.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.pokayoke.findIndex(pokayoke => pokayoke.id === action.payload.data.dto.id)
      state.pokayoke[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updatePokayoke.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default pokayokeDetail.reducer;
