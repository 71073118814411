import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Import form validation, schema, and initial values
import validations from "../schemas/validations";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";


import { useSelector } from "react-redux";
import swal from "sweetalert";

import Dialog from "@mui/material/Dialog";
import MachineInfo from "./machineInfo";
import { createMachine } from "../service/Machine";
// Function to define form steps
function getSteps() {
  return ["Machine Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <MachineInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function CreateMachinePopup(props) {
  const { openpopupMachine, setopenpopupMachine } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.role);



  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    setIsLoading(true)
    console.log("Form values:", values);

    const requestData = {
      code: values.machinecode.trim(),
      name: values.machinename.trim(),
      description: values.machineDescrption.trim(),
      // tags: [values.tags.trim()],
      // tags: values.tags.split(',').map(tag => tag.trim()), // Split string by comma and trim each tag
      tags: Array.isArray(values.tags)
      ? values.tags.filter(tag => tag.trim() !== '') // Filter out empty tags in case of array
      : values.tags.split(',').map(tag => tag.trim()).filter(tag => tag !== ''), // Split string by comma and trim each tag

      recstatus: "OPEN",
      resourcecode: "DAYCO",
      status: "Active",
      syncstatus: "SYNCED",
      type: "DYACO",
    };

    // try {
    //  // Dispatch the createBrand action and wait for the response
    const response = await dispatch(createMachine(requestData));


    //   // Reset the form after successful submission


    //   // // Navigate to the list page on success
    //   if (!response.error && !loading) {
    //       // actions.setSubmitting(false);
    //       actions.resetForm();
    //       setActiveStep(0);
    //       handleClose()
    //       setIsLoading(false)
    //   }
    // } 

    // catch (error) {
    //   console.error("Error submitting form:", error);
    //   // Handle any error from the API call here
    //   actions.setSubmitting(false);
    //   setIsLoading(false)
    // }
    if (!response.error && !loading) {
      actions.resetForm();

      setIsLoading(false)
      setopenpopupMachine(false)
      swal("Good job!", "Machine has been successfully registered.", "success");
    } else {
      actions.resetForm();
      swal({
        icon: "error",
        title: "Error",
        text: error?.message,
      });
      setIsLoading(false)
      handleClose()
    }


  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    console.log("Form values:", values);

    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  // useEffect(() => {
  //   if (error) {
  //     swal({
  //       icon: 'error',
  //       title: 'Error',
  //       text: error.message,
  //     });
  //   }
  // }, [error]);

  const handleClose = () => {
    setopenpopupMachine(false);
  };



  // Render the component
  return (

    <Dialog open={openpopupMachine} onClose={handleClose} fullWidth maxWidth="md">

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-4}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Machine Master
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          disabled={isLoading}
                        >
                          {loading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>

  );
}

