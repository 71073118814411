import { createSlice } from "@reduxjs/toolkit";
import { createDesignation, deleteDesignation, getDesignation, updateDesignation } from "../service/Designation";






const initialState = {
  designation: [],
  loading: false,
  error: null,
};

export const designationDetail = createSlice({
  name: "designationDetail",
  initialState,
  extraReducers: {
    [createDesignation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createDesignation.fulfilled]: (state, action) => {
      console.log("state",state.designation)
      state.loading = false;
      state.designation.push(action.payload.data.dto);
    },
    [createDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getDesignation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      state.designation = action.payload.data.dtoList;

    },
    [getDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteDesignation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.designation = state.designation.filter((designation) => designation.id !== id);
      }
    },
    [deleteDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateDesignation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateDesignation.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.designation.findIndex(designation => designation.id === action.payload.data.dto.id)
      state.designation[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateDesignation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default designationDetail.reducer;
