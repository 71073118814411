/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const form = {
//   formId: "department-form",
//   formField: {
//     departmentName: {
//       name: "departmentName",
//       label: "Department Name",
//       type: "text",
//       errorMsg: "department Name is required.",
//     },
//     departmentDescrption: {
//       name: "departmentDescrption",
//       label: "Department Descrption",
//       type: "text",
//       errorMsg: "Department Descrption Is Required.",
//     },
//     parentdepartmnet: {
//       name: "parentdepartmnet",
//       label: "Parent departmnet",
//       type: "select",
//       errorMsg: "parent Department Is Required.",
//     },
//   },
// };

// export default form;

const form = {
  formId: "new-department-form",
  formField: {
    departmentName: {
      name: "departmentName",
      label: "Department Name",
      type: "text",
      errorMsg: "Department Name is required.",
    },
    departmentDescrption: {
      name: "departmentDescrption",
      label: "Department Description",
      type: "text",
      errorMsg: "Department Description is required.",
    },
   
    department: {
      name: "department",
      label: "Parent Department",
      type: "text",
      errorMsg: "Parent Department is required.",
    },
  
  },
};

export default form;

