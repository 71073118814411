import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
// Images
import profilePicture from "assets/images/team-4.jpg";

// @mui icons
import Icon from "@mui/material/Icon";

import Ankit from "app/Ankit";
import ChecklistOperator from "app/ChecklistOperator/ChecklistOperator";
import CheckServiceList from "app/ChecklistOperator/CheckServiceList";
import UploadFile from "app/UploadDocumnet/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import WebSocketUI from "app/webSocketUI/WebSocketUI";
import Pokayokalist from "app/ChecklistOperator/Pokayokalist";
import ProductList from "productlist/ProductList";
import SPC from "app/spc/SPC";

const routesOperator = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth"))?.name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },

      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },

  { type: "title", title: "Dashboard", key: "dashboard" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "SPC",
        key: "SPC",
        route: "/app/admin/SPC",
        component: <SPC />,
      },
    ],
  },


  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "collapse",
    name: "Check List Master",
    key: "checklist",
    icon: <AutoStoriesIcon fontSize="medium" />,
    collapse: [
      {
        name: "Create Check List",
        key: "create-record",
        route: "/app/Component/create-record",
        component: <ChecklistOperator />,
      },
      {
        name: "Check List",
        key: "record-list",
        route: "/app/Component/record-list",
        component: <CheckServiceList />,
      },
      {
        name: "Poka yoke",
        key: "poka-yoka",
        route: "/app/admin/poka-yoka",
        component: <Pokayokalist />,
      },
    ],
  },

  {
    type: "collapse",
    name: "View Document",
    key: "uploadDocument",
    icon: <CloudUploadIcon fontSize="medium" />,
    collapse: [
      {
        name: "View-file",
        key: "upload-file",
        route: "/app/Component/upload-file",
        component: <UploadFile />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <CloudUploadIcon fontSize="medium" />,
    collapse: [
      {
        name: "Product Detail",
        key: "product-detail",
        route: "/app/Component/product-detail",
        component: <WebSocketUI />,
      },
      {
        name: "Product Listing",
        key: "product-list",
        route: "/app/Component/Productlist",
        component: <ProductList />,
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Rough work master",
  //   key: "mastersssxx",
  //   icon: <Icon fontSize="medium">badge</Icon>,
  //   collapse: [
  //     {
  //       name: "Ankit List",
  //       key: "ankit-list",
  //       route: "/app/Component/ankit-list/:id",
  //       component: <Ankit />,
  //     },

  //   ],
  // },
];
export default routesOperator;
