import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";
import { FormControl, TextField } from "@mui/material";
import { createProcess } from "../service/ProcessMaster";

export default function CreateProcess(props) {
  const { openpopupProcess, setopenpopupProcess } = props;
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [selectedProcess, setSelectedProcess] = useState({});

  const validationSchema = Yup.object({
    name: Yup.string().required("Process Name is required"),
    stationsequence: Yup.string().required("Station Sequence is required"),
    bottleneckcycletime: Yup.string().required(
      "Bottleneck Cycle Time is required"
    ),
    operatorefficiencyfactor: Yup.string().required(
      "Operator Efficiency Factor is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      stationsequence: "",
      name: "",
      bottleneckcycletime: "",
      operatorefficiencyfactor: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("Form Values:", values);
      const requestData = {
        name: values.name?.trim(),
        stationsequence: values.stationsequence?.trim(),
        bottleneckcycletime: values.bottleneckcycletime,
        operatorefficiencyfactor: values.operatorefficiencyfactor,
        recstatus: "OPEN",
        resourcecode: "DAYCO",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      };
      dispatch(createProcess(requestData));
      resetForm();
      handleClose();
    },
  });

  const handleClose = () => {
    setSelectedProcess({});
    setopenpopupProcess(false);
  };

  return (
    <Dialog
      open={openpopupProcess}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <form onSubmit={formik.handleSubmit}>
            <Card>
              <MDBox mx={2} mt={-3}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={-2}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="small"
                    color="white"
                    mt={1}
                  >
                    Process Master
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox p={3}>
                <Grid container spacing={3} mt={0.5}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="name"
                        name="name"
                        label=<span>
                          Process Name
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="stationsequence"
                        name="stationsequence"
                        label=<span>
                          Station Sequence
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        placeholder="XX||XX"
                        value={formik.values.stationsequence}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.stationsequence &&
                          Boolean(formik.errors.stationsequence)
                        }
                        helperText={
                          formik.touched.stationsequence &&
                          formik.errors.stationsequence
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="bottleneckcycletime"
                        name="bottleneckcycletime"
                        label=<span>
                          Bottleneck Cycle Time (Sec)
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        type="number"
                        value={formik.values.bottleneckcycletime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.bottleneckcycletime &&
                          Boolean(formik.errors.bottleneckcycletime)
                        }
                        helperText={
                          formik.touched.bottleneckcycletime &&
                          formik.errors.bottleneckcycletime
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="operatorefficiencyfactor"
                        name="operatorefficiencyfactor"
                        label=<span>
                          Operator Efficiency Factor (%)
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        type="number"
                        value={formik.values.operatorefficiencyfactor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.operatorefficiencyfactor &&
                          Boolean(formik.errors.operatorefficiencyfactor)
                        }
                        helperText={
                          formik.touched.operatorefficiencyfactor &&
                          formik.errors.operatorefficiencyfactor
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <MDBox
                  mt={5}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton type="submit" variant="gradient" color="dark">
                    {loading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
}
