import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateProcess } from "app/bomMaster/service/ProcessMaster";
import { MoonLoader } from "react-spinners";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";

const Processedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedProcess, openpopup, setopenpopup } = props;

  const { error, loading } = useSelector((state) => state.processmaster);

  const validationSchema = Yup.object({
    name: Yup.string().required("Process Name is required"),
    stationsequence: Yup.string().required("StationSequence is required"),
    bottleneckcycletime: Yup.string().required(
      "Bottleneck Cycle Time is required"
    ),
    operatorefficiencyfactor: Yup.string().required(
      "Operator Efficiency Factor is required"
    ),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedProcess?.name || "",
      stationsequence: selectedProcess?.stationsequence || "",
      bottleneckcycletime: selectedProcess?.bottleneckcycletime || "",
      operatorefficiencyfactor: selectedProcess?.operatorefficiencyfactor || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const updatedProcess = {
        ...selectedProcess,
        name: values.name,
        stationsequence: values.stationsequence,
        bottleneckcycletime: values.bottleneckcycletime,
        operatorefficiencyfactor: values.operatorefficiencyfactor,
      };
      const response = await dispatch(updateProcess(updatedProcess));

      if (!response.error && !loading) {
        setopenpopup(false);
        swal("Good job!", "Process has been successfully updated.", "success");
      } else {
        handleClose();

        swal({
          icon: "error",
          title: "Error",
          text: error?.message
            ? error?.message
            : "An error occurred while updating the item",
        });
      }
    },
  });

  useEffect(() => {
    // Update the form values when selectedProcess changes
    formik.setValues({
      name: selectedProcess?.name || "",
      stationsequence: selectedProcess?.stationsequence || "",
      bottleneckcycletime: selectedProcess?.bottleneckcycletime || "",
      operatorefficiencyfactor: selectedProcess?.operatorefficiencyfactor || "",
    });
  }, [selectedProcess]);

  const handleClose = () => {
    formik.setValues({
      name: selectedProcess?.name || "",
      stationsequence: selectedProcess?.stationsequence || "",
      bottleneckcycletime: selectedProcess?.bottleneckcycletime || "",
      operatorefficiencyfactor: selectedProcess?.operatorefficiencyfactor || "",
    });
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                label=<span>
                  Process Name
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched.name && formik.errors.name ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.name}
                    </p>
                  ) : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                //   label="stationsequence"
                label=<span>
                  Station Sequence
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="stationsequence"
                name="stationsequence"
                placeholder="XX||XX"
                value={formik.values.stationsequence}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.stationsequence &&
                  Boolean(formik.errors.stationsequence)
                }
                helperText={
                  formik.touched.stationsequence &&
                  formik.errors.stationsequence ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.stationsequence}
                    </p>
                  ) : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDInput
                type="number"
                label=<span>
                  Bottleneck Cycle Time (Sec)
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="bottleneckcycletime"
                name="bottleneckcycletime"
                value={formik.values.bottleneckcycletime}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.bottleneckcycletime &&
                  Boolean(formik.errors.bottleneckcycletime)
                }
                helperText={
                  formik.touched.bottleneckcycletime &&
                  formik.errors.bottleneckcycletime ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.bottleneckcycletime}
                    </p>
                  ) : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MDInput
                type="number"
                label=<span>
                  Operator Efficiency Factor (%)
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                variant="standard"
                fullWidth
                id="operatorefficiencyfactor"
                name="operatorefficiencyfactor"
                value={formik.values.operatorefficiencyfactor}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                error={
                  formik.touched.operatorefficiencyfactor &&
                  Boolean(formik.errors.operatorefficiencyfactor)
                }
                helperText={
                  formik.touched.operatorefficiencyfactor &&
                  formik.errors.operatorefficiencyfactor ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.operatorefficiencyfactor}
                    </p>
                  ) : null
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="success" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="info" type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Processedit;
