import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { Sequence, readPath, readFileName, writePath, writeFileName, keyToCheck, statusOut, uri, uriMethodType, statusMap, archiveLoc, outProp, stationCode, stationName, arcreq, fileType, startLine, wcfilter },
} = checkout;

const validations = [
  Yup.object().shape({
    [Sequence.name]: Yup.string().required(Sequence.errorMsg),
    [readPath.name]: Yup.string().required(readPath.errorMsg),
    [readFileName.name]: Yup.string().required(readFileName.errorMsg),
    [writePath.name]: Yup.string().required(writePath.errorMsg),
    [writeFileName.name]: Yup.string().required(writeFileName.errorMsg),
    [keyToCheck.name]: Yup.string().required(keyToCheck.errorMsg),
    [statusOut.name]: Yup.string().required(statusOut.errorMsg),
    [uri.name]: Yup.string().required(uri.errorMsg),
    [uriMethodType.name]: Yup.string().required(uriMethodType.errorMsg),
    // [status.name]: Yup.string().required(status.errorMsg),
    [statusMap.name]: Yup.string().required(statusMap.errorMsg),
    [archiveLoc.name]: Yup.string().required(archiveLoc.errorMsg),
    [outProp.name]: Yup.string().required(outProp.errorMsg),
    // [notFound.name]: Yup.string().required(notFound.errorMsg),
    [stationCode.name]: Yup.string().required(stationCode.errorMsg),
    [stationName.name]: Yup.string().required(stationName.errorMsg),
    [arcreq.name]: Yup.string().required(arcreq.errorMsg),
    [startLine.name]: Yup.string().required(startLine.errorMsg),
    [fileType.name]: Yup.string().required(fileType.errorMsg),
    // [dcflag.name]: Yup.string().required(dcflag.errorMsg),
    [wcfilter.name]: Yup.string().required(wcfilter.errorMsg),
  }),
];

export default validations;
