import { createSlice } from "@reduxjs/toolkit";
import { createMachine, deleteMachine, getMachine, updateMachine } from "../service/Machine";






const initialState = {
  machine: [],
  loading: false,
  error: null,
};

export const machineDetail = createSlice({
  name: "machineDetail",
  initialState,
  extraReducers: {
    [createMachine.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createMachine.fulfilled]: (state, action) => {
      console.log("state",state.machine)
      state.loading = false;
      state.machine.push(action.payload.data.dto);
    },
    [createMachine.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getMachine.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMachine.fulfilled]: (state, action) => {
      state.loading = false;
      state.machine = action.payload.data.dtoList;

    },
    [getMachine.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteMachine.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteMachine.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.machine = state.machine.filter((machine) => machine.id !== id);
      }
    },
    [deleteMachine.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateMachine.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateMachine.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.machine.findIndex(machine => machine.id === action.payload.data.dto.id)
      state.machine[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateMachine.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default machineDetail.reducer;
