import { useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "examples/Charts/LineCharts/DefaultLineChartMod/configs";
import colors from "assets/theme/base/colors";

function DefaultLineChartMod({ icon, title, description, height, chart, lineColors, xAxisTitle }) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset, index) => ({
        ...dataset,
        tension: 0,
        pointRadius: 3,
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        pointBackgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        borderColor: lineColors ? lineColors[index] : colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  options.scales = {
    y: {
      title: {
        display: true,
        text: "Value",
        font: {
          weight: "bold",
          size: "16px"
        }
      },
    },
    x: {
      title: {
        display: true,
        text: xAxisTitle,
        font: {
          weight: "bold",
          size: "16px"
        }
      },
    },
  };

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      <MDBox height={height}>
        <Line data={data} options={options} />
      </MDBox>
      {chart.datasets && (
        <MDBox mt={2} display="flex" flexDirection="row" justifyContent="space-evenly">
          {chart.datasets.map((dataset, index) => (
            <MDBox key={dataset.label} display="flex" alignItems="center" mb={1}>
              <MDBox
                width="12px"
                height="12px"
                bgColor={lineColors ? lineColors[index] : colors[dataset.color]?.main || colors.dark.main}
                borderRadius="4px"
                mr={1}
              />
              <MDTypography variant="body2" color="text">
                {dataset.label}
              </MDTypography>
            </MDBox>
          ))}
        </MDBox>
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}


DefaultLineChartMod.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  lineColors: [], 
  xAxisTitle: "", 
};


DefaultLineChartMod.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
  lineColors: PropTypes.arrayOf(PropTypes.string), 
  xAxisTitle: PropTypes.string, 
};

export default DefaultLineChartMod;
