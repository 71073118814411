import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosinstance';
import { APIs } from "services/APIs";

export const spcServiceAction = 
   createAsyncThunk("getSPCServiceData", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data?.model && data?.stationcode && data?.attributename && data?.date){
        url = APIs.attributeServiceApi +`/getV3?modelno=${data?.model}&stationcode=${data?.stationcode}&name=${data?.attributename}&createddate=${data?.date}`
      } else if(data && data?.url){
        url = data.url
      }else{
        return
      }
        let respData = await axios.get(url)
        return respData?.data?.data?.documentList || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });