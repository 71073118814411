import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getDepartment } from "app/departmentMaster/service/Department";
import { updateDesignation } from "../service/Designation";
import { MoonLoader } from "react-spinners";

const validationSchema = Yup.object().shape({
  designationname: Yup.string().required("Name is required"),
  departmentvalue: Yup.string().required("Department is required"),
});

const Designationedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedDesignation, openpopup, setopenpopup } = props;
  const { department, loading } = useSelector((state) => state.department);
  const { designation } = useSelector((state) => state.designation);
  const [selectdes, setSelectDes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  console.log(selectedDesignation);
  const onSubmit = (values) => {
    console.log(values);
    setIsLoading(true);
    const updatedDesignation = {
      ...selectedDesignation,
      name: values.designationname,
      deptcode: values.departmentvalue
        ? values.departmentvalue
        : selectedDesignation?.deptcode,
      description: values.description,

      parenthierarchicalcode: values.designationvalue
        ? values.designationvalue
        : selectedDesignation.parenthierarchicalcode,
      hierarchicalcode: values.designationvalue
        ? values.designationvalue
        : selectedDesignation.hierarchicalcode,
    };
    try {
      dispatch(updateDesignation(updatedDesignation));
      setopenpopup(false);
      setIsLoading(false);

      console.log(values);
      swal(
        "Good job!",
        "Designation has been successfully updated.",
        "success"
      );
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
      handleClose();
      swal("", "Something went wrong!", "error");
    }
  };

  const initialValues = {
    departmentvalue: selectedDesignation?.deptcode,
    designationname: selectedDesignation?.name,
    description: selectedDesignation?.description,
  };

  const handleClose = () => {
    setopenpopup(false);
    setSelectDes("");

    for (let i = 0; i < designation.length; i++) {
      if (
        designation[i]?.hierarchicalcode ===
        selectedDesignation?.parenthierarchicalcode
      ) {
        setSelectDes(designation[i].hierarchicalcode);
      }
    }
  };

  useEffect(() => {
    dispatch(getDepartment());
    setSelectDes("");

    for (let i = 0; i < designation.length; i++) {
      console.log(designation);
      if (
        designation[i]?.hierarchicalcode ===
        selectedDesignation?.parenthierarchicalcode
      ) {
        setSelectDes(designation[i].hierarchicalcode);
      }
    }
  }, [selectedDesignation]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent style={{ width: 550, height: 320 }}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span>
                    Designation Name
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  variant="standard"
                  fullWidth
                  id="designationname"
                  name="designationname"
                  value={values.designationname}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.designationname && touched.designationname && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.designationname}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span>
                    Designation Description
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                    </sup>
                  </span>
                  variant="standard"
                  fullWidth
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="departmentvalue"
                    style={{
                      paddingBottom: "2px",
                    }}
                  >
                    <span>
                      Department
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentvalue"
                    id="departmentvalue"
                    value={values.departmentvalue}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      setFieldValue("departmentvalue", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.departmentvalue && touched.departmentvalue && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: 400 }}
                    >
                      {errors.departmentvalue}
                    </FormHelperText>
                  )}
                </FormControl>
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="designationvalue"
                    style={{
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Designation
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationvalue"
                    id="designationvalue"
                    value={selectdes}
                    onChange={(e) => {
                      const designationvalue = e.target.value;

                      setFieldValue("designationvalue", designationvalue);
                      setSelectDes(designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designation.map((obj) => (
                      <option key={obj.id} value={obj.hierarchicalcode}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <MoonLoader color="black" size={16} /> : "Update"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Designationedit;
