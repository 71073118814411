import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateRole } from "../service/Role";
import { MoonLoader } from "react-spinners";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";

const Roleedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedRole, openpopup, setopenpopup } = props;

  const { error, loading } = useSelector((state) => state.role);

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Role Name is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedRole?.name || "",
      description: selectedRole?.description || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const updatedRole = {
        ...selectedRole,
        name: values.name,
        description: values.description,
      };
      const response = await dispatch(updateRole(updatedRole));

      if (!response.error && !loading) {
        setopenpopup(false);
        swal("Good job!", "Role has been successfully updated.", "success");
      } else {
        handleClose();

        swal({
          icon: "error",
          title: "Error",
          text: error?.message
          ? error?.message
          : "An error occurred while updating the item",
        });
      }
    },
  });

  useEffect(() => {
    // Update the form values when selectedRole changes
    formik.setValues({
      name: selectedRole?.name || "",
      description: selectedRole?.description || "",
    });
  }, [selectedRole]);

  const handleClose = () => {
    formik.setValues({
      name: selectedRole?.name || "",
      description: selectedRole?.description || "",
    });
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>
                Role Name
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Role Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="success" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="info" type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Roleedit;
