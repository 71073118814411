import { createSlice } from "@reduxjs/toolkit";
import { getAttributeList } from "./service/AttributeList";







const initialState = {
  attributeList: [],
  loading: false,
  error: null,
};

export const attributeList = createSlice({
  name: "attributeList",
  initialState,
  extraReducers: {
   
    [getAttributeList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAttributeList.fulfilled]: (state, action) => {
      state.loading = false;
      state.attributeList = action.payload.data.documentList;

    },
    [getAttributeList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
   
  },
});

export default attributeList.reducer;
