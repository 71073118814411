

import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-cool-form";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/Dayco.png";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { logIn } from "../../../../redux/Actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { MoonLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
export default function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const container = useRef(null);
  const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);
 
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
  const stationCode = queryParams.get("code");
  

  const { form, use } = useForm({
    defaultValues: { email: "", password: "", resourcecode: "DAYCO" },
    onSubmit: (values) => loginUser(values),
  });

  const errors = use("errors");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const loginUser = async (data) => {
    setIsLoading(true); // Set loading state to true
    const getData = {
      username: data.email,
      password: data.password,
      resourcecode: "DAYCO",
    };

    dispatch(logIn(getData,stationCode)).then(() => {
      setIsLoading(false); // Set loading state back to false when authentication is finished
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      // console.log("useeefect",isAuthenticated)
     
      navigate("/pages/profile/profile-overview");
  

    }
  }, [isAuthenticated]);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require("./gear.json"),
    });
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <Card style={{marginTop:"75px"}}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Sign In

          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your username and Password to logIn
          </MDTypography>
        </MDBox>

        <form ref={form} noValidate>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox mb={2}>
              <MDInput name="email" placeholder="Enter Username" required fullWidth />
              {errors.email && (
                <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>
                  {errors.email}
                </p>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput name="password" type="password" placeholder="Enter Password" required fullWidth />
              {errors.password && (
                <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>
                  {errors.password}
                </p>
              )}
            </MDBox>
            <MDBox mb={2} mt={5}>
             

              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={isLoading}>
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Log In"
                          )}
                        </MDButton>
            </MDBox>
            <MDTypography variant="button" color="text" style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
              {/* <MDTypography
                component={Link}
                to="/app/ForgotPassword/forgot-password"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                ForgotPassword?
              </MDTypography> */}
            </MDTypography>
          </MDBox>
        </form>

        {/* <MDBox mt={2} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/app/newregistration/registration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox> */}
      </Card>
    </CoverLayout>
  );
}



