import { createSlice } from "@reduxjs/toolkit";
import { createTypeMaster, deleteTypeMaster, getTypeMaster, updateTypeMaster } from "../service/TypeMaster";






const initialState = {
  typemaster: [],
  loading: false,
  error: null,
};

export const typemasterDetail = createSlice({
  name: "typemasterDetail",
  initialState,
  extraReducers: {
    [createTypeMaster.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createTypeMaster.fulfilled]: (state, action) => {
      console.log("state",state.typemaster)
      state.loading = false;
      state.typemaster.push(action.payload.data.dto);
    },
    [createTypeMaster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getTypeMaster.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTypeMaster.fulfilled]: (state, action) => {
      state.loading = false;
      state.typemaster = action.payload.data.dtoList;

    },
    [getTypeMaster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteTypeMaster.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteTypeMaster.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.typemaster = state.typemaster.filter((typemaster) => typemaster.id !== id);
      }
    },
    [deleteTypeMaster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateTypeMaster.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateTypeMaster.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.typemaster.findIndex(typemaster => typemaster.id === action.payload.data.dto.id)
      state.typemaster[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateTypeMaster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default typemasterDetail.reducer;
