// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { getDepartment } from "app/departmentMaster/service/Department";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { getRole } from "app/Role Master/service/Role";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getDesignation } from "app/designationMaster/service/Designation";

function UserInfo({ formData }) {
  const dispatch = useDispatch();
  const { department } = useSelector((state) => state.department);
  const { role, loading } = useSelector((state) => state.role);
  const { designation } = useSelector((state) => state.designation);

  const [color] = useState("#344767");

  const [designationList, setDesignationList] = useState([]);

  const { setFieldValue, setFieldError } = useFormikContext();

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
    username,
    phonenumber,
    email,

    password,
    repeatPassword,
  } = formField;
  var {
    firstName: firstNameV,
    lastName: lastNameV,
    username: usernameV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    phonenumber: phonenumberV,
    email: emailV,
  } = values;

  function getDesignationData(code) {
    const result = designation.filter(
      (designationItem) => designationItem.deptcode === code
    );
    setDesignationList(result);
  }
  useEffect(() => {
    dispatch(getRole());
    dispatch(getDepartment());
    dispatch(getDesignation());
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New User</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label=<span>
                  {firstName.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                // error={errors.lastName && touched.lastName}
                // success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={username.type}
                label=<span>
                  {username.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={username.name}
                value={usernameV}
                placeholder={username.placeholder}
                error={errors.username && touched.username}
                success={usernameV.length > 0 && !errors.username}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
              >
                <InputLabel
                  htmlFor="rolecode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Role
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="rolecode"
                  id="rolecode"
                  value={values.rolecode}
                  onChange={(e) => {
                    const rolecode = e.target.value;
                    const selectedRoleObj =
                      role.find((obj) => obj.rolecode === rolecode) || {};
                   
                    // const roleName =
                    //   e.target.options[e.target.selectedIndex].text;
                    setFieldValue("roleName", selectedRoleObj.name);  
                    setFieldValue("rolecode", rolecode);
                   
                  }}
                >
                  <option value=""></option>
                  {role.map((obj) => (
                    <option key={obj.id} value={obj.rolecode}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.rolecode && touched.rolecode && (
                  <FormHelperText error style={{ marginLeft: "0px", fontWeight:400 }}>
                    {errors.rolecode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
              >
                <InputLabel
                  htmlFor="departmentvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Department
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="departmentvalue"
                  id="departmentvalue"
                  value={values.departmentvalue}
                  onChange={(e) => {
                    const departmentvalue = e.target.value;
                    const selectedDepartmentObj =
                      department.find((obj) => obj.code === departmentvalue) ||
                      {};

                    getDesignationData(e.target.value);
                    setFieldValue("department", selectedDepartmentObj);

                    setFieldValue("departmentvalue", departmentvalue);
                  }}
                >
                  <option value=""></option>
                  {department.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.departmentvalue && touched.departmentvalue && (
                  <FormHelperText error style={{ marginLeft: "0px", fontWeight:400 }}>
                    {errors.departmentvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
              >
                <InputLabel
                  htmlFor="designationvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Designation
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="designationvalue"
                  id="designationvalue"
                  value={values.designationvalue}
                  onChange={(e) => {
                    const designationvalue = e.target.value;
                    //values.designation = newValue;
                    const designationobj =
                      designation.find(
                        (obj) => obj.code === designationvalue
                      ) || {};

                   
                    setFieldValue("designation", designationobj);
                    setFieldValue("designationvalue", designationvalue);
                  }}
                >
                  <option value=""></option>
                  {designationList.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.designationvalue && touched.designationvalue && (
                  <FormHelperText error style={{ marginLeft: "0px", fontWeight:400 }}>
                    {errors.designationvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={phonenumber.type}
                label=<span>
                  {phonenumber.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={phonenumber.name}
                value={phonenumberV}
                placeholder={phonenumber.placeholder}
                error={errors.phonenumber && touched.phonenumber}
                success={phonenumberV.length > 0 && !errors.phonenumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label=<span>
                  {password.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label=<span>
                  {repeatPassword.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
