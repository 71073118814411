import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosinstance';
import { APIs } from "services/APIs";

export const reportServiceAction = 
   createAsyncThunk("getReportServiceList", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data?.model && data?.formatteddate){
        url = APIs.reportServiceApi +`/getByModelAndDate?modelno=${data?.model}&date=${data?.formatteddate}`
      } else if(data && data?.url){
        url = data.url
      }else{
        return
      }
        let respData = await axios.get(url)
        return respData?.data?.data?.dtoList || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });