
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create designation

export const createDesignation = createAsyncThunk("createDesignation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `designation-service-mongo/api/designation/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getDesignation = createAsyncThunk("getDesignation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `designation-service-mongo/api/designation/v2/get`,
     
    );   
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateDesignation = createAsyncThunk("updateDesignation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `designation-service-mongo/api/designation/v2/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteDesignation = createAsyncThunk("deleteDesignation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `designation-service-mongo/api/designation/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




