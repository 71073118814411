import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{machinecode, machinename,machineDescrption,tags},
} = checkout;

const validations = [
  Yup.object().shape({
    [machinecode.name]: Yup.string().required(machinecode.errorMsg),
    [machinename.name]: Yup.string().required(machinename.errorMsg),
    [machineDescrption.name]: Yup.string(),
    [tags.name]: Yup.string().required(tags.errorMsg),
  }),
];

export default validations;
