/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "attribute-form",
  formField: {
    type: {
      name: "type",
      label: "Type",
      type: "text",
      errorMsg: "Type is required.",
    },
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    displayname: {
      name: "displayname",
      label: "Display Name",
      type: "text",
      errorMsg: "Display Name is required.",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "Descrption is Required.",
    },
    uomsymbol: {
      name: "uomsymbol",
      label: "UOM Symbol",
      type: "text",
      errorMsg: "UOM Symbol is required.",
    },
    modelno: {
      name: "modelno",
      label: "Model No.",
      type: "text",
      errorMsg: "Model No. is Required.",
    },
    stationcode: {
      name: "stationcode",
      label: "Station",
      type: "select",
      errorMsg: "Station is required.",
    },
    // machinecode: {
    //   name: "machinecode",
    //   label: "Machine",
    //   type: "select",
    //   errorMsg: "Machine is required.",
    // },
    processid: {
      name: "processid",
      label: "Process",
      type: "select",
      errorMsg: "Process is required.",
    },
    uomdescription: {
      name: "uomdescription",
      label: "UOM Description",
      type: "text",
      errorMsg: "UOM Description is Required.",
    },
    upperlimit: {
      name: "upperlimit",
      label: "Upper Limit",
      type: "number",
      errorMsg: "Upper Limit is Required.",
    },
    lowerlimit: {
      name: "lowerlimit",
      label: "Lower Limit",
      type: "number",
      errorMsg: "Lower Limit is Required.",
    },
    userdisplay: {
      name: "userdisplay",
      label: "Validation",
      type: "switch",
      errorMsg: "User Display is required.",
    },
  },
};

export default form;
