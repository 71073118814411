
import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { designationName, departmentvalue,designation  },
} = checkout;

const validations = [
  Yup.object().shape({
    [designationName.name]: Yup.string().required(designationName.errorMsg),
  [departmentvalue.name]: Yup.string().required(departmentvalue.errorMsg),
  // [designation.name]: Yup.string().required(designation.errorMsg),
 
  }),
];

export default validations;
