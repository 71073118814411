// ROLECODE.js

// import React from 'react';


// const Rolecode = () => {
  

  
//   const ROLE_CODE = {
//     Admincode:"AD001",
//     patientcode:"PA001",
//     Doctorcode:"DO001",
//     Nurse:"RE001"
//   };
 

//   return ROLE_CODE
   
// };

// export default Rolecode;




import React from 'react';
import { useSelector } from 'react-redux';



const Rolecode = () => {
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  
    const ROLE_CODE = {};
  
    if (auth.rolecode === "MAE4253") {
      ROLE_CODE.Admincode = "MAE4253";
    } else if (auth.rolecode === "OP001") {
      ROLE_CODE.Opreator = "OP001";
    } else if (auth.rolecode === "DO001") {
      ROLE_CODE.Doctorcode = "DO001";
    } else if (auth.rolecode === "RE001") {
      ROLE_CODE.Nurse = "RE001";
    }
  
    return ROLE_CODE;
  };
  
  export default Rolecode;
  


// next page const code = Rolecode();

  // console.log("rolecode",code);


  // import Rolecode from "../../../../Rolecode";


  // {code?.Admincode? <DoctorQualification />
  //  : <></>
  //  }