// import * as Yup from "yup";
// import checkout from "../schemas/form";
// const {
//   formField:{departmentName,departmentDescrption, parentdepartmnet},
// } = checkout;

// const validations = [
//   Yup.object().shape({
//     [departmentName.name]: Yup.string().required(departmentName.errorMsg),
//     [departmentDescrption.name]: Yup.string().required(departmentDescrption.errorMsg),
//     // [parentdepartmnet.name]: Yup.string().required(parentdepartmnet.errorMsg),
//   }),
// ];

// export default validations;



import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { departmentName },
} = checkout;

const validations = [
  Yup.object().shape({
    [departmentName.name]: Yup.string().required(departmentName.errorMsg),
  }),
];

export default validations;
