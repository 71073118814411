import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import { Dialog, Divider, Grid, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { getitemMaster } from "../../itemMaster/service/ItemMaster";
import { useDispatch } from "react-redux";
import FormField from "./FormField";
// import { getProcess } from "../service/ProcessMaster";
// import AddBoxIcon from "@mui/icons-material/AddBox";
// import CreateProcess from "./CreateProcess";
import { createBOMCreation } from "../service/BOMCreationMaster";
import { useFormik } from "formik";

export default function CreateBOMPopup(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idCounter, setIdCounter] = useState(0);
  const { itemmaster, loading1 } = useSelector((state) => state.itemmaster);
  const [value, setValue] = useState("");
  const [processvalue, setProcessValue] = useState("");
  const [editItemId, setEditItemId] = useState(null);
  // const [openpopupProcess, setopenpopupProcess] = useState(false);
  // const { processmaster, loading2 } = useSelector(
  //   (state) => state.processmaster
  // );
  const dispatch = useDispatch();

  const initialValues = {
    modelno: "",
    bomname: "",
    bomdescription: "",
    pattern: "",

    processid: "",
  };
  console.log("itemmaster", itemmaster);

  const validationSchema = Yup.object({
    modelno: Yup.string().required("Model No. is required"),
    bomname: Yup.string().required("BOM Name Name is required"),
    pattern: Yup.string().required("Pattern is required"),

    processid: Yup.string().required("Process Id is required"),
  });

  const handleClose = () => {
    setopenpopupBOM(false);
    setData([]);
    setValue(null);
    setProcessValue(null);
  };

  const { openpopupBOM, setopenpopupBOM } = props;

  const handleSubmit = async (values, { resetForm }) => {
    const requestData = {
      modelno: values.modelno,
      name: values.bomname,
      description: values.bomdescription,

      pattern: values.pattern,

      processid: values.processid,
      recstatus: "false",
      resourcecode: "DAYCO",
      status: "ACTIVE",
      syncstatus: "SYNCED",
      type: "DAYCO",
    };

    console.warn("xyz", requestData);
    try {
      const response = await dispatch(createBOMCreation(requestData));
      console.log(response);
      handleClose();
      setLoading(false);
      swal("Good job!", "BOM has been successfully registered.", "success");
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    resetForm({ values: { ...values, itemquantity: "" } });
  };

  useEffect(() => {
    // dispatch(getProcess());
    dispatch(getitemMaster());
  }, [dispatch]);

  return (
    <Dialog open={openpopupBOM} onClose={handleClose} fullWidth maxWidth="md">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={0}
                      p={2}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        mt={2}
                      >
                        BOM Master
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <Grid container spacing={3} mt={0}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="modelno"
                          label=<span>
                            Model No.
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="modelno"
                          value={values.modelno}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="bomname"
                          label=<span>
                            Name
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="bomname"
                          value={values.bomname}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="bomdescription"
                          label=<span>
                            Description
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                            </sup>
                          </span>
                          name="bomdescription"
                          value={values.bomdescription}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="pattern"
                          label=<span>
                            Pattern
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="pattern"
                          value={values.pattern}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="text"
                          label=<span>
                            Process Id
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="processid"
                          value={values.processid}
                          placeholder="XX||XX"
                        />
                      </Grid>
                    </Grid>
                  </MDBox>

                  {/*
                <Grid
                item
                xs={12}
                sm={12}
                style={{
                  border: "1px solid #727375",
                  borderRadius: "5px",
                }}
                mr={4}
                mb={4}
                mt={0}
                ml={3}
                >
                <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={{ columns, rows: data }}
                />
                </Grid>
              */}

                  <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    marginRight="10px"
                    marginBottom="15px"
                  >
                    <MDButton variant="gradient" color="dark" type="submit">
                      {loading ? (
                        <MoonLoader color="#f2fefa" size={16} />
                      ) : (
                        "submit"
                      )}
                    </MDButton>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {/* <CreateProcess
        openpopupProcess={openpopupProcess}
        setopenpopupProcess={setopenpopupProcess}
      /> */}
    </Dialog>
  );
}
