import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredMessages: [],
  loading: false,
  error: null,
};

export const filteredMessagesSlice = createSlice({
  name: "filteredMessages",
  initialState,
  reducers: {
    setFilteredMessages: (state, action) => {
      console.log("ddd",action.payload)
      state.filteredMessages = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setfilterClear: (state,action) => {
      state.filteredMessages = [];
      state.loading = false;
      state.error = null;
    }
  },
});

export const { setFilteredMessages, setLoading, setError, setfilterClear} = filteredMessagesSlice.actions;

export default filteredMessagesSlice.reducer;
