

import React from "react";
 
// @mui material components
import Card from "@mui/material/Card";
 
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
 
import { Grid } from "@mui/material";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
 
import { useEffect, useState } from "react";
 
import { BarLoader } from "react-spinners";
 
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
 
import { Autocomplete } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import axios, { BASE_URL } from "../../axiosinstance";
import UploadDocument from "./UploadDocument";
import TextField from "@mui/material/TextField";
 
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import Rolecode from "../../Rolecode";
import MDButton from "components/MDButton";
 
function UploadFile() {
  const dispatch = useDispatch();
  const comp = "SM";
 
  const [loading, setLoading] = useState(true);
  const { station } = useSelector((state) => state.station);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [uploadpopup, setUploadpopup] = useState(false);
 
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { operatorCode } = useSelector((state) => state.operatorCode);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  const defaultpropsStation = {
    options: !station ? [{ name: "Loading...", id: 0 }] : station,
    getOptionLabel: (option) => option.name + "(" + option.code + ")",
 
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
 
  const iconStyle = { color: "blue", marginRight: "9px", cursor: "pointer" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const code = Rolecode();
 
  function getData(code) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "file name", accessor: "filename" },
 
        { Header: "description", accessor: "description" },
 
        { Header: "view report", accessor: "view" },
      ],
      rows: [],
    };
 
    axios
      .get(`new-upload-service-mongo/api/attachment/getAll/code/${code}`)
      .then((response) => {
        console.log("response", response.data);
        response.data.map((item) => {
          item.view = (
            <span>
              <PreviewIcon
                fontSize="medium"
                style={iconStyle}
                onClick={() => {
                  // window.open(item.downloadurl); // Open the file URL in a new window or tab
                  window.open(`${BASE_URL}/new-upload-service-mongo/api/attachment/download/${item.id}`);
                }}
              />
            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();
 
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    if (auth.rolecode === code?.Admincode) {
      dispatch(getStation());
      getData(operatorCode);
    } else {
      getData(operatorCode);
    }
  }, [dispatch]);
 
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  const handleGetData = () => {
    getData(auth.profileid);
  };
 
  const gridColumns = windowWidth < 900 ? 12 : 5;
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
 
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Upload Document
                  </MDTypography>
                </span>
 
              </div>
            </MDBox>
            <MDBox pl={3} pr={3} lineHeight={1}>
              {auth?.rolecode === code?.Admincode && (
                <Grid item xs={12} sm={gridColumns} mt={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // style={{ marginLeft: "20px" }}
                    sx={{ width: windowWidth < 900 ? "65%" : "50%" }}
                    {...defaultpropsStation}
                    onChange={(event, newValue) => {
                      getData(newValue?.code);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Station code" />
                    )}
                  />
                </Grid>
              )}
              <div style={addButtonStyle}>
 
                {auth?.rolecode === code?.Admincode && (
 
                  <span
                    style={{
                      alignSelf: "flex-end",
                      marginRight: "10px",
                      marginTop: "-53px",
                      color: "#344767",
                    }}
                  >
                    <MDButton
                      color="success"
                      onClick={() => setUploadpopup(true)}
                    >
                      ADD
                    </MDButton>
                  </span>
                )}
              </div>
            </MDBox>
 
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      
      <UploadDocument
        tittle="Upload Document"
        uploadpopup={uploadpopup}
        getData={handleGetData}
        setUploadpopup={setUploadpopup}
      />
      <Footer />
    </DashboardLayout>
  );
}
 
export default UploadFile;
 