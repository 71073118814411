import { useMemo } from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "examples/Charts/DoughnutCharts/DefaultDoughnutChartMod/configs";
import ChartDataLabels from "chartjs-plugin-datalabels";

function DefaultDoughnutChartMod({ icon, title, description, height, chart }) {
  const { data, options } = configs(
    chart.labels || [],
    chart.datasets || {},
    chart.cutout
  );

  // Add the datalabels plugin to the options
  const chartOptions = {
    ...options,
    layout: {
      padding: {
        bottom: 30,
        left: 15,
        right: 15
      },
    },
    plugins: {
      ...options.plugins,
      datalabels: {
        color: "#fff", // Color of the labels
        display: true, // Display the labels
        formatter: (value) => `${value}${value == "NaN" ? "" : "%"}`, // Display the actual value
        font: {
          weight: "bold",
          size: 12,
        },
        backgroundColor: "#000",
        borderRadius: 3,
        padding: 4,
        anchor: "center",
        align: "center",
      },
    },
  };

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Doughnut
              data={data}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
            {data?.datasets[0]?.backgroundColor && chart?.labels && (
              <MDBox display="flex" flexDirection="column" mt={-2}>
                {chart.labels.map((label, index) => (
                  <MDBox display="flex" alignItems="center" key={index}>
                    <MDBox
                      width="12px"
                      height="12px"
                      bgColor={
                        data.datasets[0].backgroundColor[index] || "transparent"
                      }
                      borderRadius="50%"
                      mr={1}
                    />
                    <MDTypography variant="caption">{label}</MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            )}
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultDoughnutChartMod
DefaultDoughnutChartMod.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the DefaultDoughnutChartMod
DefaultDoughnutChartMod.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default DefaultDoughnutChartMod;
