import { createSlice } from "@reduxjs/toolkit";
import { createBOMCreation, deleteBOMCreation, getBOMCreation, updateBOMCreation } from "../service/BOMCreationMaster";






const initialState = {
  bomcreationmaster: [],
  loading: false,
  error: null,
};

export const bomcreationmasterDetail = createSlice({
  name: "bomcreationmasterDetail",
  initialState,
  extraReducers: {
    [createBOMCreation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createBOMCreation.fulfilled]: (state, action) => {
      console.log("state",state.bomcreationmaster)
      state.loading = false;
      state.bomcreationmaster.push(action.payload.data.dto);
    },
    [createBOMCreation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getBOMCreation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBOMCreation.fulfilled]: (state, action) => {
      state.loading = false;
      state.bomcreationmaster = action.payload.data.dtoList;

    },
    [getBOMCreation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteBOMCreation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteBOMCreation.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.bomcreationmaster = state.bomcreationmaster.filter((bomcreationmaster) => bomcreationmaster.id !== id);
      }
    },
    [deleteBOMCreation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateBOMCreation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateBOMCreation.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.bomcreationmaster.findIndex(bomcreationmaster => bomcreationmaster.id === action.payload.data.dto.id)
      state.bomcreationmaster[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateBOMCreation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default bomcreationmasterDetail.reducer;
