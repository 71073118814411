import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { CircularProgress, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getChecklistservice } from "./service/Checklistservice";
import DataTable from "examples/Tables/DataTable";
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const Checklistview = (props) => {
  const dispatch = useDispatch();
  const { title, selectedRole, openpopup, setopenpopup } = props;

  const { checklistservice, loading } = useSelector(
    (state) => state.checklistservice
  );

  const [isloading, setLoading] = useState(false);
  const iconStyleedit = {
    color: "blue",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const handleClose = () => {
    setopenpopup(false);
  };

  const getHeaderForCategory = (categoryType) => {
    return categoryType === "Pokayoke View" ? 'Description of Pokayoke' : 'checkpoint';
  };
  const getHeaderForCategory2 = (categoryType) => {
    return categoryType === "Pokayoke View" ? 'Verification Method' : 'Paramtere /Specification';
  };

  useEffect(() => {
    if (selectedRole) {
      dispatch(getChecklistservice(selectedRole?.code));
    }
  }, [dispatch, selectedRole]);

  const columns = [
    {
      Header: getHeaderForCategory(title),
      accessor: "category.name",
    },
    {
      Header: getHeaderForCategory2(title),
      accessor: "category.desc",
    },
    { Header: "Observation", accessor: "category.remarks" },
    {
      Header: "shift",
      accessor: "category.shift",
    },
    { Header: "STATUS", accessor: "category.recstatus" },
  ];

  const filteredColumns = title === "Pokayoke View" ? columns.filter(column => column.Header !== "Observation" && column.Header !== "shift") : columns;

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "white",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://aliceblueonline.com/wp-content/uploads/2022/10/Form-Caricature.png"
            alt="view   image"
            style={{ width: "80%", height: "300px", marginTop: "10px" }}
          />
        </div>

        <Card>
          {!loading ? (
            <DataTable
              canSearch={false}
              showTotalEntries={false}
              entriesPerPage={false}
              table={{
                columns: filteredColumns,
                rows: checklistservice || [],
              }}
            />
          ) : (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <CircularProgress />
            </div>
          )}
        </Card>
      </DialogContent>

      <DialogActions>
        <MDButton size="small" color="info" onClick={handleClose}>
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default Checklistview;
