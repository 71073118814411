import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { FaRegEdit } from "react-icons/fa";
import CreateBOMPopup from "./CreateBOMPopup";
import MDButton from "components/MDButton";
import {
  deleteBOMCreation,
  getBOMCreation,
  updateBOMCreation,
} from "../service/BOMCreationMaster";
import BOMEdit from "./BOMedit";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import swal from "sweetalert";

function BOMList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bomcreationmaster, loading } = useSelector(
    (state) => state.bomcreationmaster
  );
  const [openpopup, setopenpopup] = useState(false);
  const [selectedBOM, setSelectedBOM] = useState(null);
  const [openpopupBOM, setopenpopupBOM] = useState(false);
  const [updatingRowId, setUpdatingRowId] = useState(null);

  const editBOM = (item) => {
    setopenpopup(true);
    setSelectedBOM(item);
  };

  const onDelete = (item) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteBOMCreation(item))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  };

  const handleToggle = async (item) => {
    // Determine the new recstatus based on the current status
    const newStatus = item.recstatus === "true" ? "false" : "true";
    const updatedItem = {
      ...item,
      recstatus: newStatus,
    };

    try {
      await dispatch(updateBOMCreation(updatedItem));
    } catch (error) {
      console.error("Error in changing toggle:", error);
      swal('', 'Error in updating status', 'error');
    }
  };

  useEffect(() => {
    dispatch(getBOMCreation());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Card>
            {loading && !updatingRowId ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Bill of Material (BOM) Master
                      </MDTypography>
                    </span>

                    <span
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                    >
                      <MDButton
                        color="success"
                        onClick={() => setopenpopupBOM(true)}
                      >
                        ADD
                      </MDButton>
                    </span>
                  </div>
                </MDBox>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "model no.", accessor: "modelno" },
                      { Header: "name", accessor: "name" },
                      { Header: "description", accessor: "description" },
                      { Header: "pattern", accessor: "pattern" },
                      {
                        Header: "process",
                        accessor: "processid",
                      },
                      {
                        Header: "status flag",
                        accessor: "toggle",
                        Cell: ({ row }) => (
                          <FormControlLabel
                            control={
                              <span>
                                <Switch
                                  checked={row.original.recstatus === "true"}
                                  onChange={() => handleToggle(row.original)}
                                />
                                {updatingRowId === row.original.id && (
                                  <CircularProgress size={15} style={{ marginLeft: 2 }} />
                                )}
                              </span>
                            }
                            label=""
                          />
                        ),
                      },
                      {
                        Header: "Action",
                        accessor: "action",
                        Cell: ({ row }) => (
                          <div>
                            <Link to={{}}>
                              <FaRegEdit
                                style={iconStyleedit}
                                onClick={() => {
                                  editBOM(row.original);
                                }}
                              />
                            </Link>

                            <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(row.original);
                                }}
                              />
                            </Link>
                          </div>
                        ),
                      },
                    ],
                    rows: bomcreationmaster || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      {openpopup &&(
      <BOMEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"BOM Edit"}
        selectedBOM={selectedBOM}
      />)}
      {openpopupBOM &&(
        <CreateBOMPopup
        openpopupBOM={openpopupBOM}
        setopenpopupBOM={setopenpopupBOM}
      />
      )}
    </DashboardLayout>
  );
}

export default BOMList;
