
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role


export const getPartProcessed = createAsyncThunk("getPartProcessed", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.get(
    //  `visualization-controller/api/visualization/v3/getPartProcessed?date=${data}`,
      `visualization-service/api/visualization/v4/getPartProcessed?date=${data}`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// 2graph   api========================



