import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";
import { FormControl, TextField } from "@mui/material";
import { createMachine } from "app/MachineMaster/service/Machine";
import { getMachine } from "app/MachineMaster/service/Machine";

export default function CreateMachine(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    machineid: Yup.string().required("Machine Id is required"),
    machinename: Yup.string().required("Machine Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      machineid: "",
      machinename: "",
      machinedescription: "",
    },
    validationSchema: validationSchema,
    onSubmit: async(values, { resetForm }) => {
      setLoading(true);
      console.log("Form Values:", values);
      const requestData = {
        code: values.machineid.trim(),
        name: values.machinename.trim(),
        description: values.machinedescription.trim(),
        recstatus: "OPEN",
        resourcecode: "DAYCO",
        status: "Active",
        syncstatus: "SYNCED",
        type: "DAYCO",
      };
      try {
        await dispatch(createMachine(requestData));
        resetForm();
        handleClose();
        setLoading(false);
        swal("Good job!", "Machine has been successfully added.", "success");
        await dispatch(getMachine());
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);
        handleClose();
        swal("", "Something went wrong!", "error");
      }
    },
  });

  const handleClose = () => {
    setopenpopupMachine(false);
  };

  const { openpopupMachine, setopenpopupMachine } = props;

  // Render the component
  return (
    <Dialog
      open={openpopupMachine}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <form onSubmit={formik.handleSubmit}>
            <Card>
              <MDBox mx={2} mt={-3}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={-2}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="small"
                    color="white"
                    mt={1}
                  >
                    Machine Master
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox p={3}>
                <Grid container spacing={3} mt={0.5}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="machineid"
                        name="machineid"
                        label=<span>
                          Machine id
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        value={formik.values.machineid}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.machineid &&
                          Boolean(formik.errors.machineid)
                        }
                        helperText={
                          formik.touched.machineid && formik.errors.machineid
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="machinename"
                        name="machinename"
                        label=<span>
                          Machine Name
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        value={formik.values.machinename}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.machinename &&
                          Boolean(formik.errors.machinename)
                        }
                        helperText={
                          formik.touched.machinename &&
                          formik.errors.machinename
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="machinedescription"
                        name="machinedescription"
                        label="Machine Description"
                        variant="standard"
                        value={formik.values.machinedescription}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.machinedescription &&
                          Boolean(formik.errors.machinedescription)
                        }
                        helperText={
                          formik.touched.machinedescription &&
                          formik.errors.machinedescription
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <MDBox
                  mt={5}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton type="submit" variant="gradient" color="dark">
                    {loading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
}
