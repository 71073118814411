import { createSlice } from "@reduxjs/toolkit";
import { createAttribute, deleteAttribute, getAttribute, updateAttribute, getAttributebyModelandStation } from "../service/Attribute";






const initialState = {
  attribute: [],
  filteredattribute: [],
  loading: false,
  error: null,
};

export const attributeDetail = createSlice({
  name: "attributeDetail",
  initialState,
  reducers: {
    resetFilteredAttribute: (state) => {
      state.filteredattribute = [];
    },
  },
  extraReducers: {
    [createAttribute.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createAttribute.fulfilled]: (state, action) => {
      console.log("state",state.attribute)
      state.loading = false;
      state.attribute.push(action.payload.data.dto);
    },
    [createAttribute.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getAttribute.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAttribute.fulfilled]: (state, action) => {
      state.loading = false;
      state.attribute = action.payload.data.dtoList;

    },
    [getAttribute.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteAttribute.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteAttribute.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.attribute = state.attribute.filter((attribute) => attribute.id !== id);
      }
    },
    [deleteAttribute.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateAttribute.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateAttribute.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.attribute.findIndex(attribute => attribute.id === action.payload.data.dto.id)
      state.attribute[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateAttribute.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getAttributebyModelandStation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAttributebyModelandStation.fulfilled]: (state, action) => {
      state.loading = false;
      state.filteredattribute = action.payload.data.dtoList;

    },
    [getAttributebyModelandStation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { resetFilteredAttribute } = attributeDetail.actions;

export default attributeDetail.reducer;
