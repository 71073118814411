// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";


import { BarLoader } from "react-spinners";

import { Box, Card, Dialog, DialogTitle } from "@mui/material";

import MDButton from "components/MDButton";


import swal from "sweetalert";
import Grid from "@mui/material/Grid";

// import axios, { UI_BASE_URL } from "../axiosinstance";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import { Autocomplete, TextField } from "@mui/material";


import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBadge from "components/MDBadge";
import { getStation } from "app/stationMaster/service/Station";
import ProductListview from "./ProductListview";
import PrintQRCode from "components/PrintQRCode";
import { getBOMCreation } from "app/bomMaster/service/BOMCreationMaster";
import Rolecode from 'Rolecode';

function ProductList() {
  
  const dispatch = useDispatch();
  const code = Rolecode();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { station } = useSelector((state) => state.station);
  const [openpopup, setopenpopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [stationcode, setStationcode] = useState("");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(8);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [timer, setTimer] = useState(null);
  const { bomcreationmaster } = useSelector((state) => state.bomcreationmaster);

  const [color] = useState("#344767");

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultpropsstation = {
    options: !station
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : station,
    getOptionLabel: (option) => option.name + "(" + option.code + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function fetchData(stationcode, page, size, query) {
    setLoading(true);
    setData([]);
    if (stationcode === undefined) {
      getData(currentPage, pageSize, query);
    } else {
      await axios
        .get(
          `/itemmaster-service/api/PartStatus/v2/partStatusMaster/get?modelno=${stationcode}&type=PRODUCT&ispageable=true&page=${page}&size=${size}&st=${query}`
        )
        .then((response) => {
          setData(response?.data?.data?.dtoList);
          setTotalPages(response?.data?.data?.page?.totalPages);
          setLoading(false);
        })
        .catch((e) => {
          console.log("error", e);
          swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          setData([]);
          setLoading(false);
        });
    }
  }

  const defaultpropsmodelcode = {
    options: !bomcreationmaster
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : bomcreationmaster,
    getOptionLabel: (option) => option.modelno,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
 
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

    return `${formattedDate} ${formattedTime}`;
  };

  const fetchDataWithDelay = (currentPage, pageSize, query) => {
    // Clear the previous timer to restart the countdown
    clearTimeout(timer);

    // Set a new timer to fetch data after one second
    const newTimer = setTimeout(() => {
      setCurrentPage(0);
      if (stationcode) {
        fetchData(stationcode, currentPage, pageSize, query);
      } else {
        getData(currentPage, pageSize, query);
      }
    }, 1000);

    setTimer(newTimer);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    fetchDataWithDelay(currentPage, pageSize, value);
  };

  useEffect(() => {
    if (stationcode) {
      fetchData(stationcode, currentPage, pageSize, searchQuery);
    } else {
      getData(currentPage, pageSize, searchQuery);
    }
  }, [currentPage, pageSize]);

  async function getData(pageSize, size, query) {
    setLoading(true);
    setData([]);
    await axios
      .get(
        `/itemmaster-service/api/PartStatus/v2/partStatusMaster/get?type=PRODUCT&ispageable=true&page=${pageSize}&size=${size}&st=${query}`
      )
      .then((response) => {
        setData(response?.data?.data?.dtoList);
        setTotalPages(response?.data?.data?.page?.totalPages);
        setLoading(false);
      })
      .catch((e) => {
        swal({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        setData([]);
        setLoading(false);
      });
  }

  useEffect(() => {
    dispatch(getStation());
    dispatch(getBOMCreation());
    getData(0, pageSize, searchQuery);
  }, []);

  const viewData = (item) => {
    setopenpopup(true);
    setSelectedData(item);
  };

  // Handle the next page button click
  function handleNextPage() {
    setCurrentPage((currentPage) => currentPage + 1);
  }

  // Handle the previous page button click
  function handlePreviousPage() {
    setCurrentPage((currentPage) => currentPage - 1);
  }
  function handlePreviousPage() {
    setCurrentPage((currentPage) => currentPage - 1);
  }

  // handle QR code 
  const [openPrint, setOpenPrint] = useState(false);

  const handleClose =()=> setOpenPrint(!openPrint)
  const [QRLink, setQRLink] = useState('');
  const [title, setTitle] = useState('');

  const handleShowQR =(item) => {
    // console.log(item, "item 201 ProductList.js")
    setQRLink(`{"PARTNO":${item.name}, "VENDORID": "11694-1"}`)
    setTitle(item.name)
    setStationcode(item.stationcode)
    handleClose()
  }

  // Render the table rows with add button
  function renderRows() {
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
    
  <td>
    <Link
      to={`/app/Steps/steps-ui/${item.stationcode}/${item.name}`}
      style={{
        fontWeight: "bold",
        textDecoration: "none",
        color: "rgb(123, 128, 154)",
        transition: "color 0.3s", // Optional: add a smooth transition effect
      }}
      onMouseOver={(e) => (e.currentTarget.style.color = "#1A73E8")}
      onMouseOut={(e) => (e.currentTarget.style.color = "rgb(123, 128, 154)")}
    >
      {item.name}
    </Link>
  </td>


         
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.stationcode}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.stationname}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.modelno ? item.modelno :''}
            </td>

            <td>
              {item?.recstatus === "OK" ? (
                <MDBadge
                  badgeContent="Pass"
                  size="md"
                  color="success"
                  gradient
                />
              ) : item?.recstatus == undefined || null || "OPEN" ? (
                <MDBadge badgeContent="fail" size="md" color="error" gradient />
              ) : (
                <></>
              )}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {formatDateTime(item.createddate)}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.type}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.rework ? item.rework : "false"}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.reworkcount || ""}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.bypass ? item.bypass : "false"}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              {item.setuppart? item.setuppart : "false"}
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              <MDButton style={{whiteSpace: 'nowrap'}} size="small" color="success" variant="outlined" onClick={()=>handleShowQR(item)} sx={{cursor:"pointer"}}>Show QR</MDButton>
            </td>
            <td
              class="MuiBox-root css-1nos2st"
              style={{ color: "rgb(123, 128, 154", fontWeight: "bold" }}
            >
              <VisibilityIcon
                fontSize="medium"
                color="rgb(123, 128, 154)"
                cursor="pointer"
                onClick={() => viewData(item)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card style={{ overflowX: "scroll" }}>
        <h5
          className="MuiTypography-root MuiTypography-h5 css-121ioyh-MuiTypography-root"
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
        Product List
        </h5>
      

        <MDBox pt={3} mb={3}>
          <MDBox>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <Grid container spacing={3} sx={{display:"flex" , justifyContent:"space-between"}}>
                <Grid item xs={12} sm={3}  >
        <Autocomplete
                      disablePortal
                      id="combo-box-mo001"
                      sx={{ width: 180 }}
                      {...defaultpropsmodelcode}
                      onChange={(event, newValue) => {
                        // setMocode(newValue?.modelno);
                        // setCurrentPage(0);
                        // setSearchQuery("");
                         fetchData(newValue?.modelno, 0, pageSize, "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Model name" />
                      )}
                    />
                </Grid>

                  <Grid item xs={12} sm={2} >
                    <MDInput
                      type="text"
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleChange}
                      label="Serach"
                    />
                  </Grid>
                </Grid>
              </div>
            </MDBox>
          </MDBox>
        </MDBox>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        <div>
          <table className="data-table2">
            <thead>
              <tr>
                <th>NAME</th>
                <th>STATIONCODE</th>
                <th>STATIONNAME</th>
                <th>Model No</th>
                <th>STATUS</th>
                <th>CREATEDDATE</th>
                <th>TYPE</th>
                <th>Rework</th>
                <th>Rework Count</th>
                <th>BYPASS</th>
                <th>SETUP PART</th>
                <th>Scan QR</th>
                <th>ACTION</th>
              </tr>
            </thead>
            {renderRows()}
          </table>
          <div className="pagination-buttons2">
            <MDButton
              variant="contained"
              size="small"
              mr={20}
              color="info"
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              Previous
            </MDButton>
            <p className="total-pages2">
              Page {currentPage + 1} of {totalPages}
            </p>
            <MDButton
              variant="contained"
              size="small"
              color="success"
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1 || data.length === 0}
            >
              Next
            </MDButton>
          </div>
        </div>
      </Card>
      <Footer />
      {openpopup && (
        <ProductListview
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Product View"}
          selectedData={selectedData}
        />
      )}

      <Dialog open={openPrint} onClose={handleClose} >
      <DialogTitle
        style={{
          backgroundColor: "#1A73E8",
          color: "#fff",
          textAlign: "center",
        }}
      >
       Scan QR Code
      </DialogTitle>
        <Grid p={8}>
          <PrintQRCode data={QRLink} isprint={false} title={title} rowdata={"rowdata"} stationcode={stationcode}/>
        </Grid>
      </Dialog>
    </DashboardLayout>
  );
}




export default ProductList;
