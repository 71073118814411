import { createSlice } from "@reduxjs/toolkit";
import { createHeaderchecklist, deleteHeaderchecklist, getHeaderchecklist, updateHeaderchecklist } from "../service/Headerchecklist";






const initialState = {
  headerchecklist: [],
  loading: false,
  error: null,
};

export const headerchecklistDetail = createSlice({
  name: "headerchecklistDetail",
  initialState,
  extraReducers: {
    [createHeaderchecklist.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createHeaderchecklist.fulfilled]: (state, action) => {
      console.log("state",state.headerchecklist)
      state.loading = false;
      state.headerchecklist.push(action.payload.data.dto);
    },
    [createHeaderchecklist.rejected]: (state, action) => {   
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getHeaderchecklist.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getHeaderchecklist.fulfilled]: (state, action) => {
      state.loading = false;
      state.headerchecklist = action.payload.data.dtoList;

    },
    [getHeaderchecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteHeaderchecklist.pending]: (state) => {  
      state.loading = true;
      state.error = null;
    },
    [deleteHeaderchecklist.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.headerchecklist = state.headerchecklist.filter((headerchecklist) => headerchecklist.id !== id);
      }
    },
    [deleteHeaderchecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateHeaderchecklist.pending]: (state) => {  
      state.loading = true;
      state.error = null;
    },
    [updateHeaderchecklist.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.headerchecklist.findIndex(headerchecklist => headerchecklist.id === action.payload.data.dto.id)
      state.headerchecklist[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateHeaderchecklist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default headerchecklistDetail.reducer;
