import React, { useEffect, useState } from "react";
import "./operator.css"; // Import the CSS file
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import swal from "sweetalert";
import { MoonLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCheckList } from "app/stationMaster/service/Checklist";
import { createHeaderchecklist } from "./service/Headerchecklist";
import { createChecklistservice } from "./service/Checklistservice";
import { getShift } from "app/shiftMaster/service/Shift";
import { APIs } from "services/APIs";
import { getApiResponse } from "services/getApisResponse";

const CreateRecord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checklist, loading } = useSelector((state) => state.checklist);
  const { shift } = useSelector((state) => state.shift);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { operatorCode } = useSelector((state) => state.operatorCode);
  const [formData, setFormData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [stationAssociation, setStationAssociation] = useState();

  useEffect(() => {
    // const machineChecklist = stationAssociation?.checklist?.filter((i) => i.type === "MACHINECHECKLIST");
    // console.warn("machineChecklist",machineChecklist)
    if (
      stationAssociation?.checklist &&
      stationAssociation?.checklist?.length > 0
    ) {
      // Initialize formData when checklist is available
      setFormData(
        stationAssociation?.checklist.map((item) => ({
          ...item,
          observation: "",
          recstatus: "",
          shift: "",
        }))
      );
    }
  }, [stationAssociation]);

  const handleInputChange = (index, key, value) => {
    const newData = [...formData];
    if (key === "category") {
      newData[index][key].machine = value;
    } else {
      newData[index][key] = value;
    }
    setFormData(newData);
  };

  const handleCheckClick = (index, value) => {
    const newData = [...formData];
    newData[index].checkedValue = value; // Set the value based on button click
    setFormData(newData);
  };

  const handleShiftChange = (index, value) => {
    const newData = [...formData];
    newData[index].shift = value; // Set the shift value
    setFormData(newData);
  };

  const handleSubmit = async () => {
    // Check for empty rows
    const emptyRows = formData.filter((item) => {
      return !item.observation || !item.checkedValue || !item.shift;
    });

    if (emptyRows.length > 0) {
      const errorMessages = emptyRows.map((row) => {
        const errors = [];
        if (!row.observation) {
          errors.push("Observation is empty");
        }
        if (!row.checkedValue) {
          errors.push("Result is empty");
        }
        if (!row.shift) {
          errors.push("Shift is empty");
        }
        return `${row.name}: ${errors.join(", ")}`;
      });
      swal({
        icon: "error",
        title: "Error",
        text: `The following rows have issues:\n${errorMessages.join("\n")}`,
      });
      return; // Stop submission if any row is empty
    }

    setIsLoading(true);
    try {
      const requestData = {
        operatorname: auth.firstname + " " + auth.lastname,
        stationcode: operatorCode,
        operatorid: auth?.profileid,
        recstatus: "OPEN",
        resourcecode: "DAYCO",
        status: "Active",
        syncstatus: "SYNCED",
        type: "CHECKLISTMASTER",
      };

      const headerChecklistResponse = await dispatch(
        createHeaderchecklist(requestData),
        console.warn("requestData", requestData)
      );

      if (headerChecklistResponse.error) {
        throw new Error(
          headerChecklistResponse.error.message ||
            "Header checklist creation failed"
        );
      }
      console.warn("formdata", formData);

      const transformedData = formData.map((item) => {
        const { observation, checkedValue, shift, ...rest } = item;
        const code = headerChecklistResponse?.payload?.data?.dto?.code
     
       

        const filledObservation =
          item.observation !== undefined ? item.observation : null;
        const data = {
          ...rest,
          code: code,
          stationcode :operatorCode,
         createdby: auth?.profileid,
         id: null,
          status: "ACTIVE",
          category: {
            ...item,
            remarks: filledObservation,
            recstatus: item.checkedValue !== undefined ? item.checkedValue : "",
            shift: item.shift !== undefined ? item.shift : "",
          },
        };
        console.warn("data",data)
        return data;
      });
     

      const checklistServiceResponse = await dispatch(
        createChecklistservice(transformedData)
      );
    

      if (checklistServiceResponse.error) {
        throw new Error(
          checklistServiceResponse.error.message ||
            "Checklist service creation failed"
        );
      }

      navigate("/app/Component/record-list");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      swal({
        icon: "error",
        title: "Error",
        text: error.message || "Something went wrong",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    let response = await getApiResponse(
      `${APIs.getStationAssociationsingle}?station.code=${operatorCode}`,
      {}
    );
    setStationAssociation(response?.data?.dto);

    dispatch(getShift(operatorCode));
  }, [dispatch, operatorCode]);

  return (
    <Card>
      {formData.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            fontSize: "18px",
            color: "#555",
            marginTop: "20px",
          }}
        >
          No data found
        </p>
      ) : (
        <>
          <div className="data-list-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Check Point</th>
                  <th>Paramtere /Specification</th>
                  <th>Shift</th>
                  <th>Observation</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {formData.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.desc}</td>
                    <td>
                      <select
                        className="custom-dropdown"
                        value={item.shift}
                        onChange={(e) =>
                          handleShiftChange(index, e.target.value)
                        }
                      >
                        <option value="">Select Any Shift</option>

                        {shift.map((obj) => (
                          <option key={obj.id} value={obj.title}>
                            {obj?.title}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <MDInput
                        label="Observation..."
                        size="small"
                        type="text"
                        value={item.observation}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "observation",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <MDButton
                        variant="contained"
                        color={
                          item.checkedValue === "ok" ? "success" : "default"
                        }
                        onClick={() => handleCheckClick(index, "ok")}
                      >
                        ✓
                      </MDButton>
                      <MDButton
                        variant="contained"
                        color={
                          item.checkedValue === "notok" ? "error" : "default"
                        }
                        onClick={() => handleCheckClick(index, "notok")}
                      >
                        ✗
                      </MDButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                onClick={handleSubmit}
              >
                {isloading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Submit"
                )}
              </MDButton>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default CreateRecord;
