import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { useSelector } from "react-redux";
import MDButton from "components/MDButton";

import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import { getAttributeList } from "productlist/reducer/service/AttributeList";
const ViewAttribute = (props) => {
  const dispatch = useDispatch();
  const { title, selectedData, openpopup, setopenpopup } = props;

  const { error} = useSelector((state) => state.attributeList);

  const { attributeList, loading } = useSelector((state) => state.attributeList); 



  useEffect(() => {
    const data ={
      itemcode:selectedData?.sapcode,
      stationcode:selectedData?.stationcode
    }
    dispatch(getAttributeList(data));
   
  }, [selectedData]);

  const handleClose = () => {
   
    setopenpopup(false);
  };
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Options for formatting time
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };

    const formattedDate = dateTime.toLocaleDateString();
    const formattedTime = dateTime
      .toLocaleTimeString(undefined, timeOptions)
      .toUpperCase();

    return ` ${formattedDate} ${formattedTime}`;
  };

 
  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#1A73E8",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
     
          <div
            style={{
              marginTop:"20px",
              border: "1.5px solid black",
              width: "97%",
              padding: 10,
              borderRadius: 10,
              marginLeft: 15,
              marginBottom: 5,
            }}
          >
            {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <div style={{ color: "red", textAlign: "center" }}>
              Error fetching data. Please try again.
            </div>
          ) : (
            <>
            <div style={{ fontSize: "15px", color: "#7b809a" }}>
              <div>
                <text style={{ fontWeight: "bold" }}>Name:</text>

                {" " + selectedData?.name}
                {selectedData?.recstatus === "OK" ? (
                  <MDBadge
                    badgeContent="PASS"
                    size="md"
                    style={{ marginLeft: "12px" }}
                    color="success"
                    gradient
                  />
                ) : selectedData?.recstatus == undefined || null || "OPEN" ? (
                  <MDBadge
                    badgeContent="FAIL"
                    size="md"
                    style={{ marginLeft: "12px" }}
                    color="error"
                    gradient
                  />
                ) : (
                  <></>
                )}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Stationcode :</text>
                {" " + selectedData?.stationcode}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Stationname :</text>
                {" " + selectedData?.stationname}
              </div>
             
              <div>
                <text style={{ fontWeight: "bold" }}>Txn Id :</text>
                {" " + selectedData?.sapcode}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Type :</text>
                {" " + selectedData?.type}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Bypass :</text>
                {" " + selectedData?.bypass && selectedData?.bypass}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Setup Part :</text>
                {" " + selectedData?.setuppart === null ? "" : selectedData?.setuppart}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>Model No :</text>
                {" " +selectedData.modelno === null ? "" : selectedData?.modelno}
              </div>
              <div>
                <text style={{ fontWeight: "bold" }}>CreatedDate :</text>
                {formatDateTime(selectedData?.createddate)}
              </div>
              {selectedData?.modifieddate===null ||selectedData?.modifieddate===undefined ? (
              <div>
              <text style={{ fontWeight: "bold" }}>Modified Date :</text>
              {' '} --/--/--
              </div>
            ) : (
             
              <div>
             
                <text style={{ fontWeight: "bold" }}>Modified Date :</text>
                {formatDateTime(selectedData?.modifieddate)}

              </div>
            )}
            </div>

        
             
               <DataTable
                 showTotalEntries={false}
              entriesPerPage={false}
                  table={{
                    columns: [
                  {
                    Header: "name",
                    accessor: "displayname",
                    width: "25%",
                  },
                  {
                    Header: "uom",
                    accessor: (row) => `${row.uomdescription} (${row.uom})`,
                    width: "30%",
                  },
                  {
                    Header: "model no",
                    accessor: "modelno",
                   
                  },
                  {
                    Header: "range",
                    accessor: (row) =>
                      `(${row.lowerlimit} - ${row.upperlimit})`,
                    width: "30%",
                  },
                  {
                    Header: "value",
                    accessor: "value",
                    Cell: ({ value, row }) => {
                      console.warn("value",value)
                      console.warn("value222",row.original)
                      const isOutOfRange =
                        value < row.original.lowerlimit ||
                        value > row.original.upperlimit;

                      return row?.original?.displayflag === "false" &&
                        row?.original?.displayflag !== null ? (
                        <span>{`${value}`}</span>
                      ) : (
                        <span
                          style={{
                            color: isOutOfRange ? "red" : "green",
                          }}
                        >
                          {`${value} ${row.original.uom}`}
                        </span>
                      );
                    },
                    width: "30%",
                  },
                  ,
                ],
                    rows: attributeList || [],
                  }}
                />
                 </>
          )}
          </div>
      
        </DialogContent>
    </Dialog>
  );
};





export default ViewAttribute;