import { createSlice } from "@reduxjs/toolkit";
import {
  createChecklistservice,
  deleteChecklistservice,
  getChecklistservice,
  updateChecklistservice,
} from "../service/Checklistservice";

const initialState = {
  checklistservice: [],
  loading: false,
  error: null,
};

export const checklistserviceDetail = createSlice({
  name: "checklistserviceDetail",
  initialState,
  extraReducers: {
    [createChecklistservice.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createChecklistservice.fulfilled]: (state, action) => {
      console.log("state", state.checklistservice);
      state.loading = false;
      state.checklistservice.push(action.payload.data.dto);
    },
    [createChecklistservice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getChecklistservice.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getChecklistservice.fulfilled]: (state, action) => {
      state.loading = false;
      state.checklistservice = action.payload.data.dtoList;
    },
    [getChecklistservice.rejected]: (state, action) => {
      state.loading = false;
      
    state.checklistservice=[]
      state.error = action.error;
    },
    [deleteChecklistservice.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteChecklistservice.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload);
      const { id } = action.payload.data.dto;
      if (id) {
        state.checklistservice = state.checklistservice.filter(
          (checklistservice) => checklistservice.id !== id
        );
      }
    },
    [deleteChecklistservice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateChecklistservice.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateChecklistservice.fulfilled]: (state, action) => {
      state.loading = false;

      const index = state.checklistservice.findIndex(
        (checklistservice) => checklistservice.id === action.payload.data.dto.id
      );
      state.checklistservice[index] = action.payload.data.dto;
      //   state.users.push(action.payload);
    },
    [updateChecklistservice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default checklistserviceDetail.reducer;
