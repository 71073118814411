import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { reportServiceAction } from "redux/Actions/reportServiceAction";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const getReportListSlice = createSlice({
  name: "getReportServiceList",
  initialState,
  reducers: {
    resetReportServiceList: (state) => {
      state.list = [];
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportServiceAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reportServiceAction.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
        state.error = null;
      })
      .addCase(reportServiceAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { resetReportServiceList } = getReportListSlice.actions;

export default getReportListSlice.reducer;