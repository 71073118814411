
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const createStationAssociation = createAsyncThunk("createStationAssociation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `stationassociation-master/api/StationAssociation/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getStationAssociation = createAsyncThunk("getStationAssociation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `stationassociation-master/api/StationAssociation/v2/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateStationAssociation = createAsyncThunk("updateStationAssociation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `stationassociation-master/api/StationAssociation/v2/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteStationAssociation = createAsyncThunk("deleteStationAssociation", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `stationassociation-master/api/StationAssociation/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});