import { createSlice } from "@reduxjs/toolkit";
import { createCheckList, deleteCheckList, getCheckList, updateCheckList } from "../service/Checklist";

const initialState = {
  checklist: [],
  loading: false,
  error: null,
};

export const checklistDetail = createSlice({
  name: "checklistDetail",
  initialState,
  extraReducers: {
    [createCheckList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createCheckList.fulfilled]: (state, action) => {
      console.log("state",state.checklist)
      state.loading = false;
      state.checklist.push(action.payload.data.dto);
    },
    [createCheckList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getCheckList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCheckList.fulfilled]: (state, action) => {
      state.loading = false;
      state.checklist = action.payload.data.dtoList;

    },
    [getCheckList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteCheckList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteCheckList.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.checklist = state.checklist.filter((checklist) => checklist.id !== id);
      }
    },
    [deleteCheckList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCheckList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateCheckList.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.checklist.findIndex(checklist => checklist.id === action.payload.data.dto.id)
      state.checklist[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateCheckList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default checklistDetail.reducer;
