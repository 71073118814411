import { createSlice } from "@reduxjs/toolkit";
import { getAttributeVariation } from "../service/Attributevariation";







const initialState = {
  attributevariation: {
    datasets: { label: 'Station Wise Attribute Variation', data: [] },
    error: null,
    labels: [],
    objects: null,
  },
  loading: false,
  error: null,
};

export const attributevariationDetail = createSlice({
  name: "attributevariationDetail",
  initialState,
  reducers: {
    updateNoOfattributevariation: (state, action) => {
      const newData = action.payload;
      state.attributevariation.labels = newData?.labels;
      state.attributevariation.datasets.data = newData?.data;
    },
  },
  extraReducers: {
  
    [getAttributeVariation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAttributeVariation.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.attributevariation = action.payload;

    },
    [getAttributeVariation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
  },
});

export const { updateNoOfattributevariation } = attributevariationDetail.actions;
export default attributevariationDetail.reducer;
/// 3 graph redcer